import React, { useCallback } from 'react';

import { Container, PageList, PageItem } from './styles';

const Pagination = ({ totalItems, currentPage, setPage }) => {
  const totalPages = Math.ceil(totalItems / 20);

  const items = [];

  for (let i = 0; i < totalPages; i += 1) {
    items.push(i);
  }

  const previousPage = useCallback(() => {
    if (currentPage > 1) {
      setPage((state) => state - 1);
    }
  }, [currentPage, setPage]);

  const nextPage = useCallback(() => {
    if (currentPage <= totalPages) {
      setPage((state) => state + 1);
    }
  }, [currentPage, setPage, totalPages]);

  return (
    <Container>
      <PageList>
        <PageItem
          title="Página Anterior"
          disable={currentPage === 1}
          onClick={previousPage}
        >
          {'<'}
        </PageItem>
        {items.map((item) => (
          <PageItem
            active={currentPage === item + 1}
            onClick={() => setPage(item + 1)}
            key={item}
          >
            {item + 1}
          </PageItem>
        ))}

        <PageItem
          title="Próxima página"
          disable={currentPage === totalPages}
          onClick={nextPage}
        >
          {'>'}
        </PageItem>
      </PageList>
    </Container>
  );
};

export default Pagination;
