import styled, { css } from 'styled-components';

export const ContentForm = styled.div`
  display: flex;

  ${(props) =>
    props.noactive &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `}
`;

export const Col1 = styled.div`
  width: 68%;
  margin-top: 32px;
  padding-right: 16px;
`;

export const Col2 = styled.div`
  width: 32%;
  margin-top: 32px;
  padding: 0 0 0 16px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 24px;

  button {
    margin-right: 8px;
  }
`;

export const Hr = styled.hr`
  border: 0;
  border-bottom: 1px solid #e5eaed;
  margin: 16px 0;
`;

export const GroupFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  .payment {
    background: #f0f0f0;
  }
`;

export const GroupFieldsCol1 = styled.div`
  display: block;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  padding-left: 24px;
  border-left: 2px solid #e5eaed;

  div {
    margin: 0;
  }
`;

export const GroupFieldsCol4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;

  .noactive {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const GroupTextArea = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
`;

export const Info = styled.p`
  display: flex;
  align-items: center;

  background: #e8f4fd;
  color: #0d3c61;
  padding: 16px;
  border-radius: 4px;
  margin-top: 16px;

  svg {
    margin-right: 12px;
  }
`;
