import useSWR from 'swr';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

export const useFetchAccounts = (url) => {
  const { signOut } = useAuth();
  const { addToast } = useToast();

  const { data, error, mutate } = useSWR(
    url,
    async () => {
      const response = await api.get(url);

      const totalItens = response.headers['x-total-count'];

      const accounts = response.data.map((acc) => {
        return {
          id: acc.id,
          description: acc.description,
          type: acc.type,
          account_default: acc.account_default && 'Padrão',
        };
      });

      return { data: accounts, total: totalItens };
    },
    {
      revalidateOnReconnect: true,
      onError: (err) => {
        if (err.response.status === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada. Faça seu login.',
          });
          signOut();
        }
      },
    }
  );

  return {
    dataList: data && data.data,
    totalItems: data && data.total,
    loading: !data && !error,
    error,
    mutate,
  };
};
