import styled from 'styled-components';

export const TableHeader = styled.thead`
  background: #ededf0;
  border-radius: 4px;

  th {
    font-weight: bold;
    text-align: left;
    padding: 14px 16px;
    border-bottom: 1px solid #d4d4d4;

    :nth-child(1) {
      text-align: center;
    }
    :nth-child(2) {
      text-align: left;
    }

    :nth-child(3) {
      text-align: left;
    }
    :nth-child(4) {
      text-align: center;
    }
    :nth-child(5) {
      text-align: right;
    }
    :nth-child(6) {
      text-align: center;
      border-left: dotted 1px #d1d5da;
    }
  }
`;
