import React from 'react';

import { Container } from './styles';

const ButtonDefault = ({ children, icon: Icon, checked, ...rest }) => {
  return (
    <Container checked={checked}>
      <button
        type="button"
        title={
          checked
            ? 'Clicando aqui vamos marcar como NÃO RECEBIDO'
            : 'Clique para receber na conta padrão selecionada'
        }
        {...rest}
      >
        {children}
        {checked && Icon && <Icon size={16} />}
      </button>
    </Container>
  );
};

export default ButtonDefault;
