import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  background: linear-gradient(0deg, #358ccf, #3ca2d3);
  border-color: #318ace;
  border: 0;

  border-radius: 4px;
  height: 40px;
  color: #fff;
  font-size: 10px;

  transition: background 0.2s;

  :hover {
    filter: brightness(90%);
  }

  :active {
    filter: brightness(80%);
  }
`;
