import api from '../services/api';

export const checkExistsCNPJ = async (cnpj) => {
  if (!cnpj) {
    return true;
  }
  try {
    await api.get(`/peoples/legalpeoples/checkcnpj?cnpj=${cnpj}`);

    return true;
  } catch (error) {
    return false;
  }
};

export const checkExistsCPF = async (cpf) => {
  if (!cpf) {
    return true;
  }
  try {
    await api.get(`/peoples/fisicalpeoples/Checkcpf?cpf=${cpf}`);

    return true;
  } catch (error) {
    return false;
  }
};
