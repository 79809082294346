import styled from 'styled-components';

export const Container = styled.div`
  label {
    display: flex;
    flex-direction: column;
  }

  select {
    height: 40px;
    padding: 6px 8px;
    background: #fff;
    color: #616161;
    border-radius: 4px;
    border: 2px solid #d1d5da;
    font-weight: bold;
    margin-top: 4px;

    transition: all 0.2s ease-in-out;

    :focus {
      border-color: #2188ff;
      box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
        0 0 0 0.2em rgba(3, 102, 214, 0.3);
      transition: all 0.2s ease-in-out;
    }

    option {
      font-size: 16px;
    }
  }

  article {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SwitchGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4px;
  }

  span {
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => (props.checkStatus ? '#2693e6' : '#e02041')};
    margin-top: 4px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 18px;

  button {
    margin-right: 8px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;

  form {
    width: 100%;
  }
`;

export const GroupFieldsHead = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  align-items: center;

  background: #f2f2f2;
  padding: 16px;
  border-radius: 4px;
  margin-top: 40px;
  margin-bottom: 40px;

  .noactive {
    pointer-events: none;
    opacity: 0.4;
  }
`;

export const GroupFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  margin-top: 16px;
`;

export const GroupTextArea = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin: 40px 0 64px 0;
`;

export const People = styled.div``;

export const Pj = styled.div`
  display: ${(props) => (props.showPJ ? 'block' : 'none')};

  h4 {
    font-size: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e5eaed;
    margin-bottom: 40px;

    span {
      margin-left: 10px;
      border-radius: 20px;
      font-size: 16px;
      font-weight: bold;
      background: linear-gradient(0deg, #358ccf, #3ca2d3);
      color: #fff;
      padding: 3px 12px;
    }
  }
`;

export const Pf = styled.div`
  display: ${(props) => (props.showPF ? 'block' : 'none')};

  h4 {
    font-size: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e5eaed;
    margin-bottom: 40px;

    span {
      margin-left: 10px;
      border-radius: 20px;
      font-size: 16px;
      font-weight: bold;
      background: linear-gradient(0deg, #358ccf, #3ca2d3);
      color: #fff;
      padding: 3px 12px;
    }
  }
`;

export const PfEmployee = styled.div`
  display: ${(props) => (props.showEmployee ? 'block' : 'none')};
  margin: 40px 0;
`;

export const BankDetails = styled.div`
  display: ${(props) => (props.showBank ? 'block' : 'none')};
  margin-top: 40px;
`;

export const Hr = styled.hr`
  border: 0;
  border-bottom: 1px solid #e5eaed;
  margin: 40px 0;
`;

export const ButtonAcordion = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  padding: 20px 0;
  border: 0;
  border-radius: 4px;
  background: #f5f5f5;
  svg {
    margin: 0 8px;
  }
`;

export const Accordion = styled.div`
  button:first-child {
    margin-bottom: 16px;
  }
`;

export const WrapperProfession = styled.div`
  display: flex;
  align-items: flex-end;
`;
