import styled from 'styled-components';

export const TableHeader = styled.thead`
  background: #f2f2f2;

  th {
    font-weight: bold;
    text-align: center;
    padding: 14px 16px;
    border-bottom: 2px solid #d4d4d4;

    :nth-child(1) {
      width: 10%;
    }

    :nth-child(2) {
      width: 35%;
      text-align: left;
    }

    :nth-child(3) {
      width: 20%;
      text-align: left;
    }

    :nth-child(4) {
      width: 15%;
      text-align: left;
    }

    :nth-child(5) {
      width: 5%;
    }

    :nth-child(6) {
      width: 15%;
      border-left: 1px dotted #d4d4d4;
    }
  }
`;
