import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Container, TabsList, TabsItem } from './styles';

const NavTabs = ({ dataTabs }) => {
  const { pathname } = useLocation();

  return (
    <Container>
      <TabsList>
        {dataTabs.map((t) => (
          <Link key={t.label} to={t.link}>
            <TabsItem active={pathname === t.link}>{t.label}</TabsItem>
          </Link>
        ))}
      </TabsList>
    </Container>
  );
};

export default NavTabs;
