import styled from 'styled-components';

export const TableBodyItem = styled.tr`
  transition: all 0.2s ease;

  td {
    border-bottom: 1px dotted #d4d4d4;
    text-align: center;

    :nth-child(1) {
      width: 10%;
    }

    :nth-child(2) {
      width: 45%;
      text-align: left;
    }

    :last-child {
      width: 15%;
      border-left: 1px dotted #d4d4d4;
    }

    a {
      display: flex;
      justify-content: center;
      padding: 18px 16px;
    }

    :nth-child(1) {
      width: 10%;
      a {
        justify-content: center;
      }
    }
    :nth-child(2) {
      width: 45%;
      a {
        justify-content: flex-start;
      }
    }
  }

  :hover {
    background: #f5f5f9;
  }
`;

export const Actions = styled.button`
  border: 0;
  background: transparent;
  margin: 0;
  padding: 8px 16px;

  svg {
    margin: 0;
  }
`;
