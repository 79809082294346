import useSWR from 'swr';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

import {
  formatedInputBRL,
  formatedDateOnlyAdd6Hours,
} from '../../../utils/formatedInputs';

export const useFetchSales = (url) => {
  const { signOut } = useAuth();
  const { addToast } = useToast();

  const { data, error, mutate } = useSWR(
    url,
    async () => {
      const response = await api.get(url);
      const { salesMonth, totals: totalsData } = response.data;

      const salesByMonth = salesMonth.map((sale) => {
        return {
          date_sale: formatedDateOnlyAdd6Hours(sale.date_sale),
          sale_id: sale.id,
          client: sale.peoples
            ? sale.peoples.pj?.fantasy_name || sale.peoples.pf?.name
            : '',
          situation:
            (sale.situation === 'sale' && 'Venda') ||
            (sale.situation === 'order_draft' && 'Pedido Rascunho') ||
            (sale.situation === 'order_confirmed' && 'Pedido Confirmado') ||
            (sale.situation === 'order_canceled' && 'Pedido Cancelado'),
          amount: formatedInputBRL(sale.total_liquid),
        };
      });

      const totals = totalsData.map((t) => {
        return {
          total_order_canceled: formatedInputBRL(+t.total_order_canceled),
          total_order_draft: formatedInputBRL(+t.total_order_draft),
          total_order_confirmed: formatedInputBRL(+t.total_order_confirmed),
          total_sale: formatedInputBRL(+t.total_sale),
          total_preview: formatedInputBRL(+t.total_preview),
        };
      });

      return { data: salesByMonth, totals };
    },
    {
      revalidateOnReconnect: true,

      onError: (err) => {
        if (err.response.status === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada. Faça seu login.',
          });
          signOut();
        }
      },
    }
  );

  return {
    dataList: data && data.data,
    totals: data && data.totals,
    loading: !data && !error,
    error,
    mutate,
  };
};
