import styled from 'styled-components';

export const Content = styled.div`
  margin: 40px 0;
  max-width: 500px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 18px;

  button {
    margin-right: 8px;
  }
`;

export const Wrapper = styled.div`
  max-width: 100%;
  margin-top: 40px;

  h3 {
    margin-top: 24px;
    font-size: 18px;
    padding-bottom: 12px;
    border-bottom: 1px dashed #e5eaed;
    font-weight: bold;
  }
`;

export const GroupFieldsCol3 = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 24px;
`;

export const GroupFieldsCol5 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0 40px 0;
  background: #ededf0;
  padding: 16px 16px 8px 16px;
  border-radius: 4px;
`;

export const Actions = styled.div`
  display: flex;
`;
export const IconAction = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  background: transparent;
  border: 0;

  & + button {
    margin-left: 10px;
  }
`;

export const Total = styled.section`
  display: flex;
  max-width: 40%;
  flex-direction: column;
  margin-left: auto;
  margin-right: 64px;
`;

export const SubTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 32px 0 100px;

  p {
    font-weight: bold;
  }

  span {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const SubTotal2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 32px 0 100px;

  p {
    height: 85px;
    padding-top: 16px;
  }

  span {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const TotalLiq = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 16px 32px 16px 40px;

  p,
  span {
    font-size: 24px;
    font-weight: bold;
  }
`;
export const Hr = styled.hr`
  border: 0;
  border-bottom: 1px dashed #e5eaed;
  margin: 32px 0;
`;

export const Payment = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  margin-top: 32px;

  article + article {
    margin-left: 24px;
  }
`;
export const Installments = styled.ul`
  list-style: none;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  align-items: center;
  justify-content: center;
  padding: 40px 64px 24px 64px;
  border: 2px solid #e5eaed;
  border-radius: 4px;
  position: relative;

  &::before {
    content: '';
    border-style: solid;
    border-color: #e5eaed transparent;
    border-width: 0 12px 12px 12px;
    top: -12px;
    left: 80px;
    position: absolute;
  }
`;
export const InstallmentsItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85px;
    padding-bottom: 32px;
    margin-right: 32px;
    margin-top: 8px;

    span {
      width: 62px;
      font-weight: bold;
      margin-top: 0px;
      background: #ededf0;
      padding: 8px 0;
      border-radius: 4px;
      text-align: center;
    }
  }

  article + article {
    margin-left: 24px;
  }
`;

export const DivVisible = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
`;
