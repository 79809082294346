import styled from 'styled-components';

export const TableBodyItem = styled.tr`
  transition: all 0.2s ease;

  td {
    text-align: left;
    border-bottom: 1px dotted #c9d3dd;
    padding: 12px 10px 10px 0;

    :nth-child(1) {
      text-align: left;
    }
    :nth-child(2) {
      text-align: left;
    }
    :nth-child(3) {
      text-align: right;
    }

    :nth-child(4) {
      text-align: right;
    }
    :nth-child(5) {
      text-align: right;
    }
    :nth-child(6) {
      display: none;
    }
  }
`;

export const Actions = styled.button`
  border: 0;
  background: transparent;
  margin: 0;
  padding: 8px 16px;

  svg {
    margin: 0;
  }
`;
