import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const TabsList = styled.ul`
  display: flex;
  margin: 8px 0 20px 0;
  list-style: none;
  border-bottom: 1px solid #e5eaed;
  font-weight: bold;
`;

export const TabsItem = styled.li`
  position: relative;
  padding: 12px 24px;
  background: #ededf0;
  border-radius: 8px 8px 0 0;
  border-top: 1px solid #e5eaed;
  border-left: 1px solid #e5eaed;
  border-right: 1px solid #e5eaed;
  margin-right: 16px;

  ${(props) =>
    props.active &&
    css`
      margin-bottom: -1px;
      border-bottom: 1px solid #fff;
      background: #fff;
      box-shadow: 0px -1px 1px 0px rgba(32, 33, 36, 0.28);
    `}
`;
