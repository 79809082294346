/* eslint-disable consistent-return */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import NavbarMenu from '../../components/NavbarMenu';
import Loading from '../../components/Loading';

import { useFetch } from '../../hooks/useFetch';

import { formatedInputBRL } from '../../utils/formatedInputs';

import {
  Container,
  SectionCards,
  WrapperCards1,
  WrapperCards2,
  WrapperCards3,
  Card,
} from './styles';

const Dashboard = () => {
  const { dataList, loading } = useFetch('/dashboard');

  const totalSales = useMemo(() => {
    if (dataList) {
      return formatedInputBRL(dataList.totalSalesMonth);
    }
  }, [dataList]);

  const totalReceiveToday = useMemo(() => {
    if (dataList) {
      return formatedInputBRL(dataList.totalBillsReceivedToday);
    }
  }, [dataList]);
  const totalBillsReceivedMonth = useMemo(() => {
    if (dataList) {
      return formatedInputBRL(dataList.totalBillsReceivedMonth);
    }
  }, [dataList]);
  const totalBillsPayToday = useMemo(() => {
    if (dataList) {
      return formatedInputBRL(dataList.totalBillsPayToday);
    }
  }, [dataList]);
  const totalBillsPayMonth = useMemo(() => {
    if (dataList) {
      return formatedInputBRL(dataList.totalBillsPayMonth);
    }
  }, [dataList]);

  return (
    <>
      <NavbarMenu />
      <Container>
        <h1>Dashboard</h1>

        {loading && <Loading />}

        {!loading && (
          <SectionCards>
            <Link to="/sales">
              <WrapperCards3>
                <Card>
                  <h3>Vendas no mês</h3>
                  <span>{totalSales}</span>
                  <p>GRANJA TATUÍ</p>
                </Card>
              </WrapperCards3>
            </Link>
            <Link to="/billstoreceives">
              <WrapperCards1>
                <Card>
                  <h3>Contas a receber hoje</h3>
                  <span>{totalReceiveToday}</span>
                  <p>Restante do mês {totalBillsReceivedMonth}</p>
                </Card>
              </WrapperCards1>
            </Link>

            <Link to="/billstopays">
              <WrapperCards2>
                <Card>
                  <h3>Contas a pagar hoje</h3>
                  <span>{totalBillsPayToday}</span>
                  <p>Restante do mês {totalBillsPayMonth}</p>
                </Card>
              </WrapperCards2>
            </Link>
          </SectionCards>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
