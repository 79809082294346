/* eslint-disable no-plusplus */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FiCheck, FiX, FiPlus } from 'react-icons/fi';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { addMonths, format } from 'date-fns';

import getValidationErrors from '../../../utils/getValidationErrors';
import { parseStrToFloatInputBRL } from '../../../utils/parseStrToFloatInputBRL';
import { formatedInputBRL } from '../../../utils/formatedInputs';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import { useFetch } from '../../../hooks/useFetch';
import { useFetchProducts } from '../hooks/useFetchProducts';

import NavBarMenu from '../../../components/NavbarMenu';
import ContainerBox from '../../../components/ContainerBox';
import ErrorBoxSubmit from '../../../components/ErrorBoxSubmit';

import {
  Input,
  Select,
  InputDatePicker,
  InputInteger,
  InputBRL,
  TextArea,
} from '../../../components/Form';
import ButtonSave from '../../../components/ButtonPrimaryBlue';
import ButtonAddItem from '../../../components/ButtonPrimaryGreen';
import ButtonCancel from '../../../components/ButtonCancel';
import Table from './Table';

import {
  ButtonGroup,
  Wrapper,
  GroupFieldsCol3,
  GroupFieldsCol5,
  Total,
  SubTotal,
  SubTotal2,
  TotalLiq,
  Hr,
  Payment,
  Installments,
  InstallmentsItem,
  DivVisible,
} from './styles';

const SalesNewEdit = () => {
  const { dataList: clients } = useFetch('/peoplesearch?search=%');
  const { dataList: accountPlans } = useFetch('/accountplansearch?search=%');
  const { dataList: products } = useFetchProducts('/productsearch?search=%');

  const [errorsValidateSubmit, setErrorsValidateSubmit] = useState(false);
  const [showParcel, setShowParcel] = useState(false);
  const [isSale, setIsSale] = useState(false);
  const [items, setItems] = useState([]);
  const [objInstallments, setObjInstallments] = useState([]);

  const [total, setTotal] = useState(0);
  const [totalLiq, setTotalLiq] = useState(0);

  const location = useLocation();

  const history = useHistory();

  const formRef = useRef(null);

  const { addToast } = useToast();

  const head = {
    product_id: 'ID',
    product: 'Produto',
    quantity: 'Quantidade',
    price: 'Preço',
    sub_total: 'SubTotal',
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }

    if (location.state.situation === 'sale') {
      setIsSale(true);
    }

    formRef.current.setFieldValue('situation', location.state.situation);

    // setId(location.state.item.id);
  }, [location.state]);

  const handleSubmit = useCallback(
    async (dataForm) => {
      try {
        const { payment_term } = dataForm;

        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          people_id: Yup.string().required('Cliente é obrigatório'),
          account_plan_id: Yup.string().required(
            'Plano de contas é obrigatório'
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        if (!items[0]) {
          addToast({
            type: 'error',
            title: 'Adicione os itens',
          });
          return;
        }

        if (isSale) {
          if (payment_term === 'avista' || payment_term === '1') {
            const itemsParsed = items.map((item) => {
              return {
                product_id: +item.product_id,
                quantity: +item.quantity,
                price: +parseStrToFloatInputBRL(item.price).toFixed(2),
                sub_total: +parseStrToFloatInputBRL(item.sub_total).toFixed(2),
              };
            });

            const installments = {
              parcel: payment_term === '1' ? '1 / 1' : 'À Vista',
              maturity_date: format(dataForm.maturity_date, 'yyyy-MM-dd'),
              amount: totalLiq,
              comments: dataForm.comments_parcel,
            };

            const dataSale = {
              people_id: +dataForm.people_id,
              account_plan_id: +dataForm.account_plan_id,
              date_sale: format(dataForm.date_sale, 'yyyy-MM-dd'),
              situation: dataForm.situation,
              payment_term: dataForm.payment_term,
              total: +total.toFixed(2),
              total_liquid: +totalLiq.toFixed(2),
              discount: dataForm.discount
                ? +parseStrToFloatInputBRL(dataForm.discount).toFixed(2)
                : null,
              comments: dataForm.comments,
              items: itemsParsed,
              installments: [installments],
            };

            const salePosted = await api.post('sales', dataSale);

            addToast({
              type: 'success',
              title: 'Venda cadastrada com Sucesso',
            });

            history.push({
              pathname: '/sales/view',
              state: { sale_id: salePosted.data.id },
            });
            return;
          }

          if (+payment_term >= 2) {
            const itemsParsed = items.map((item) => {
              return {
                product_id: +item.product_id,
                quantity: +item.quantity,
                price: +parseStrToFloatInputBRL(item.price).toFixed(2),
                sub_total: +parseStrToFloatInputBRL(item.sub_total).toFixed(2),
              };
            });

            const installmentsParsed = dataForm.installments.map((parcel) => {
              return {
                parcel: parcel.parcel,
                maturity_date: format(parcel.maturity_date, 'yyyy-MM-dd'),
                amount: +parseStrToFloatInputBRL(parcel.amount).toFixed(2),
                comments: parcel.comments,
              };
            });

            const dataSale = {
              people_id: +dataForm.people_id,
              account_plan_id: +dataForm.account_plan_id,
              date_sale: format(dataForm.date_sale, 'yyyy-MM-dd'),
              situation: dataForm.situation,
              payment_term: dataForm.payment_term,
              total: +total.toFixed(2),
              total_liquid: +totalLiq.toFixed(2),
              discount: dataForm.discount
                ? +parseStrToFloatInputBRL(dataForm.discount).toFixed(2)
                : null,
              comments: dataForm.comments,
              items: itemsParsed,
              installments: installmentsParsed,
            };

            const salePosted = await api.post('sales', dataSale);

            addToast({
              type: 'success',
              title: 'Venda cadastrada com Sucesso',
            });

            history.push({
              pathname: '/sales/view',
              state: { sale_id: salePosted.data.id },
            });
            return;
          }
        }

        const itemsParsed = items.map((item) => {
          return {
            product_id: +item.product_id,
            quantity: +item.quantity,
            price: +parseStrToFloatInputBRL(item.price).toFixed(2),
            sub_total: +parseStrToFloatInputBRL(item.sub_total).toFixed(2),
          };
        });

        const dataOrder = {
          people_id: +dataForm.people_id,
          account_plan_id: +dataForm.account_plan_id,
          date_sale: format(dataForm.date_sale, 'yyyy-MM-dd'),
          situation: dataForm.situation,
          total: +total.toFixed(2),
          total_liquid: +totalLiq.toFixed(2),
          discount: dataForm.discount
            ? +parseStrToFloatInputBRL(dataForm.discount).toFixed(2)
            : null,
          comments: dataForm.comments,
          items: itemsParsed,
        };

        const salePosted = await api.post('sales', dataOrder);

        addToast({
          type: 'success',
          title: 'Pedido cadastrado com Sucesso',
        });

        history.push({
          pathname: '/sales/view',
          state: { sale_id: salePosted.data.id },
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          setErrorsValidateSubmit(true);

          formRef.current.setErrors(errors);
          return;
        }
        setErrorsValidateSubmit(false);
        console.log(JSON.stringify(err.data));

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, history, items, total, totalLiq, isSale]
  );

  const calculateInstallments = useCallback((totalSale, numParcel) => {
    const valueParcel = (totalSale / numParcel).toFixed(2);
    const lastParcel = (totalSale - valueParcel * (numParcel - 1)).toFixed(2);

    return {
      valueParcel,
      lastParcel,
    };
  }, []);

  const handleInstallments = useCallback(async () => {
    const payment_term = formRef.current.getFieldValue('payment_term');

    if (payment_term === 'avista') {
      setObjInstallments([]);
      formRef.current.setFieldValue('maturity_date', new Date());
      formRef.current.setFieldValue('amount', formatedInputBRL(totalLiq));
      setShowParcel(false);
      return;
    }

    if (payment_term === '1') {
      setObjInstallments([]);
      const maturityMonth = addMonths(new Date(), 1);
      formRef.current.setFieldValue('maturity_date', maturityMonth);
      formRef.current.setFieldValue('amount', formatedInputBRL(totalLiq));
      setShowParcel(false);
      return;
    }

    if (+payment_term >= 2) {
      setShowParcel(true);
      setObjInstallments([]);
      const { lastParcel, valueParcel } = calculateInstallments(
        totalLiq,
        +payment_term
      );

      for (let index = 0; index < +payment_term; index++) {
        const obj = {
          parcel: `${index + 1} / ${payment_term}`,
          valueParcel: index === +payment_term - 1 ? lastParcel : valueParcel,
          maturity_date: addMonths(new Date(), index + 1),
          comments: '',
        };
        setObjInstallments((state) => [...state, obj]);
      }
    }
  }, [calculateInstallments, totalLiq]);

  const handleAddItem = useCallback(async () => {
    const product_id = formRef.current.getFieldValue('product_id');
    const quantity = formRef.current.getFieldValue('quantity');
    const price = formRef.current.getFieldValue('price');
    formRef.current.setFieldValue('discount', '');

    const product =
      products &&
      products.filter((p) => {
        return p.id === +product_id;
      });

    const sub_total = +quantity * +parseStrToFloatInputBRL(price).toFixed(2);

    const itemAdd = {
      product_id,
      product: product[0]?.description,
      quantity,
      price,
      sub_total: formatedInputBRL(sub_total),
    };

    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        product_id: Yup.string().required('Selecione um produto'),
        quantity: Yup.string().required('Digite a quantidade'),
        price: Yup.string().required('Digite o preço'),
      });

      await schema.validate(itemAdd, {
        abortEarly: false,
      });

      const productExists = items.filter((item) => {
        return item.product_id === product_id;
      });

      if (productExists[0]) {
        addToast({
          type: 'error',
          title: 'Produto já foi adicionado',
        });
        return;
      }

      setItems((state) => [...state, itemAdd]);

      setTotal((state) => state + sub_total);
      setTotalLiq((state) => state + sub_total);

      formRef.current.setFieldValue('product_id', '');
      formRef.current.setFieldValue('price', '');
      formRef.current.setFieldValue('quantity', '');

      formRef.current.setFieldValue('payment_term', 'avista');
      setObjInstallments([]);
      setShowParcel(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setErrorsValidateSubmit(true);

        formRef.current.setErrors(errors);
      }
      setErrorsValidateSubmit(false);
    }
  }, [products, items, addToast]);

  const handleRemoveItem = useCallback(
    (productId) => {
      const itemRemoved = items.filter(
        (item) => +item.product_id === +productId
      );

      const sub_total = +parseStrToFloatInputBRL(itemRemoved[0].sub_total);

      setTotal((state) => state - sub_total);
      setTotalLiq((state) => state - sub_total);

      setItems(items.filter((item) => item.product_id !== productId));

      formRef.current.setFieldValue('payment_term', 'avista');
      setObjInstallments([]);
      setShowParcel(false);
    },
    [items]
  );

  const handleDiscount = useCallback(() => {
    if (total === 0) {
      return;
    }
    const discountinput = formRef.current.getFieldValue('discount');

    const discount = +parseStrToFloatInputBRL(discountinput).toFixed(2);

    setTotalLiq(total - discount);

    formRef.current.setFieldValue('payment_term', 'avista');
    setShowParcel(false);
  }, [total]);

  const handlePrice = useCallback(
    (idProduct) => {
      if (!idProduct) {
        return;
      }

      formRef.current.setFieldValue('price', '');
      formRef.current.setFieldValue('quantity', '');

      const product =
        products &&
        products.filter((p) => {
          return p.id === +idProduct;
        });

      formRef.current.setFieldValue(
        'price',
        product[0]?.sale_price ? product[0].sale_price : ''
      );

      const quantityInput = formRef.current.getFieldRef('quantity');

      quantityInput.focus();
    },
    [products]
  );

  const handleSituation = useCallback((e) => {
    const situation = e.target.value;

    if (situation === 'sale') {
      setIsSale(true);
      return;
    }

    formRef.current.setFieldValue('payment_term', 'avista');
    setObjInstallments([]);

    setIsSale(false);
    setShowParcel(false);
  }, []);

  const handleEnter = useCallback((e) => {
    if (e.keyCode === 13) e.preventDefault();
  }, []);

  return (
    <>
      <NavBarMenu />

      <ContainerBox title={isSale ? 'Nova Venda' : 'Novo Pedido'}>
        <Form
          initialData={location.state ? location.state.item : {}}
          ref={formRef}
          onSubmit={handleSubmit}
          onKeyDown={(e) => handleEnter(e)}
        >
          <Wrapper>
            <GroupFieldsCol3>
              <Select label="Cliente:" name="people_id" id="people_id">
                <option value="">Selecione o Cliente</option>
                {clients &&
                  clients
                    .filter((item) => item.client)
                    .map((p) => (
                      <option key={p.id} value={p.id}>
                        {p.pf?.name || p.pj?.fantasy_name}
                      </option>
                    ))}
              </Select>
              <Select
                label="Plano de Contas*"
                name="account_plan_id"
                id="account_plan_id"
              >
                <option value="">Selecione o Plano de Contas</option>
                {accountPlans &&
                  accountPlans
                    .filter((item) => item.type === 'r')
                    .map((acc) => (
                      <option key={acc.id} value={acc.id}>
                        {acc.name}
                      </option>
                    ))}
              </Select>
              <InputDatePicker
                name="date_sale"
                id="date_sale"
                label="Data da Venda*"
              />
            </GroupFieldsCol3>
            <GroupFieldsCol3 style={{ marginTop: 16 }}>
              <Select
                label="Situação da Negociação*"
                name="situation"
                id="situation"
                bg
                onChange={(e) => handleSituation(e)}
              >
                <option value="order_draft">Pedido Rascunho</option>
                <option value="order_confirmed">Pedido Confirmado</option>
                <option value="order_canceled">Pedido Cancelado</option>
                <option value="sale">Venda</option>
              </Select>
            </GroupFieldsCol3>
            <h3>Produtos</h3>

            <GroupFieldsCol5>
              <Select
                label="Produto*"
                name="product_id"
                id="product_id"
                style={{
                  width: 480,
                }}
                onChange={(e) => handlePrice(e.target.value)}
              >
                <option value="">Selecione o Produto</option>
                {products &&
                  products.map((prod) => (
                    <option key={prod.id} value={prod.id}>
                      {prod.description}
                    </option>
                  ))}
              </Select>
              <InputInteger
                style={{
                  maxWidth: 160,
                }}
                id="quantity"
                name="quantity"
                label="Qtde*"
                autoComplete="off"
              />
              <InputBRL
                style={{
                  maxWidth: 160,
                }}
                id="price"
                name="price"
                label="Valor unititário*"
                autoComplete="off"
              />

              <ButtonAddItem onClick={handleAddItem}>
                <FiPlus size={18} />
                Adicionar item
              </ButtonAddItem>
            </GroupFieldsCol5>

            <Table data={items} head={head} handleDelete={handleRemoveItem} />

            <Total>
              <SubTotal>
                <p>Valor Total</p>
                <span>{formatedInputBRL(total)}</span>
              </SubTotal>
              <SubTotal2>
                <p>Desconto R$ </p>
                <InputBRL
                  style={{ maxWidth: 100, textAlign: 'right' }}
                  name="discount"
                  placeholder="R$ 0,00"
                  onChange={() => handleDiscount()}
                />
              </SubTotal2>
              <TotalLiq>
                <p>Total líquido</p>
                <span>{formatedInputBRL(totalLiq)}</span>
              </TotalLiq>
            </Total>
            <h3> </h3>
            <Payment visible={isSale}>
              <Select
                style={{ width: 200 }}
                bg
                label="Condição de pagamento"
                name="payment_term"
                id="payment_term"
                onChange={() => handleInstallments()}
                defaultValue="avista"
              >
                <option value="avista">À Vista</option>
                <option value="1">1X</option>
                <option value="2">2X</option>
                <option value="3">3X</option>
                <option value="4">4X</option>
                <option value="5">5X</option>
                <option value="6">6X</option>
                <option value="7">7X</option>
                <option value="8">8X</option>
                <option value="9">9X</option>
                <option value="10">10X</option>
                <option value="11">11X</option>
                <option value="12">12X</option>
                <option value="13">13X</option>
                <option value="14">14X</option>
                <option value="15">15X</option>
                <option value="16">16X</option>
                <option value="17">17X</option>
                <option value="18">18X</option>
                <option value="19">19X</option>
                <option value="20">20X</option>
                <option value="21">21X</option>
                <option value="22">22X</option>
                <option value="23">23X</option>
                <option value="24">24X</option>
                <option value="25">25X</option>
                <option value="26">26X</option>
                <option value="27">27X</option>
                <option value="28">28X</option>
                <option value="29">29X</option>
                <option value="30">30X</option>
                <option value="31">31X</option>
                <option value="32">32X</option>
                <option value="33">33X</option>
                <option value="34">34X</option>
                <option value="35">35X</option>
                <option value="36">36X</option>
                <option value="37">37X</option>
                <option value="38">38X</option>
                <option value="39">39X</option>
                <option value="40">40X</option>
                <option value="41">41X</option>
                <option value="42">42X</option>
                <option value="43">43X</option>
                <option value="44">44X</option>
                <option value="45">45X</option>
                <option value="46">46X</option>
                <option value="47">47X</option>
                <option value="48">48X</option>
              </Select>

              <DivVisible visible={!showParcel}>
                <article style={{ marginLeft: 24 }}>
                  <InputDatePicker
                    name="maturity_date"
                    id="maturity_date"
                    label="Data Vencimento*"
                  />
                </article>

                <article>
                  <Input
                    style={{
                      width: 604,
                    }}
                    id="comments_parcel"
                    name="comments_parcel"
                    label="Observações desta parcela"
                    autoComplete="off"
                  />
                </article>
              </DivVisible>
            </Payment>

            <Installments visible={showParcel}>
              {objInstallments.map((installment, i) => {
                return (
                  <InstallmentsItem key={installment.parcel}>
                    <Scope path={`installments[${i}]`}>
                      <section>
                        Parcela
                        <Input
                          style={{ margin: 0, padding: 0 }}
                          type="hidden"
                          name="parcel"
                          id="parcel"
                          defaultValue={installment.parcel}
                        />
                        <span>{installment.parcel}</span>
                      </section>

                      <article>
                        <InputDatePicker
                          name="maturity_date"
                          id="maturity_date"
                          label="Data Vencimento"
                          dateSelected={installment.maturity_date}
                        />
                      </article>
                      <article>
                        <InputBRL
                          name="amount"
                          id="amount"
                          placeholder="R$ 0,00"
                          label="Valor"
                          defaultValue={formatedInputBRL(
                            installment.valueParcel
                          )}
                        />
                      </article>
                      <article>
                        <Input
                          style={{ width: 360 }}
                          id="comments"
                          name="comments"
                          label="Observações desta parcela"
                          autoComplete="off"
                        />
                      </article>
                    </Scope>
                  </InstallmentsItem>
                );
              })}
            </Installments>
            {isSale && <Hr />}
            <TextArea name="comments" id="comments" label="Observações" />
          </Wrapper>

          <ButtonGroup>
            <ButtonSave icon={FiCheck}>Salvar</ButtonSave>
            <Link to="/sales">
              <ButtonCancel icon={FiX}>Cancelar</ButtonCancel>
            </Link>
          </ButtonGroup>
          {errorsValidateSubmit && <ErrorBoxSubmit />}
        </Form>
      </ContainerBox>
    </>
  );
};

export default SalesNewEdit;
