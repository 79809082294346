import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    color: #616161;
    margin-bottom: 4px;
  }

  input {
    max-width: 176px;
    width: 100%;
    height: 40px;
    padding: 6px 8px;
    background: #f9f9f9;
    color: #616161;
    border: 2px solid #d1d5da;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

    ::placeholder {
      color: #d1d5da;
    }

    ${(props) =>
      props.isFocused &&
      css`
        border-color: #2188ff;
        box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
          0 0 0 0.2em rgba(3, 102, 214, 0.3);
        transition: all 0.2s ease-in-out;
      `}

    ${(props) =>
      props.isErrored &&
      props.isFilled &&
      css`
        border: 2px solid #d1d5da;
        box-shadow: none;
      `}
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonPrevious = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 40px;
  background: #f9f9f9;
  color: #616161;
  border-radius: 4px 0 0 4px;
  border: 2px solid #d1d5da;
  margin-right: -2px;
`;

export const ButtonNext = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 40px;
  background: #f9f9f9;
  color: #616161;
  border-radius: 0 4px 4px 0;
  border: 2px solid #d1d5da;
  margin-left: -2px;
`;
