import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const TableBodyItem = styled.tr`
  transition: all 0.2s ease;

  td {
    text-align: center;
    border-bottom: 1px dotted #d4d4d4;

    :nth-child(1) {
      a {
        justify-content: center;
      }
    }
    :nth-child(2) {
      a {
        justify-content: flex-start;
      }
    }
    :nth-child(3) {
      max-width: 300px;
      a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    :nth-child(4) {
      a {
        max-width: 160px;
        justify-content: center;
      }
      ${(props) =>
        props.canceled &&
        css`
          a {
            background: #fff2f0;
            color: ${lighten(0.1, '#e02041')};

            font-size: 12px;
            font-weight: bold;
            padding: 4px;
            border-radius: 4px;
            margin: auto;
          }
        `}

      ${(props) =>
        props.daft &&
        css`
          a {
            background: #fff4ce;
            color: #b98e00;

            font-size: 12px;
            font-weight: bold;
            padding: 4px;
            border-radius: 4px;
            margin: auto;
          }
        `}

        ${(props) =>
        props.confirmed &&
        css`
          a {
            background: #f2ffed;
            color: #52c41a;

            font-size: 12px;
            font-weight: bold;
            padding: 4px;
            border-radius: 4px;
            margin: auto;
          }
        `}
        ${(props) =>
        props.sale &&
        css`
          a {
            background: #f0f5ff;
            color: #2f54eb;

            font-size: 12px;
            font-weight: bold;
            padding: 4px;
            border-radius: 4px;
            margin: auto;
          }
        `}
    }
    :nth-child(5) {
      font-weight: bold;
      a {
        justify-content: flex-end;
      }
    }
    :nth-child(6) {
      border-left: dotted 1px #d1d5da;
    }

    a {
      display: flex;
      padding: 18px 16px;
    }
  }

  :hover {
    background: #f5f5f9;
  }
`;

export const Actions = styled.button`
  border: 0;
  background: transparent;
  margin: 0;
  padding: 8px 16px;

  svg {
    margin: 0;
  }
`;
