import React from 'react';

import Head from './Head';
import Row from './Row';

import NoRegisterFound from '../../../components/NoRegisterFound';

import { TableList, TableBody } from './styles';

const Table = ({
  data,
  head,
  handleDelete,
  handleLaunchCredit,
  handleReverseLaunchCredit,
  pathname,
}) => {
  if (!data[0]) {
    return <NoRegisterFound />;
  }

  const keys = Object.keys(data[0]);

  return (
    <TableList>
      <Head head={head} keys={keys} />
      <TableBody>
        {data.map((item) => (
          <Row
            item={item}
            key={item.id}
            handleLaunchCredit={handleLaunchCredit}
            handleReverseLaunchCredit={handleReverseLaunchCredit}
            handleDelete={handleDelete}
            pathname={pathname}
          />
        ))}
      </TableBody>
    </TableList>
  );
};

export default Table;
