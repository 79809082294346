import React, { useCallback, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { Form } from '@unform/web';

import { mutate as mutateGlobal } from 'swr';
import api from '../../services/api';
import { useFetch } from '../../hooks/useFetch';
import { useToast } from '../../hooks/toast';

import ButtonPrimaryGree from '../../components/ButtonPrimaryGreen';
import { InputSearch } from '../../components/Form';
import NavbarMenu from '../../components/NavbarMenu';
import ContainerBox from '../../components/ContainerBox';
import ButtonGroupbox from '../../components/ContainerBox/ButtonGroupbox';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination';

import { Container, TotalItems } from './styles';

const DreGroups = () => {
  const formRef = useRef(null);

  const [page, setPage] = useState(1);
  const [url, setUrl] = useState('/brands?page=1');
  const [showDreGroups, setShowDreGroups] = useState(true);

  const { dataList: dreGroups, loading, totalItems } = useFetch(
    `/dregroups?page=${page}`
  );

  const { dataList: searchDreGroup, loading: loadingSearch } = useFetch(url);

  const { addToast } = useToast();

  const head = {
    id: 'ID',
    name: 'Grupo DRE',
  };

  const handleDreGroupDelete = useCallback(
    async (id) => {
      try {
        if (
          window.confirm(`Tem Certeza que realmente deseja excluir o registro?`)
        ) {
          await api.delete(`/dregroups/${id}`);

          mutateGlobal(`/dregroups?page=${page}`);
          mutateGlobal(url);

          addToast({
            type: 'success',
            title: 'Cadastro Excluido com Sucesso',
          });
        } else {
          return;
        }
      } catch (err) {
        if (err.response.status === 403) {
          addToast({
            type: 'error',
            title: 'Não Permitido excluir. ',
            description: 'Grupo DRE cadastrado em um plano de contas.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, page, url]
  );

  const handleSubmit = useCallback(() => {
    const input = formRef.current.getFieldValue('inputdregroup');

    if (!input) {
      setUrl('/dregroups?page=1');
      setPage(1);
      setShowDreGroups(true);

      return;
    }

    setUrl(`/dregroupsearch?search=${input}`);
    setShowDreGroups(false);
    formRef.current.reset();
  }, []);

  const handleEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Container>
      <NavbarMenu />
      <ContainerBox title="Grupo DRE">
        <ButtonGroupbox>
          <Link to="/dregroups/newedit">
            <ButtonPrimaryGree icon={FiPlus}>Adicionar</ButtonPrimaryGree>
          </Link>

          <Form ref={formRef} onSubmit={() => {}}>
            <InputSearch
              id="inputdregroup"
              name="inputdregroup"
              autoComplete="off"
              onKeyDown={(e) => handleEnter(e)}
              handleClick={handleSubmit}
            />
          </Form>
        </ButtonGroupbox>

        {showDreGroups && loading && <Loading />}

        {showDreGroups && !loading && dreGroups && (
          <>
            <Table
              head={head}
              data={dreGroups}
              handleDelete={handleDreGroupDelete}
              pathname="/dregroups/newedit"
            />
            {totalItems && (
              <TotalItems>
                <span>
                  Total de registro(s): <b>{totalItems}</b>
                </span>
              </TotalItems>
            )}
            {totalItems > 20 && (
              <Pagination
                setPage={setPage}
                currentPage={page}
                totalItems={totalItems}
              />
            )}
          </>
        )}

        {!showDreGroups && loadingSearch && <Loading />}

        {!showDreGroups && !loadingSearch && searchDreGroup && (
          <Table
            head={head}
            data={searchDreGroup}
            handleDelete={handleDreGroupDelete}
            pathname="/dregroups/newedit"
          />
        )}
      </ContainerBox>
    </Container>
  );
};

export default DreGroups;
