import React from 'react';

import { Container, Header } from './styles';

const ContainerBox = ({ children, title, small }) => {
  return (
    <Container small={small}>
      <Header>
        <h1>{title}</h1>
      </Header>
      {children}
    </Container>
  );
};

export default ContainerBox;
