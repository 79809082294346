import styled, { css } from 'styled-components';

export const Container = styled.div`
  label {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-top: 4px;
      height: 40px;
    }
  }

  select {
    width: 100%;
    height: 40px;
    padding: 6px 8px;
    background: #fff;
    color: #616161;
    border-radius: 4px;
    border: 2px solid #d1d5da;
    font-weight: bold;
    margin-right: 8px;

    transition: all 0.2s ease-in-out;

    :focus {
      border-color: #2188ff;
      box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
        0 0 0 0.2em rgba(3, 102, 214, 0.3);
      transition: all 0.2s ease-in-out;
    }

    ${(props) =>
      props.isErrored &&
      css`
        border-color: #e02041;
        box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
          0 0 0 0.2em rgba(224, 32, 65, 0.3);
      `}

    option {
      font-size: 16px;
    }
  }

  small {
    font-size: 12px;
    font-style: italic;
    color: #e02041;
    margin-top: 4px;
    transition: all 0.2s ease-in-out;
  }
`;
