import React, { useEffect, useRef, useState } from 'react';

import { addHours } from 'date-fns';

import { useField } from '@unform/core';

import pt from 'date-fns/locale/pt-BR';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Container } from './styles';

const InputDatePicker = ({ id, name, label, dateSelected, ...rest }) => {
  const datepickerRef = useRef(null);
  const { fieldName, error, registerField } = useField(name);

  const [date, setDate] = useState(dateSelected || new Date());

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      clearValue: (ref) => {
        ref.clear();
      },
      setValue: (e, v) => {
        setDate(v ? addHours(new Date(v), 6) : new Date()); // <---- Setting up default value
      },
      getValue: () => {
        return datepickerRef.current.props.selected; // to get selected value from Date picker's props
      },
    });
  }, [fieldName, registerField]);

  registerLocale('pt', pt);

  return (
    <Container isErrored={!!error}>
      <label {...rest} htmlFor={id}>
        {label}
      </label>

      <DatePicker
        name={name}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        dateFormat="dd/MM/yyyy"
        locale="pt"
        ref={datepickerRef}
        selected={date}
        onChange={(d) => setDate(d)}
        {...rest}
      />

      <small>{error}</small>
    </Container>
  );
};

export default InputDatePicker;
