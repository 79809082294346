import useSWR from 'swr';
import { format, addHours } from 'date-fns';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

import { formatedInputBRL } from '../../../utils/formatedInputs';

export const useFetchBillsToPays = (url) => {
  const { signOut } = useAuth();
  const { addToast } = useToast();

  const { data, error, mutate } = useSWR(
    url,
    async () => {
      const response = await api.get(url);

      const billsToPays = response.data.map((bills) => {
        return {
          id: bills.id,
          maturity_date: format(
            addHours(new Date(bills.maturity_date), 6),
            'dd/MM/yyyy'
          ),
          account_plan: bills.account_plan?.name,
          description: bills.description,
          provider: bills.peoples
            ? bills.peoples.pj?.fantasy_name || bills.peoples.pf?.name
            : '',
          amount: bills.paid
            ? formatedInputBRL(bills.payment_amount)
            : formatedInputBRL(bills.amount),
          paid:
            (bills.paid && !bills.paid_partial && 'Pago') ||
            (bills.paid && bills.paid_partial && 'Pago*') ||
            (!bills.paid && !bills.paid_partial && bills.past && 'Em Atraso') ||
            (!bills.paid && !bills.paid_partial && 'Em Aberto'),
          past: bills.past,
        };
      });

      return { data: billsToPays };
    },
    {
      revalidateOnReconnect: true,

      onError: (err) => {
        if (err.response.status === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada. Faça seu login.',
          });
          signOut();
        }
      },
    }
  );

  return {
    dataList: data && data.data,
    loading: !data && !error,
    error,
    mutate,
  };
};
