import React, { useCallback, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { Form } from '@unform/web';
import { format, addMonths, subMonths } from 'date-fns';

import { mutate as mutateGlobal } from 'swr';
import api from '../../services/api';
import { useFetchBillsToPays } from './hooks/useFetchBillsToPays';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import ButtonPrimaryGree from '../../components/ButtonPrimaryGreen';
import { InputSearch, InputDatePickerMonth } from '../../components/Form';
import NavbarMenu from '../../components/NavbarMenu';
import ContainerBox from '../../components/ContainerBox';
import ButtonGroupbox from '../../components/ContainerBox/ButtonGroupbox';
import Table from './Table';
import Loading from '../../components/Loading';

import { Container } from './styles';

const BillsToPay = () => {
  const { user } = useAuth();

  const formRef = useRef(null);

  const [url, setUrl] = useState('/billstopaysearch?search=%');
  const [showBillsToPays, setShowBillsToPays] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const urlMonth = useMemo(() => format(currentMonth, 'yyyy-MM-dd'), [
    currentMonth,
  ]);

  const { dataList: billsToPays, loading } = useFetchBillsToPays(
    `billstopays?month=${urlMonth}`
  );

  const {
    dataList: searchBillsToPays,
    loading: loadingSearch,
  } = useFetchBillsToPays(url);

  const { addToast } = useToast();

  const head = {
    id: 'ID',
    maturity_date: 'Vencimento',
    account_plan: 'Plano Contas',
    description: 'Descrição',
    provider: 'Fornecedor',
    amount: 'Valor',
    paid: 'Situação',
    past: ' ',
  };

  const handlePreviousMonth = useCallback(() => {
    setCurrentMonth(subMonths(currentMonth, 1));
  }, [currentMonth]);

  const handleNextMonth = useCallback(() => {
    setCurrentMonth(addMonths(currentMonth, 1));
  }, [currentMonth]);

  const handleSubmit = useCallback(() => {
    const input = formRef.current.getFieldValue('inputbills');

    if (!input) {
      setUrl('/billstopaysearch?search=%');
      setShowBillsToPays(true);
      return;
    }

    setUrl(`/billstopaysearch?search=${input}`);
    setShowBillsToPays(false);
    formRef.current.reset();
  }, []);

  const handleDeleteBillsToPay = useCallback(
    async (id) => {
      try {
        if (
          window.confirm(`Tem Certeza que realmente deseja excluir o registro?`)
        ) {
          await api.delete(`/billstopays/${id}`);

          mutateGlobal(`billstopays?month=${urlMonth}`);
          mutateGlobal(url);

          addToast({
            type: 'success',
            title: 'Cadastro Excluido com Sucesso',
          });
        } else {
          return;
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, url, urlMonth]
  );

  const handleLaunchDebit = useCallback(
    async (id) => {
      try {
        const billToPayLaunched = {
          bills_to_pay_id: id,
          user_id: user.id,
        };
        await api.post(`/launchdebitaccount`, billToPayLaunched);

        mutateGlobal(`billstopays?month=${urlMonth}`);

        addToast({
          type: 'success',
          title: 'Pagamento confirmado com Sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao confirmar pagamento!',
        });
      }
    },
    [addToast, user, urlMonth]
  );

  const handleReverseLaunchDebit = useCallback(
    async (item) => {
      try {
        if (item.paid === 'Pago*') {
          if (
            window.confirm(
              `ATENÇÃO! Pagamento parcial. Se confirmar o estorno deverá excluir o lançamento gerado do restante contas a pagar.`
            )
          ) {
            const billToPayReverseLaunched = {
              bills_to_pay_id: item.id,
              user_id: user.id,
            };
            await api.post(
              `/reverselaunchdebitaccount`,
              billToPayReverseLaunched
            );

            mutateGlobal(`billstopays?month=${urlMonth}`);

            addToast({
              type: 'success',
              title: 'Estorno de pagamento confirmado com Sucesso',
            });
            return;
          }
        }

        if (item.paid === 'Pago') {
          const billToPayReverseLaunched = {
            bills_to_pay_id: item.id,
            user_id: user.id,
          };
          await api.post(
            `/reverselaunchdebitaccount`,
            billToPayReverseLaunched
          );

          mutateGlobal(`billstopays?month=${urlMonth}`);

          addToast({
            type: 'success',
            title: 'Estorno de pagamento confirmado com Sucesso',
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao confirmar estorno de pagamento!',
        });
      }
    },
    [addToast, user, urlMonth]
  );

  const handleEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Container>
      <NavbarMenu />
      <ContainerBox title="Contas a Pagar">
        <ButtonGroupbox>
          <Link to="/billstopays/newedit">
            <ButtonPrimaryGree icon={FiPlus}>Novo Pagamento</ButtonPrimaryGree>
          </Link>

          <InputDatePickerMonth
            selected={currentMonth}
            onChange={(date) => setCurrentMonth(date)}
            handlePreviousMonth={handlePreviousMonth}
            handleNextMonth={handleNextMonth}
          />

          <Form ref={formRef} onSubmit={() => {}}>
            <InputSearch
              id="inputbills"
              name="inputbills"
              autoComplete="off"
              onKeyDown={(e) => handleEnter(e)}
              handleClick={handleSubmit}
            />
          </Form>
        </ButtonGroupbox>

        {showBillsToPays && loading && <Loading />}

        {showBillsToPays && !loading && billsToPays && (
          <>
            <Table
              head={head}
              data={billsToPays}
              handleDelete={handleDeleteBillsToPay}
              handleLaunchDebit={handleLaunchDebit}
              handleReverseLaunchDebit={handleReverseLaunchDebit}
              pathname="/billstopays/newedit"
            />
          </>
        )}

        {!showBillsToPays && loadingSearch && <Loading />}

        {!showBillsToPays && !loadingSearch && searchBillsToPays && (
          <Table
            head={head}
            data={searchBillsToPays}
            handleDelete={handleDeleteBillsToPay}
            handleLaunchDebit={handleLaunchDebit}
            handleReverseLaunchDebit={handleReverseLaunchDebit}
            pathname="/billstopays/newedit"
          />
        )}
      </ContainerBox>
    </Container>
  );
};

export default BillsToPay;
