import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FiCheck, FiX, FiEdit2 } from 'react-icons/fi';
import { mutate as mutateGlobal } from 'swr';
import * as Yup from 'yup';

import { Form } from '@unform/web';

import getValidationErrors from '../../../utils/getValidationErrors';
import { parseStrToFloatInputBRL } from '../../../utils/parseStrToFloatInputBRL';
import {
  formatedInputDatePicker,
  formatedInputBRL,
} from '../../../utils/formatedInputs';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import NavBarMenu from '../../../components/NavbarMenu';
import ContainerBox from '../../../components/ContainerBox';

import {
  Input,
  Select,
  CheckBox,
  InputBRL,
  InputDatePicker,
} from '../../../components/Form';
import ButtonSave from '../../../components/ButtonPrimaryBlue';
import ButtonCancel from '../../../components/ButtonCancel';
import ButtonDefault from '../../../components/ButtonDefault';
import Loading from '../../../components/Loading';

import { ButtonGroup, GroupFields, HeaderGroup } from './styles';

const AccountNewEdit = () => {
  const { user } = useAuth();

  const location = useLocation();

  const [id, setId] = useState(null);
  const [showBank, setShowBank] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [accountData, setAccountData] = useState({});

  const history = useHistory();

  const formRef = useRef(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (!location.state) {
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      const response = await api.get(`/accounts/${location.state.item.id}`);

      if (response.data.type === 'caixinha') {
        setShowBank(false);
      }

      const dataAccount = {
        type: response.data.type,
        description: response.data.description,
        account_default: response.data.account_default,
        bank: response.data.bank,
        agency: response.data.agency,
        account: response.data.account,
        pj: response.data.pj ? 'pj' : 'pf',
        initial_balance:
          response.data.initial_balance &&
          formatedInputBRL(response.data.initial_balance),
        initial_balance_date:
          response.data.initial_balance_date &&
          formatedInputDatePicker(response.data.initial_balance_date),
      };

      setIsLoading(false);
      setAccountData(response.data);

      formRef.current.setData(dataAccount);
    };

    fetchData();

    setId(location.state.item.id);
  }, [location.state]);

  useEffect(() => {
    if (!formRef.current) return;

    const typeSelect = formRef.current.getFieldRef('type');

    typeSelect.focus();
  }, []);

  const handleSubmit = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          type: Yup.string().required('Selecione um tipo de conta'),
          bank: Yup.string().when('type', {
            is: (val) => val !== 'caixinha',
            then: Yup.string().required('Banco é obrigatório'),
            otherwise: Yup.string(),
          }),
          agency: Yup.string().when('type', {
            is: (val) => val !== 'caixinha',
            then: Yup.string().required('Agência é obrigatório'),
            otherwise: Yup.string(),
          }),
          account: Yup.string().when('type', {
            is: (val) => val !== 'caixinha',
            then: Yup.string().required('Conta é obrigatório'),
            otherwise: Yup.string(),
          }),
          description: Yup.string().required('Descrição é obrigatório'),
          initial_balance: Yup.string().required('Saldo é obrigatório'),
          initial_balance_date: Yup.string().required(
            'Data do Saldo é obrigatório'
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const response = await api.get('accountdefault');

        if (response.data.id && dataForm.account_default) {
          addToast({
            type: 'error',
            title: 'Já existe uma conta Padrão.',
            description: 'Só pode ter uma conta padrão para movimento',
          });
          return;
        }

        const dataAccount = {
          type: dataForm.type,
          description: dataForm.description,
          account_default: !response.data.id && true,
          bank: dataForm.type !== 'caixinha' ? dataForm.bank : null,
          agency: dataForm.type !== 'caixinha' ? dataForm.agency : null,
          account: dataForm.type !== 'caixinha' ? dataForm.account : null,
          pj: dataForm.type !== 'caixinha' ? dataForm.pj === 'pj' : null,
          initial_balance: +parseStrToFloatInputBRL(dataForm.initial_balance),
          initial_balance_date: new Date(dataForm.initial_balance_date),

          current_balance: +parseStrToFloatInputBRL(dataForm.initial_balance),
          current_balance_date: new Date(dataForm.initial_balance_date),
        };

        const account = await api.post('accounts', dataAccount);

        const initialBalanceData = {
          account_id: account.data?.id,
          user_id: user.id,
        };

        await api.post('launchinitialbalance', initialBalanceData);
        mutateGlobal(`/accounts?page=1`);

        addToast({
          type: 'success',
          title: 'Cadastro efetuado com Sucesso',
        });

        history.push('/accounts');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.response.status === 403) {
          addToast({
            type: 'error',
            description: 'Descrição de conta já cadastrada',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, history, user.id]
  );

  const handleUpdate = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          type: Yup.string().required('Selecione um tipo de conta'),
          bank: Yup.string().when('type', {
            is: (val) => val !== 'caixinha',
            then: Yup.string().required('Banco é obrigatório'),
            otherwise: Yup.string(),
          }),
          agency: Yup.string().when('type', {
            is: (val) => val !== 'caixinha',
            then: Yup.string().required('Agência é obrigatório'),
            otherwise: Yup.string(),
          }),
          account: Yup.string().when('type', {
            is: (val) => val !== 'caixinha',
            then: Yup.string().required('Conta é obrigatório'),
            otherwise: Yup.string(),
          }),
          description: Yup.string().required('Descrição é obrigatório'),
          initial_balance: Yup.string().required('Saldo é obrigatório'),
          initial_balance_date: Yup.string().required(
            'Data do Saldo é obrigatório'
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const response = await api.get('accountdefault');

        if (dataForm.account_default && response.data.id) {
          if (response.data.id !== id) {
            addToast({
              type: 'error',
              title: 'Já existe uma conta Padrão.',
              description: 'Só pode ter uma conta padrão para movimento',
            });
            return;
          }
        }

        const dataAccount = {
          type: dataForm.type,
          description: dataForm.description,
          account_default: dataForm.account_default,
          bank: dataForm.type !== 'caixinha' ? dataForm.bank : null,
          agency: dataForm.type !== 'caixinha' ? dataForm.agency : null,
          account: dataForm.type !== 'caixinha' ? dataForm.account : null,
          pj: dataForm.type !== 'caixinha' ? dataForm.pj === 'pj' : null,
        };

        await api.patch(`accounts/${id}`, dataAccount);

        mutateGlobal(`accounts/${id}`, dataAccount);

        addToast({
          title: 'Cadastro alterado com Sucesso',
        });

        history.push('/accounts');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.response.status === 403) {
          addToast({
            type: 'error',
            description: 'Descrição já cadastrado',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, history, id]
  );

  const handleTypeAccount = useCallback(() => {
    if (formRef.current.getFieldValue('type') !== 'caixinha') {
      setShowBank(true);
      return;
    }

    formRef.current.setFieldValue('bank', '');
    formRef.current.setFieldValue('agency', '');
    formRef.current.setFieldValue('account', '');
    formRef.current.setFieldValue('pj', null);
    setShowBank(false);
  }, []);

  return (
    <>
      <NavBarMenu />

      {isLoading && <Loading />}

      {!isLoading && (
        <ContainerBox
          title={location.state ? 'Alterar Conta' : 'Cadastro Conta'}
        >
          {location.state && (
            <HeaderGroup>
              <Link
                to={{
                  pathname: '/accounts/editinitialbalance',
                  state: { accountData },
                }}
              >
                <ButtonDefault icon={FiEdit2}>
                  Editar Saldo Inicial
                </ButtonDefault>
              </Link>

              <p>
                Saldo Atual no Sistema:&nbsp;&nbsp;&nbsp;{' '}
                {formatedInputBRL(accountData.current_balance)}
              </p>
            </HeaderGroup>
          )}

          <Form
            ref={formRef}
            onSubmit={location.state ? handleUpdate : handleSubmit}
          >
            <GroupFields>
              <Select
                label="Tipo de conta bancária ou de movimentação*"
                name="type"
                id="type"
                onChange={handleTypeAccount}
              >
                <option value="">Selecione o Tipo da conta</option>
                <option value="corrente">Conta Corrente</option>
                <option value="caixinha">Caixinha</option>
                <option value="poupanca">Poupança</option>
              </Select>

              <Input
                id="description"
                name="description"
                label="Descrição da Conta*"
                placeholder="Ex: Ítau - Conta Corrente"
                autoComplete="off"
              />
              <CheckBox
                id="account_default"
                name="account_default"
                label="Conta Padrão"
                title="Conta padrão de lançamentos financeiros"
              />
            </GroupFields>
            {showBank && (
              <>
                <GroupFields>
                  <Select label="Banco*" name="bank" id="bank">
                    <option value="">Selecione o Banco</option>
                    <option value="bb">Banco do Brasil</option>
                    <option value="bradesco">Banco Bradesco</option>
                    <option value="itau">Banco Itaú</option>
                    <option value="santander">Banco Santander</option>
                    <option value="caixa">Caixa Econômica</option>
                    <option value="sicoob">Banco SICOOB</option>
                    <option value="sicredi">Banco SICREDI</option>
                    <option value="safra">Banco Safra</option>
                    <option value="outro">Outro</option>
                  </Select>
                  <Input
                    id="agency"
                    name="agency"
                    label="Agência*"
                    autoComplete="off"
                  />
                  <Input
                    id="account"
                    name="account"
                    label="Número da Conta*"
                    autoComplete="off"
                  />
                </GroupFields>
              </>
            )}
            <GroupFields>
              <div className={!showBank ? 'none' : ''}>
                <Select label="Modalidade" name="pj" id="pj">
                  <option value="pj">Pessoa Jurídica</option>
                  <option value="pf">Pessoa Física</option>
                </Select>
              </div>

              <InputBRL
                id="initial_balance"
                name="initial_balance"
                label="Saldo Inicial R$*"
                autoComplete="off"
                className={location.state ? 'noactive' : ''}
                readOnly={location.state}
              />
              <InputDatePicker
                id="initial_balance_date"
                name="initial_balance_date"
                label="Data do Saldo Inicial*"
                autoComplete="off"
                className={location.state ? 'noactive' : ''}
                readOnly={location.state}
              />
            </GroupFields>

            <ButtonGroup>
              <ButtonSave icon={FiCheck}>Salvar</ButtonSave>
              <Link to="/accounts">
                <ButtonCancel icon={FiX}>Cancelar</ButtonCancel>
              </Link>
            </ButtonGroup>
          </Form>
        </ContainerBox>
      )}
    </>
  );
};

export default AccountNewEdit;
