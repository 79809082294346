import styled from 'styled-components';

export const Content = styled.div`
  margin: 40px 0;
  max-width: 500px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 40px;

  button {
    margin-right: 8px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 0px;
`;

export const WrapperDescription = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 24px;
`;

export const GroupFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
  height: 85px;
`;

export const SwitchGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4px;
  }

  span {
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => (props.checkStatus ? '#2693e6' : '#e02041')};
    margin-top: 4px;
  }
`;
