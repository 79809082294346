import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/_layouts/Login';
import Dashboard from '../pages/Dashboard';
import Brands from '../pages/Brands';
import BrandsNewEdit from '../pages/Brands/BrandsNewEdit';
import People from '../pages/People';
import PeopleNewEdit from '../pages/People/PeopleNewEdit';
import Professions from '../pages/Professions';
import ProfessionsNewEdit from '../pages/Professions/ProfessionsNewEdit';
import DreGroupsNewEdit from '../pages/DREgroups/DreGroupsNewEdit';
import DreGroups from '../pages/DREgroups';
import AccountPlanNewEdit from '../pages/AccountPlan/AccountPlanNewEdit';
import AccountPlan from '../pages/AccountPlan';
import CoastCentersNewEdit from '../pages/CoastCenters/CoastCentersNewEdit';
import CoastCenters from '../pages/CoastCenters';
import AccountNewEdit from '../pages/Accounts/AccountNewEdit';
import AccountEditInitialBalance from '../pages/Accounts/AccountEditInitialBalance';
import Accounts from '../pages/Accounts';
import ClassificationNewEdit from '../pages/Classifications/ClassificationsNewEdit';
import Classifications from '../pages/Classifications';
import CategoriesNewEdit from '../pages/Categories/CategoriesNewEdit';
import Categories from '../pages/Categories';
import UnitsNewEdit from '../pages/Units/UnitsNewEdit';
import Units from '../pages/Units';
import ProductsNewEdit from '../pages/Products/ProductsNewEdit';
import Products from '../pages/Products';
import BillsToPayNewEdit from '../pages/BillsToPay/BillsToPayNewEdit';
import BillsToPay from '../pages/BillsToPay';
import BillsToReceiveNewEdit from '../pages/BillsToReceive/BillsToReceiveNewEdit';
import BillsToReceive from '../pages/BillsToReceive';
import SalesNew from '../pages/Sales/SalesNew';
import SalesEdit from '../pages/Sales/SalesEdit';
import Sales from '../pages/Sales';
import SaleView from '../pages/Sales/SaleView';
import SalesReceivable from '../pages/Sales/SalesReceivable';

const NoMatch = () => <h2>Página não encontrada! ERROR 404!</h2>;

const Routes = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route exact path="/" component={Dashboard} isPrivate />
    <Route path="/brands/newedit" component={BrandsNewEdit} isPrivate />
    <Route path="/brands" component={Brands} isPrivate />
    <Route path="/peoples/newedit" component={PeopleNewEdit} isPrivate />
    <Route path="/peoples" component={People} isPrivate />
    <Route
      path="/professions/newedit"
      component={ProfessionsNewEdit}
      isPrivate
    />
    <Route path="/professions" component={Professions} isPrivate />
    <Route path="/dregroups/newedit" component={DreGroupsNewEdit} isPrivate />
    <Route path="/dregroups" component={DreGroups} isPrivate />
    <Route
      path="/accountplans/newedit"
      component={AccountPlanNewEdit}
      isPrivate
    />
    <Route path="/accountplans" component={AccountPlan} isPrivate />
    <Route
      path="/coastcenters/newedit"
      component={CoastCentersNewEdit}
      isPrivate
    />
    <Route path="/coastCenters" component={CoastCenters} isPrivate />
    <Route path="/accounts/newedit" component={AccountNewEdit} isPrivate />
    <Route
      path="/accounts/editinitialbalance"
      component={AccountEditInitialBalance}
      isPrivate
    />
    <Route path="/accounts" component={Accounts} isPrivate />
    <Route
      path="/classifications/newedit"
      component={ClassificationNewEdit}
      isPrivate
    />
    <Route path="/classifications" component={Classifications} isPrivate />
    <Route path="/categories/newedit" component={CategoriesNewEdit} isPrivate />
    <Route path="/Categories" component={Categories} isPrivate />
    <Route path="/units/newedit" component={UnitsNewEdit} isPrivate />
    <Route path="/units" component={Units} isPrivate />
    <Route path="/products/newedit" component={ProductsNewEdit} isPrivate />
    <Route path="/products" component={Products} isPrivate />
    <Route
      path="/billstopays/newedit"
      component={BillsToPayNewEdit}
      isPrivate
    />
    <Route path="/billstopays" component={BillsToPay} isPrivate />
    <Route
      path="/billstoreceives/newedit"
      component={BillsToReceiveNewEdit}
      isPrivate
    />
    <Route path="/billstoreceives" component={BillsToReceive} isPrivate />
    <Route path="/sales/new" component={SalesNew} isPrivate />
    <Route path="/sales/edit" component={SalesEdit} isPrivate />
    <Route path="/sales/view" component={SaleView} isPrivate />
    <Route path="/sales/receivable" component={SalesReceivable} isPrivate />
    <Route path="/sales" component={Sales} isPrivate />
    <Route path="*">
      <NoMatch />
    </Route>
  </Switch>
);

export default Routes;
