import React from 'react';

import { Container } from './styles';

const Loading = () => {
  return (
    <Container>
      <div id="inTurnFadingTextG">
        <div id="inTurnFadingTextG_1" className="inTurnFadingTextG">
          C
        </div>
        <div id="inTurnFadingTextG_2" className="inTurnFadingTextG">
          a
        </div>
        <div id="inTurnFadingTextG_3" className="inTurnFadingTextG">
          r
        </div>
        <div id="inTurnFadingTextG_4" className="inTurnFadingTextG">
          r
        </div>
        <div id="inTurnFadingTextG_5" className="inTurnFadingTextG">
          e
        </div>
        <div id="inTurnFadingTextG_6" className="inTurnFadingTextG">
          g
        </div>
        <div id="inTurnFadingTextG_7" className="inTurnFadingTextG">
          a
        </div>
        <div id="inTurnFadingTextG_8" className="inTurnFadingTextG">
          n
        </div>
        <div id="inTurnFadingTextG_9" className="inTurnFadingTextG">
          d
        </div>
        <div id="inTurnFadingTextG_10" className="inTurnFadingTextG">
          o
        </div>
        <div id="inTurnFadingTextG_11" className="inTurnFadingTextG">
          .
        </div>
        <div id="inTurnFadingTextG_12" className="inTurnFadingTextG">
          .
        </div>
        <div id="inTurnFadingTextG_13" className="inTurnFadingTextG">
          .
        </div>
      </div>
    </Container>
  );
};

export default Loading;
