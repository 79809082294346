import useSWR from 'swr';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

export const useFetchPeople = (url) => {
  const { signOut } = useAuth();
  const { addToast } = useToast();

  const { data, error, mutate } = useSWR(
    url,
    async () => {
      const response = await api.get(url);

      const totalItens = response.headers['x-total-count'];

      const filteredPeople = response.data.filter((item) => item.pj || item.pf);

      const peoples = filteredPeople.map((p) => {
        return {
          id: p.id,
          name: p.pj ? p.pj?.fantasy_name : p.pf?.name,
          cpfcnpj: p.pj?.cnpj || p.pf?.cpf || '',
          phone: p.phone,
          active: p.active ? 'Ativo' : 'Inativo',
        };
      });

      return { data: peoples, total: totalItens };
    },
    {
      revalidateOnReconnect: true,
      onError: (err) => {
        if (err.response.status === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada. Faça seu login.',
          });
          signOut();
        }
      },
    }
  );

  return {
    dataList: data && data.data,
    totalItems: data && data.total,
    loading: !data && !error,
    error,
    mutate,
  };
};
