import styled from 'styled-components';

export const TableList = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 0px;

  border: 1px solid #d4d4d4;
`;

export const TableBody = styled.tbody``;
