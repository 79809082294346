import React from 'react';

import { Container } from './styles';

const ButtonPrimaryBlue = ({ children, icon: Icon, ...rest }) => {
  return (
    <Container>
      <button type="submit" {...rest}>
        {Icon && <Icon size={16} />}
        {children}
      </button>
    </Container>
  );
};

export default ButtonPrimaryBlue;
