import React, { useCallback, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { Form } from '@unform/web';

import { mutate as mutateGlobal } from 'swr';
import api from '../../services/api';
import { useFetchAccountPlan } from './hooks/useFetchAccountPlan';
import { useFetchSearchAccountPlan } from './hooks/useFetchSeachAccountPlan';
import { useToast } from '../../hooks/toast';

import ButtonPrimaryGree from '../../components/ButtonPrimaryGreen';
import { InputSearch } from '../../components/Form';
import NavbarMenu from '../../components/NavbarMenu';
import ContainerBox from '../../components/ContainerBox';
import ButtonGroupbox from '../../components/ContainerBox/ButtonGroupbox';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination';

import { Container, TotalItems } from './styles';

const AccountPlans = () => {
  const formRef = useRef(null);

  const [page, setPage] = useState(1);
  const [url, setUrl] = useState('/accountplans?page=1');
  const [showAccountPlain, setShowAccountPlain] = useState(true);

  const { dataList: accountPlans, loading, totalItems } = useFetchAccountPlan(
    `/accountplans?page=${page}`
  );

  const {
    dataList: searchAccountPlan,
    loading: loadingSearch,
  } = useFetchSearchAccountPlan(url);

  const { addToast } = useToast();

  const head = {
    id: 'ID',
    name: 'Plano de Conta',
    type: 'Tipo',
    dregroup: 'Grupo DRE',
  };

  const handleAccountPlanDelete = useCallback(
    async (id) => {
      try {
        if (
          window.confirm(`Tem Certeza que realmente deseja excluir o registro?`)
        ) {
          await api.delete(`/accountplans/${id}`);

          mutateGlobal(`/accountplans?page=${page}`);
          mutateGlobal(url);

          addToast({
            type: 'success',
            title: 'Cadastro Excluido com Sucesso',
          });
        } else {
          return;
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, page, url]
  );

  const handleSubmit = useCallback(() => {
    const input = formRef.current.getFieldValue('inputaccountplan');

    if (!input) {
      setUrl('/accountplans?page=1');
      setPage(1);
      setShowAccountPlain(true);

      return;
    }

    setUrl(`/accountplansearch?search=${input}`);
    setShowAccountPlain(false);
    formRef.current.reset();
  }, []);

  const handleEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Container>
      <NavbarMenu />
      <ContainerBox title="Plano Categorias de Contas">
        <ButtonGroupbox>
          <Link to="/accountplans/newedit">
            <ButtonPrimaryGree icon={FiPlus}>Adicionar</ButtonPrimaryGree>
          </Link>

          <Form ref={formRef} onSubmit={() => {}}>
            <InputSearch
              id="inputaccountplan"
              name="inputaccountplan"
              autoComplete="off"
              onKeyDown={(e) => handleEnter(e)}
              handleClick={handleSubmit}
            />
          </Form>
        </ButtonGroupbox>

        {showAccountPlain && loading && <Loading />}

        {showAccountPlain && !loading && accountPlans && (
          <>
            <Table
              head={head}
              data={accountPlans}
              handleDelete={handleAccountPlanDelete}
              pathname="/accountplans/newedit"
            />
            {totalItems && (
              <TotalItems>
                <span>
                  Total de registro(s): <b>{totalItems}</b>
                </span>
              </TotalItems>
            )}
            {totalItems > 20 && (
              <Pagination
                setPage={setPage}
                currentPage={page}
                totalItems={totalItems}
              />
            )}
          </>
        )}

        {!showAccountPlain && loadingSearch && <Loading />}

        {!showAccountPlain && !loadingSearch && searchAccountPlan && (
          <Table
            head={head}
            data={searchAccountPlan}
            handleDelete={handleAccountPlanDelete}
            pathname="/accountplans/newedit"
          />
        )}
      </ContainerBox>
    </Container>
  );
};

export default AccountPlans;
