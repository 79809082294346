import React from 'react';
import { Link } from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';

import ButtonReceived from '../../../../../components/ButtonGhostReceived';

import { TableBodyItem } from './styles';

const Row = ({
  item,
  pathname,

  handleReceived,
  handleUndoReceived,
}) => {
  const keys = Object.keys(item);

  return (
    <TableBodyItem past={item.past} received={item.received}>
      {keys.map((key) => (
        <td key={key}>
          <Link to={{ pathname, state: { item } }}>
            {' '}
            <span>{item[key]} </span>
          </Link>
        </td>
      ))}
      <td>
        <ButtonReceived
          onClick={
            item.received
              ? () => handleUndoReceived(item)
              : () => handleReceived(item)
          }
          checked={item.received}
          icon={FiCheck}
        >
          {item.received ? 'Recebido' : 'Receber'}
        </ButtonReceived>
      </td>
    </TableBodyItem>
  );
};

export default Row;
