import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import pt from 'date-fns/locale/pt-BR';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Container, Wrapper, ButtonPrevious, ButtonNext } from './styles';

const InputDatePickerMonth = ({
  id,
  label,
  handlePreviousMonth,
  handleNextMonth,
  ...rest
}) => {
  registerLocale('pt', pt);

  return (
    <Container>
      {label && (
        <label {...rest} htmlFor={id}>
          {label}
        </label>
      )}

      <Wrapper>
        <ButtonPrevious type="button" onClick={() => handlePreviousMonth()}>
          <FiChevronLeft size={20} />
        </ButtonPrevious>

        <DatePicker
          dateFormat="MMMM - yyyy"
          locale="pt"
          showMonthYearPicker
          showFullMonthYearPicker
          {...rest}
        />
        <ButtonNext type="button" onClick={() => handleNextMonth()}>
          <FiChevronRight size={20} />
        </ButtonNext>
      </Wrapper>
    </Container>
  );
};

export default InputDatePickerMonth;
