import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FiCheck, FiX } from 'react-icons/fi';
import useSWR, { mutate as mutateGlobal } from 'swr';
import * as Yup from 'yup';
import { Form } from '@unform/web';

import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import { useFetch } from '../../../hooks/useFetch';

import NavBarMenu from '../../../components/NavbarMenu';
import ContainerBox from '../../../components/ContainerBox';
import { Input, Select } from '../../../components/Form';
import ButtonSave from '../../../components/ButtonPrimaryBlue';
import ButtonCancel from '../../../components/ButtonCancel';

import { ButtonGroup, Wrapper } from './styles';

const AccountPlanNewEdit = () => {
  const { data } = useSWR('accountplans');
  const { dataList } = useFetch('/dregroups?search=%');

  const [id, setId] = useState(null);

  const location = useLocation();

  const history = useHistory();

  const formRef = useRef(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (!location.state) {
      return;
    }

    const fetchData = async () => {
      const response = await api.get(`/accountplans/${location.state.item.id}`);

      const dataAcc = {
        name: response.data.name,
        type: response.data.type,
        dregroup_id: String(response.data.dregroup_id),
      };

      formRef.current.setData(dataAcc);
    };

    fetchData();

    setId(location.state.item.id);
  }, [location.state]);

  useEffect(() => {
    const nameInput = formRef.current.getFieldRef('name');

    nameInput.focus();
  }, []);

  const handleSubmit = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome do Plano de conta é obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        if (!formRef.current.getFieldValue('type')) {
          addToast({
            type: 'error',
            description: 'Selecione um Tipo de conta.',
          });
          return;
        }

        if (!formRef.current.getFieldValue('dregroup_id')) {
          addToast({
            type: 'error',
            description: 'Selecione um Grupo DRE.',
          });
          return;
        }

        mutateGlobal(`/accountplans?page=1`, [...data, dataForm], false);

        await api.post('accountplans', dataForm);

        addToast({
          type: 'success',
          title: 'Cadastro efetuado com Sucesso',
        });

        history.push('/accountplans');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.response.status === 403) {
          addToast({
            type: 'error',
            description: 'Plano de conta já cadastrada',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, history, data]
  );

  const handleUpdate = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome Group DRE é obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        if (!formRef.current.getFieldValue('type')) {
          addToast({
            type: 'error',
            description: 'Selecione um Tipo de conta.',
          });
          return;
        }

        if (!formRef.current.getFieldValue('dregroup_id')) {
          addToast({
            type: 'error',
            description: 'Selecione um Grupo DRE.',
          });
          return;
        }

        await api.patch(`accountplans/${id}`, dataForm);

        mutateGlobal(`accountplans`);

        addToast({
          title: 'Cadastro alterado com Sucesso',
        });

        history.push('/accountplans');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.response.status === 403) {
          addToast({
            type: 'error',
            description: 'Plano de Conta já cadastrado',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, history, id]
  );
  return (
    <>
      <NavBarMenu />

      <ContainerBox
        title={
          location.state
            ? 'Alterar Plano Categorias de Conta'
            : 'Cadastro Plano Categorias de Conta'
        }
        small
      >
        <Form
          initialData={location.state ? location.state.item : {}}
          ref={formRef}
          onSubmit={location.state ? handleUpdate : handleSubmit}
        >
          <Wrapper>
            <Input
              id="name"
              name="name"
              label="Plano de Conta*"
              autoComplete="off"
            />
          </Wrapper>
          <Wrapper style={{ marginTop: 0 }}>
            <Select label="Tipo*" name="type" id="type">
              <option value="">Selecione o Tipo da conta</option>
              <option value="r">Receita</option>
              <option value="d">Despesa</option>
            </Select>
          </Wrapper>
          <Wrapper style={{ marginTop: 26 }}>
            <Select label="Grupo DRE*" name="dregroup_id" id="dregroup_id">
              <option value="">Selecione o Grupo DRE</option>
              {dataList &&
                dataList.map((dre) => (
                  <option key={dre.id} value={dre.id}>
                    {dre.name}
                  </option>
                ))}
            </Select>
          </Wrapper>
          <ButtonGroup>
            <ButtonSave icon={FiCheck}>Salvar</ButtonSave>
            <Link to="/accountplans">
              <ButtonCancel icon={FiX}>Cancelar</ButtonCancel>
            </Link>
          </ButtonGroup>
        </Form>
      </ContainerBox>
    </>
  );
};

export default AccountPlanNewEdit;
