import useSWR from 'swr';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

import {
  formatedInputBRL,
  formatedDateOnlyAdd6Hours,
} from '../../../utils/formatedInputs';

export const useFetchSaleView = (url) => {
  const { signOut } = useAuth();
  const { addToast } = useToast();

  const { data, error, mutate } = useSWR(
    url,
    async () => {
      const response = await api.get(url);

      const {
        id,
        date_sale,
        situation,
        account_plan_id,
        payment_term,
        total,
        total_liquid,
        discount,
        peoples,
        comments,
      } = response.data;

      const sale = {
        id,
        client: peoples.pj?.fantasy_name || peoples.pf?.name,
        people_id: peoples.id,
        account_plan_id,
        date_sale: formatedDateOnlyAdd6Hours(date_sale),
        situation,
        payment_term,
        total: formatedInputBRL(total),
        total_liquid: formatedInputBRL(total_liquid),
        discount: discount ? formatedInputBRL(discount) : null,
        comments,
      };

      const { items, billsToReceives } = response.data;

      const itemsSale = items.map((i) => {
        return {
          product_id: `${i.products?.id}`,
          product: `${i.products?.description} | ${
            i.products.classification?.description
          } | ${i.products?.color === 'v' ? 'VERMELHO' : 'BRANCO'} `,
          quantity: i.quantity,
          price: formatedInputBRL(i.price),
          sub_total: formatedInputBRL(i.sub_total),
          id: i.id,
        };
      });

      const billsReceives = billsToReceives.map((bills) => {
        return {
          parcel: bills.parcel,
          maturity_date: formatedDateOnlyAdd6Hours(bills.maturity_date),
          amount: formatedInputBRL(bills.amount),
          received_amount: bills.received_amount
            ? formatedInputBRL(bills.received_amount)
            : '',
          received_date: bills.received_date
            ? formatedDateOnlyAdd6Hours(bills.received_date)
            : '',
          comments: bills.comments,
          id: bills.id,
          past: bills.past,
          received: bills.received,
        };
      });

      let verifyReceived = false;

      // eslint-disable-next-line no-restricted-syntax
      for (const item of billsToReceives) {
        if (item.received === true) {
          verifyReceived = true;
        }
      }

      const billsReceivedTotal = billsToReceives
        .filter((r) => r.received)
        .reduce((totalOk, item) => totalOk + +item.received_amount, 0);

      const billsReceivesPastTotal = billsToReceives
        .filter((r) => r.past && !r.received)
        .reduce((totalPast, item) => totalPast + +item.amount, 0);

      const billsReceivesToReceivesTotal = billsToReceives
        .filter((r) => !r.past && !r.received)
        .reduce((totalReceived, item) => totalReceived + +item.amount, 0);

      const dataSale = {
        sale,
        itemsSale,
        billsReceives,
        billsReceivedTotal: formatedInputBRL(billsReceivedTotal),
        billsReceivesPastTotal: formatedInputBRL(billsReceivesPastTotal),
        billsReceivesToReceivesTotal: formatedInputBRL(
          billsReceivesToReceivesTotal
        ),
        received: verifyReceived,
      };
      return { data: dataSale };
    },
    {
      revalidateOnReconnect: true,

      onError: (err) => {
        if (err.response.status === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada. Faça seu login.',
          });
          signOut();
        }
      },
    }
  );

  return {
    dataList: data && data.data,
    loading: !data && !error,
    error,
    mutate,
  };
};
