import styled from 'styled-components';

export const Content = styled.div`
  margin: 40px 0;
  max-width: 500px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 40px;

  button {
    margin-right: 8px;
  }
`;
export const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  padding: 16px;
  border-radius: 4px;
  background: #ededf0;

  p {
    font-weight: bold;
  }
`;

export const Wrapper = styled.div`
  margin-top: 40px;
`;

export const GroupFields = styled.div`
  .noactive {
    opacity: 0.6;
    pointer-events: none;
  }

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  margin-top: 16px;

  :first-child {
    margin-top: 40px;
  }

  .none {
    display: none;
  }
`;
