import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import { Container, Content } from './styles';

import { Input } from '../../../components/Form';
import Button from '../../../components/Button';

import logo from '../../../assets/logo.svg';

const Login = () => {
  const formRef = useRef(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail inválido')
            .required('Nome de Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        await signIn({
          email: data.email,
          password: data.password,
        });

        history.push('/');

        addToast({
          type: 'success',
          title: 'Login efetuado com Sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.message === 'Network Error') {
          addToast({
            type: 'error',
            title: 'Erro na Rede',
            description:
              'Sem Comunicação com o Servidor. Verifique com o suporte.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Credenciais inválidas, verifique Usuário e senha.',
        });
      }
    },
    [signIn, addToast, history]
  );

  return (
    <Container>
      <Content>
        <img src={logo} alt="Zamp Tecnologia" />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Faça seu Login</h1>

          <Input id="email" name="email" placeholder="E-mail" label="E-mail" />

          <Input
            type="password"
            id="password"
            name="password"
            placeholder="Senha"
            label="Senha"
          />

          <Button type="submit">Entrar</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Login;
