/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FiCheck, FiX } from 'react-icons/fi';
import useSWR, { mutate as mutateGlobal } from 'swr';
import * as Yup from 'yup';
import { Form } from '@unform/web';

import Switch from 'react-switch';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import { useFetch } from '../../../hooks/useFetch';

import Loading from '../../../components/Loading';
import NavBarMenu from '../../../components/NavbarMenu';
import ContainerBox from '../../../components/ContainerBox';
import {
  Input,
  InputBRL,
  InputReadOnly,
  Select,
  TextArea,
} from '../../../components/Form';
import ButtonSave from '../../../components/ButtonPrimaryBlue';
import ButtonCancel from '../../../components/ButtonCancel';

import {
  ButtonGroup,
  Wrapper,
  SwitchGroup,
  WrapperDescription,
  GroupFields,
} from './styles';

const AccountPlanNewEdit = () => {
  const [id, setId] = useState(null);

  const location = useLocation();
  const history = useHistory();
  const formRef = useRef(null);
  const { addToast } = useToast();

  const [checkStatus, setCheckStatus] = useState(true);
  const [isLoading, setIsloading] = useState(!!location.state);

  const { data } = useSWR('products');

  const { dataList: categories } = useFetch('/categories/p');

  const { dataList: classifications } = useFetch(
    '/classificationsearch?search=%'
  );

  const { dataList: units } = useFetch('/unitysearch?search=%');

  useEffect(() => {
    if (!location.state) {
      return;
    }

    const fetchData = async () => {
      const response = await api.get(`/products/${location.state.item.id}`);
      setIsloading(false);

      const dataProduct = {
        description: response.data.description,
        category_id: String(response.data.category_id),
        unity_id: String(response.data.unity_id),
        classification_id: response.data.classification_id
          ? response.data.classification_id
          : '',
        color: response.data.color,
        code: response.data.code,
        bar_code: response.data.bar_code,
        stock: response.data.stock ? response.data.stock : '',
        minimum_stock: response.data.minimum_stock
          ? response.data.minimum_stock
          : '',
        sale_price: response.data.sale_price
          ? String(response.data.sale_price).replace('.', ',')
          : '',
        dozens: response.data.dozens ? String(response.data.dozens) : '',
        comments: response.data.comments,
      };

      setCheckStatus(response.data.active);

      formRef.current.setData(dataProduct);
    };

    fetchData();

    setId(location.state.item.id);
  }, [location.state]);

  useEffect(() => {
    if (!formRef.current) return;

    const nameInput = formRef.current.getFieldRef('description');

    nameInput.focus();
  }, []);

  const handleSubmit = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          description: Yup.string().required(
            'Descrição do Produto é obrigatório'
          ),
          category_id: Yup.string().required('Selecione uma categoria'),
          unity_id: Yup.string().required('Selecione uma unidade'),
          classification_id: Yup.string().required(
            'Selecione Tipo - Classificação'
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        let salePriceString = formRef.current.getFieldValue('sale_price');

        salePriceString = salePriceString.replace('R$ ', '');
        salePriceString = salePriceString.replace(/\./g, '');
        salePriceString = salePriceString.replace(',', '.');
        const salePriceDecimal = parseFloat(salePriceString).toFixed(2);

        const dataProduct = {
          description: dataForm.description,
          category_id: parseInt(dataForm.category_id, 10),
          unity_id: parseInt(dataForm.unity_id, 10),
          classification_id: dataForm.classification_id
            ? parseInt(dataForm.classification_id, 10)
            : null,
          color: dataForm.color,
          code: dataForm.code,
          bar_code: dataForm.bar_code,
          stock: dataForm.stock ? parseInt(dataForm.stock, 10) : null,
          minimum_stock: dataForm.minimum_stock
            ? parseInt(dataForm.minimum_stock, 10)
            : null,
          sale_price: dataForm.sale_price ? salePriceDecimal : null,
          dozens: dataForm.dozens ? parseFloat(dataForm.dozens) : null,
          comments: dataForm.comments,
          active: checkStatus,
        };

        mutateGlobal(`/products?page=1`, [...data, dataForm], false);

        await api.post('products', dataProduct);

        addToast({
          type: 'success',
          title: 'Cadastro efetuado com Sucesso',
        });

        history.push('/products');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.response.status === 403) {
          addToast({
            type: 'error',
            description: 'Descrição já cadastrada',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, history, data, checkStatus]
  );

  const handleUpdate = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          description: Yup.string().required(
            'Descrição do Produto é obrigatório'
          ),
          category_id: Yup.string().required('Selecione uma categoria'),
          unity_id: Yup.string().required('Selecione uma unidade'),
          classification_id: Yup.string().required(
            'Selecione Tipo - Classificação'
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        let salePriceString = formRef.current.getFieldValue('sale_price');

        salePriceString = salePriceString.replace('R$ ', '');
        salePriceString = salePriceString.replace(/\./g, '');
        salePriceString = salePriceString.replace(',', '.');
        const salePriceDecimal = parseFloat(salePriceString).toFixed(2);

        const dataProduct = {
          description: dataForm.description,
          category_id: parseInt(dataForm.category_id, 10),
          unity_id: parseInt(dataForm.unity_id, 10),
          classification_id: dataForm.classification_id
            ? parseInt(dataForm.classification_id, 10)
            : null,
          color: dataForm.color,
          code: dataForm.code,
          bar_code: dataForm.bar_code,
          stock: dataForm.stock ? parseInt(dataForm.stock, 10) : null,
          minimum_stock: dataForm.minimum_stock
            ? parseInt(dataForm.minimum_stock, 10)
            : null,
          sale_price: dataForm.sale_price ? salePriceDecimal : null,
          dozens: dataForm.dozens ? parseFloat(dataForm.dozens) : null,
          comments: dataForm.comments,
          active: checkStatus,
        };

        await api.patch(`products/${id}`, dataProduct);

        mutateGlobal(`products`);

        addToast({
          title: 'Cadastro alterado com Sucesso',
        });

        history.push('/products');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.response.status === 403) {
          addToast({
            type: 'error',
            description: 'Descrição já cadastrada',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, history, id, checkStatus]
  );
  return (
    <>
      <NavBarMenu />
      {isLoading && <Loading />}

      {!isLoading && (
        <ContainerBox
          title={location.state ? 'Alterar Produto' : 'Cadastro Produtos'}
        >
          <Form
            ref={formRef}
            onSubmit={location.state ? handleUpdate : handleSubmit}
          >
            <WrapperDescription>
              <Input
                id="description"
                name="description"
                label="Descrição do Produto*"
                autoComplete="off"
              />
              <SwitchGroup checkStatus={checkStatus}>
                <label htmlFor="active">Status Produto</label>
                <Switch
                  name="active"
                  id="active"
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={28}
                  uncheckedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={96}
                  onChange={() => setCheckStatus(!checkStatus)}
                  checked={checkStatus}
                />
                <span>{checkStatus ? 'Ativo' : 'Inativo'}</span>
              </SwitchGroup>
            </WrapperDescription>

            <GroupFields style={{ marginTop: 10 }}>
              <Select label="Categoria*" name="category_id" id="category_id">
                <option value="">Selecione a Categoria</option>
                {categories &&
                  categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.description}
                    </option>
                  ))}
              </Select>

              <Select label="Unidade*" name="unity_id" id="unity_id">
                <option value="">Selecione a Unidade</option>
                {units &&
                  units.map((un) => (
                    <option key={un.id} value={un.id}>
                      {un.description}
                    </option>
                  ))}
              </Select>

              <Select
                label="Tipo - Classificação*"
                name="classification_id"
                id="classification_id"
              >
                <option value="">Selecione Tipo - Classificação</option>
                {classifications &&
                  classifications.map((clas) => (
                    <option key={clas.id} value={clas.id}>
                      {clas.description}
                    </option>
                  ))}
              </Select>

              <Select label="Cor" name="color" id="color">
                <option value="">Selecione a Cor</option>
                <option value="b">Branco</option>
                <option value="v">Vermelho</option>
              </Select>
            </GroupFields>

            <GroupFields>
              <Input
                id="stock"
                name="stock"
                label="Estoque"
                autoComplete="off"
              />
              <Input
                id="minimum_stock"
                name="minimum_stock"
                label="Estoque mínimo"
                autoComplete="off"
              />
              <InputBRL
                id="sale_price"
                name="sale_price"
                label="Preço de Venda"
                autoComplete="off"
              />
              <InputReadOnly
                id="cost_price"
                name="cost_price"
                label="Custo médio"
                autoComplete="off"
              />
            </GroupFields>
            <GroupFields>
              <Select label="Quantidade em Dúzias " name="dozens" id="dozens">
                <option value="">Selecione a Qtde em Dúzias</option>
                <option value="30">30 Dúzias</option>
                <option value="25">25 Dúzias</option>
                <option value="20">20 Dúzias</option>
                <option value="2.5">2,5 Dúzias</option>
                <option value="1">1 Dúzia</option>
              </Select>
              <Input
                id="code"
                name="code"
                label="Código interno"
                autoComplete="off"
              />
              <Input
                id="bar_code"
                name="bar_code"
                label="Código Barras"
                autoComplete="off"
              />
            </GroupFields>
            <Wrapper>
              <TextArea name="comments" id="comments" label="Observações" />
            </Wrapper>

            <ButtonGroup>
              <ButtonSave icon={FiCheck}>Salvar</ButtonSave>
              <Link to="/products">
                <ButtonCancel icon={FiX}>Cancelar</ButtonCancel>
              </Link>
            </ButtonGroup>
          </Form>
        </ContainerBox>
      )}
    </>
  );
};

export default AccountPlanNewEdit;
