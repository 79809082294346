import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  background: #f0f0f5;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Content = styled.div`
  background: #fff;
  width: 100%;
  max-width: 400px;
  padding: 48px 32px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px #d7d8da;

  animation: ${appearFromLeft} 1s;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 170px;
  }

  form {
    width: 100%;

    button {
      margin-top: 16px;
    }

    h1 {
      text-align: center;
      font-size: 24px;
      color: #191654;
      margin: 40px 0;
    }
  }
`;
