import React from 'react';
import { FiPlus } from 'react-icons/fi';

import { Button } from './styles';

const ButtonAddFast = ({ ...rest }) => {
  return (
    <Button type="button" {...rest}>
      <FiPlus size={16} />
    </Button>
  );
};

export default ButtonAddFast;
