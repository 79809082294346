import styled from 'styled-components';

export const TableHeader = styled.thead`
  border-radius: 4px;

  th {
    font-weight: bold;
    text-align: left;
    padding: 14px 10px;
    border-bottom: 1px dotted #c9d3dd;
    background: #ededf0;

    :nth-child(1) {
      width: 15%;
      text-align: center;
      border-left: 1px dotted #c9d3dd;
    }
    :nth-child(2) {
      width: 5%;
      text-align: center;
    }

    :nth-child(3) {
      width: 35%;
      text-align: left;
    }
    :nth-child(4) {
      width: 5%;
      text-align: left;
    }
    :nth-child(5) {
      width: 15%;
      text-align: right;
    }
    :nth-child(6) {
      width: 15%;
      text-align: right;
      padding-right: 20px;
    }
    :nth-child(7) {
      width: 0%;
      display: none;
    }
    :nth-child(8) {
      width: 0%;
      display: none;
    }
    :nth-child(9) {
      width: 0%;
      display: none;
    }
    :nth-child(10) {
      width: 10px;
      border-right: 1px dotted #c9d3dd;
    }
  }
`;
