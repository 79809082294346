import React from 'react';

import { TableBodyItem } from './styles';

const Row = ({ item }) => {
  const keys = Object.keys(item);

  return (
    <TableBodyItem key={item.product_id}>
      {keys.map((key) => (
        <td key={key}>
          <span>{item[key]} </span>
        </td>
      ))}
    </TableBodyItem>
  );
};

export default Row;
