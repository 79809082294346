import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

const Select = ({ id, name, label, children, bg, ...rest }) => {
  const selectRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!selectRef.current.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      bg={bg}
    >
      <label htmlFor={id}>
        {label}
        <select
          name={name}
          id={id}
          defaultValue={defaultValue}
          ref={selectRef}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...rest}
        >
          {children}
        </select>
      </label>

      <small>{error}</small>
    </Container>
  );
};

export default Select;
