import useSWR from 'swr';

import { useToast } from './toast';
import { useAuth } from './auth';
import api from '../services/api';

export const useFetch = (url) => {
  const { signOut } = useAuth();
  const { addToast } = useToast();

  const { data, error, mutate } = useSWR(
    url,
    async () => {
      const response = await api.get(url);

      const totalItens = response.headers['x-total-count'];

      return { data: response.data, total: totalItens };
    },
    {
      revalidateOnReconnect: true,
      onError: (err) => {
        if (err.response.status === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada. Faça seu login.',
          });
          signOut();
        }
      },
    }
  );

  return {
    dataList: data && data.data,
    totalItems: data && data.total,
    loading: !data && !error,
    error,
    mutate,
  };
};
