import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-text-mask';

import { Container } from './styles';

const InputCNPJ = ({
  id,
  name,
  placeholder,
  label,
  handleOnClick,
  loading,
  ...rest
}) => {
  const mask = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ];
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current.inputElement.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current.inputElement,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      <label htmlFor={id}>{label}</label>
      <div>
        <InputMask
          guide
          mask={mask}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          name={name}
          id={id}
          placeholder={placeholder}
          type="text"
          {...rest}
        />
        <button
          onClick={handleOnClick}
          type="button"
          title="Click para buscar dados pelo CNPJ"
        >
          {loading ? 'Carregando...' : 'Buscar dados'}
        </button>
      </div>

      <small>{error}</small>
    </Container>
  );
};

export default InputCNPJ;
