import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

const InputReadOnly = ({ id, name, placeholder, label, ...rest }) => {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error}>
      <label htmlFor={id}>{label}</label>
      <input
        defaultValue={defaultValue}
        ref={inputRef}
        name={name}
        id={id}
        placeholder={placeholder}
        {...rest}
        readOnly
      />

      <small>{error}</small>
    </Container>
  );
};

export default InputReadOnly;
