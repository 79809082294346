import styled from 'styled-components';

export const Content = styled.div`
  margin: 40px 0;
  max-width: 500px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 40px;

  button {
    margin-right: 8px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 40px;
`;

export const GroupFields = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin-top: 16px;

  :first-child {
    margin-top: 40px;
  }

  .none {
    display: none;
  }
`;
