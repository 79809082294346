import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateY(-32px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Header = styled.header`
  h1 {
    margin-top: 16px;
    margin-bottom: 40px;
    font-size: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e5eaed;
    font-weight: bold;
  }
`;

export const Content = styled.div`
  width: 600px;
  padding: 32px;
  background: #fff;
  border-radius: 4px;

  animation: ${appearFromLeft} 0.7s;

  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.4);
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 18px;

  button {
    margin-right: 8px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 40px;
`;
