import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from '../pages/_layouts/Login';
import DefaultLayout from '../pages/_layouts/default';

import { useAuth } from '../hooks/auth';

const RouteWrapper = ({ component: Component, isPrivate = false, ...rest }) => {
  const { user } = useAuth();

  if (!user && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (user && !isPrivate) {
    return <Redirect to="/" />;
  }

  const Layout = user ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteWrapper;
