import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const TableBodyItem = styled.tr`
  transition: all 0.2s ease;

  td {
    border-bottom: 1px dotted #d4d4d4;
    text-align: left;
    a {
      display: flex;
      justify-content: flex-start;
      padding: 18px 10px;
    }

    :nth-child(1) {
      max-width: 30px;
      a {
        justify-content: center;
      }
    }

    :nth-child(2) {
      max-width: 100px;
    }

    :nth-child(3) {
      max-width: 100px;
      a {
        padding: 4px 8px;
        border-radius: 4px;
        background: #f2f2f2;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    :nth-child(4) {
      max-width: 250px;
      a {
        padding-left: 16px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      ${(props) =>
        (props.opened || props.past) &&
        !props.received &&
        css`
          a {
            font-weight: bold;
          }
        `}
    }
    :nth-child(5) {
      max-width: 200px;
      a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    :nth-child(6) {
      width: 150px;
      a {
        justify-content: flex-end;
      }

      ${(props) =>
        (props.received || props.receivedPartial) &&
        css`
          a {
            color: #2f54eb;
          }
        `}
    }

    :nth-child(7) {
      width: 100px;
      padding: 0 6px;
      a {
        justify-content: center;
        background: #f0f5ff;
        color: #2f54eb;

        font-size: 12px;
        font-weight: bold;
        padding: 4px;
        border-radius: 4px;
        margin: 0 5px;
      }
      ${(props) =>
        props.past &&
        css`
          a {
            background: #fff2f0;
            color: ${lighten(0.1, '#e02041')};

            font-size: 12px;
            font-weight: bold;
            padding: 4px;
            border-radius: 4px;
            margin: 0 5px;
          }
        `}
      ${(props) =>
        props.received &&
        css`
          a {
            background: #f2ffed;
            color: #52c41a;

            font-size: 12px;
            font-weight: bold;
            padding: 4px;
            border-radius: 4px;
            margin: 0 5px;
          }
        `}
      ${(props) =>
        props.receivedPartial &&
        css`
          a {
            background: #f6ffed;
            color: #52c41a;

            font-size: 12px;
            font-weight: bold;
            padding: 4px 2px;
            border-radius: 4px;
            margin: 0 4px;
          }
        `}
    }

    :nth-child(8) {
      display: none;
    }

    :nth-child(9) {
      border-left: dotted 1px #d1d5da;
      width: 100px;
      justify-content: center;
    }
  }

  :hover {
    background: #f5f5f9;
  }
`;

export const WrapperActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Actions = styled.button`
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0px 3px;

  svg {
    margin: 0;
  }
`;
