import React from 'react';

import { TableHeader } from './styles';

const Head = ({ keys, head }) => {
  const tableHead = head || {};
  return (
    <TableHeader>
      <tr>
        {keys.map((key) => (
          <th key={key}>{tableHead[key] || key}</th>
        ))}
        <th> </th>
      </tr>
    </TableHeader>
  );
};

export default Head;
