import React from 'react';
import { Link } from 'react-router-dom';
import { FiTrash2, FiDollarSign, FiRotateCcw } from 'react-icons/fi';

import { TableBodyItem, WrapperActions, Actions } from './styles';

const Row = ({
  item,
  handleDelete,
  handleLaunchCredit,
  handleReverseLaunchCredit,
  pathname,
}) => {
  const keys = Object.keys(item);

  return (
    <TableBodyItem
      key={item.id}
      past={item.past}
      received={item.received === 'Recebido'}
      receivedPartial={item.received === 'Recebido*'}
      opened={item.received === 'Em Aberto'}
    >
      {keys.map((key) => (
        <td key={key} title={item[key].toString()}>
          <Link to={{ pathname, state: { item } }}>{item[key]}</Link>
        </td>
      ))}
      <td>
        <WrapperActions>
          {item.received === 'Recebido' || item.received === 'Recebido*' ? (
            <Actions
              title="Definir como não Recebido"
              type="button"
              onClick={() => handleReverseLaunchCredit(item)}
            >
              <FiRotateCcw size={16} color="#616161" />
            </Actions>
          ) : (
            <>
              <Actions
                title="Definir como Recebido"
                type="button"
                onClick={() => handleLaunchCredit(item.id)}
              >
                <FiDollarSign size={18} color="#52c41a" />
              </Actions>
              <Actions
                title="Excluir item"
                type="button"
                onClick={() => handleDelete(item.id)}
              >
                <FiTrash2 size={17} color="#616161" />
              </Actions>
            </>
          )}
        </WrapperActions>
      </td>
    </TableBodyItem>
  );
};

export default Row;
