import React, { useCallback, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { Form } from '@unform/web';
import { format, addMonths, subMonths } from 'date-fns';

import { mutate as mutateGlobal } from 'swr';
import api from '../../services/api';
import { useFetchBillsToReceives } from './hooks/useFetchBillsToReceives';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import ButtonPrimaryGree from '../../components/ButtonPrimaryGreen';
import { InputSearch, InputDatePickerMonth } from '../../components/Form';
import NavbarMenu from '../../components/NavbarMenu';
import ContainerBox from '../../components/ContainerBox';
import ButtonGroupbox from '../../components/ContainerBox/ButtonGroupbox';
import Table from './Table';
import Loading from '../../components/Loading';

import { Container } from './styles';

const BillsToReceive = () => {
  const { user } = useAuth();

  const formRef = useRef(null);

  const [url, setUrl] = useState('/billstoreceivesearch?search=%');
  const [showBillsToReceives, setShowBillsToReceives] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const urlMonth = useMemo(() => format(currentMonth, 'yyyy-MM-dd'), [
    currentMonth,
  ]);

  const { dataList: billsToReceives, loading } = useFetchBillsToReceives(
    `/billstoreceives?month=${urlMonth}`
  );

  const {
    dataList: searchBillsToReceives,
    loading: loadingSearch,
  } = useFetchBillsToReceives(url);

  const { addToast } = useToast();

  const head = {
    id: 'ID',
    maturity_date: 'Vencimento',
    account_plan: 'Plano Contas',
    description: 'Descrição',
    client: 'Cliente',
    amount: 'Valor',
    received: 'Situação',
    past: ' ',
  };

  const handlePreviousMonth = useCallback(() => {
    setCurrentMonth(subMonths(currentMonth, 1));
  }, [currentMonth]);

  const handleNextMonth = useCallback(() => {
    setCurrentMonth(addMonths(currentMonth, 1));
  }, [currentMonth]);

  const handleSubmit = useCallback(() => {
    const input = formRef.current.getFieldValue('inputbills');

    if (!input) {
      setUrl('/billstoreceivesearch?search=%');
      setShowBillsToReceives(true);
      return;
    }

    setUrl(`/billstoreceivesearch?search=${input}`);
    setShowBillsToReceives(false);
    formRef.current.reset();
  }, []);

  const handleDeleteBillsToReceive = useCallback(
    async (id) => {
      try {
        if (
          window.confirm(`Tem Certeza que realmente deseja excluir o registro?`)
        ) {
          await api.delete(`/billstoreceives/${id}`);

          mutateGlobal(`/billstoreceives?month=${urlMonth}`);
          mutateGlobal(url);

          addToast({
            type: 'success',
            title: 'Cadastro Excluido com Sucesso',
          });
        } else {
          return;
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, url, urlMonth]
  );

  const handleLaunchCredit = useCallback(
    async (id) => {
      try {
        const billToReceiveLaunched = {
          bills_to_receive_id: id,
          user_id: user.id,
        };
        await api.post(`/launchcreditaccount`, billToReceiveLaunched);

        mutateGlobal(`/billstoreceives?month=${urlMonth}`);

        addToast({
          type: 'success',
          title: 'Recebimento confirmado com Sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao confirmar recebimento!',
        });
      }
    },
    [addToast, user, urlMonth]
  );

  const handleReverseLaunchCredit = useCallback(
    async (item) => {
      try {
        if (item.received === 'Recebido*') {
          if (
            window.confirm(
              `ATENÇÃO! Recebimento parcial. Se confirmar o estorno deve excluir o lançamento gerado do restante contas a receber.`
            )
          ) {
            const billToReceiveReverseLaunched = {
              bills_to_receive_id: item.id,
              user_id: user.id,
            };
            await api.post(
              `/reverselaunchcreditaccount`,
              billToReceiveReverseLaunched
            );

            mutateGlobal(`/billstoreceives?month=${urlMonth}`);

            addToast({
              type: 'success',
              title: 'Estorno de recebimento confirmado com Sucesso',
            });
            return;
          }
        }

        if (item.received === 'Recebido') {
          const billToReceiveReverseLaunched = {
            bills_to_receive_id: item.id,
            user_id: user.id,
          };
          await api.post(
            `/reverselaunchcreditaccount`,
            billToReceiveReverseLaunched
          );

          mutateGlobal(`/billstoreceives?month=${urlMonth}`);

          addToast({
            type: 'success',
            title: 'Estorno de recebimento confirmado com Sucesso',
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao confirmar estorno de recebimento!',
        });
      }
    },
    [addToast, user, urlMonth]
  );

  const handleEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Container>
      <NavbarMenu />
      <ContainerBox title="Contas a Receber">
        <ButtonGroupbox>
          <Link to="/billstoreceives/newedit">
            <ButtonPrimaryGree icon={FiPlus}>
              Novo Recebimento
            </ButtonPrimaryGree>
          </Link>

          <InputDatePickerMonth
            selected={currentMonth}
            onChange={(date) => setCurrentMonth(date)}
            handlePreviousMonth={handlePreviousMonth}
            handleNextMonth={handleNextMonth}
          />

          <Form ref={formRef} onSubmit={() => {}}>
            <InputSearch
              id="inputbills"
              name="inputbills"
              autoComplete="off"
              onKeyDown={(e) => handleEnter(e)}
              handleClick={handleSubmit}
            />
          </Form>
        </ButtonGroupbox>

        {showBillsToReceives && loading && <Loading />}

        {showBillsToReceives && !loading && billsToReceives && (
          <>
            <Table
              head={head}
              data={billsToReceives}
              handleLaunchCredit={handleLaunchCredit}
              handleReverseLaunchCredit={handleReverseLaunchCredit}
              handleDelete={handleDeleteBillsToReceive}
              pathname="/billstoreceives/newedit"
            />
          </>
        )}

        {!showBillsToReceives && loadingSearch && <Loading />}

        {!showBillsToReceives && !loadingSearch && searchBillsToReceives && (
          <Table
            head={head}
            data={searchBillsToReceives}
            handleLaunchCredit={handleLaunchCredit}
            handleDelete={handleDeleteBillsToReceive}
            pathname="/billstoreceives/newedit"
          />
        )}
      </ContainerBox>
    </Container>
  );
};

export default BillsToReceive;
