import styled from 'styled-components';

export const Container = styled.div`
  button {
    min-width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;

    border: 1px solid rgba(0, 0, 0, 0.2);
    background: linear-gradient(0deg, #eeeff2, #fafafb);
    color: #777;

    border-radius: 4px;
    height: 38px;
    font-size: 14px;
    font-weight: bold;
    transition: background 0.2s;

    :focus {
      border-color: #2188ff;
      box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
        0 0 0 0.2em rgba(3, 102, 214, 0.3);
      transition: all 0.2s ease-in-out;
    }

    svg {
      margin-right: 8px;
    }

    &:hover {
      background: #eeeff2;
    }

    &:active {
      background: linear-gradient(180deg, #eeeff2, #fafafb);
    }
  }
`;
