import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;

  h1 {
    border-bottom: 2px solid #e5eaed;
    padding-bottom: 12px;
  }
`;

export const SectionCards = styled.div`
  display: flex;
  margin-top: 40px;
  a + a {
    margin-left: 32px;
  }
`;

export const WrapperCards1 = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 280px;
  border-left: 6px solid #4ab858;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.3);
`;
export const WrapperCards2 = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 280px;
  border-left: 6px solid #f43e61;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

  & + div {
    margin-left: 32px;
  }
`;

export const WrapperCards3 = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 280px;
  border-left: 6px solid #3ca2d3;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

  & + div {
    margin-left: 32px;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  padding: 16px 16px 16px 0;
  h3 {
    font-size: 16px;
  }
  span {
    font-family: 'Open Sans Condensed', sans-serif;
    margin: 16px 0;
    font-size: 24px;
    font-weight: bold;
  }

  p {
    font-size: 12px;
  }
`;
