import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { SWRConfig } from 'swr';
import api from './services/api';
import Routes from './routes';
import GlobalStyle from './styles/global';

import AppProvider from './hooks';

function App() {
  return (
    <Router>
      <SWRConfig value={{ fetcher: (url) => api(url).then((r) => r.data) }}>
        <AppProvider>
          <Routes />
        </AppProvider>
      </SWRConfig>

      <GlobalStyle />
    </Router>
  );
}

export default App;
