import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  input {
    min-width: 300px;
    height: 40px;
    padding: 6px 58px 6px 8px;
    background: #fff;
    color: #5d5d5d;
    border-radius: 4px;
    border: 2px solid #d1d5da;

    transition: all 0.2s ease-in-out;

    ::placeholder {
      color: #9f9f9f;
    }

    ${(props) =>
      props.isFocused &&
      css`
        border-color: #2188ff;
        box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
          0 0 0 0.2em rgba(3, 102, 214, 0.3);
        transition: all 0.2s ease-in-out;
      `}
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    margin-left: -50px;

    background: linear-gradient(0deg, #358ccf, #3ca2d3);
    border-color: #318ace;
    border: 0;

    border-radius: 0px 4px 4px 0;
    height: 40px;
    color: #fff;
    font-size: 16px;

    transition: background 0.2s;

    :hover {
      filter: brightness(90%);
    }

    :active {
      filter: brightness(80%);
    }
  }
`;
