import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FiCheck, FiX, FiInfo } from 'react-icons/fi';
import useSWR, { mutate as mutateGlobal } from 'swr';
import * as Yup from 'yup';
import { Form } from '@unform/web';

import { format } from 'date-fns';

import getValidationErrors from '../../../utils/getValidationErrors';
import validatorBiggerZero from '../../../utils/validatorBiggerZero';
import { parseStrToFloatInputBRL } from '../../../utils/parseStrToFloatInputBRL';
import {
  formatedInputBRL,
  formatedInputDatePicker,
} from '../../../utils/formatedInputs';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import { useFetch } from '../../../hooks/useFetch';
import { useAuth } from '../../../hooks/auth';

import NavBarMenu from '../../../components/NavbarMenu';
import ContainerBox from '../../../components/ContainerBox';
import {
  Input,
  Select,
  InputDatePicker,
  CheckBox,
  InputBRL,
  TextArea,
} from '../../../components/Form';
import ButtonSave from '../../../components/ButtonPrimaryBlue';
import ButtonCancel from '../../../components/ButtonCancel';
import Loading from '../../../components/Loading';

import {
  ButtonGroup,
  GroupFields,
  GroupFieldsCol1,
  GroupFieldsCol4,
  ContentForm,
  Col1,
  Col2,
  Hr,
  Info,
} from './styles';

const BillsToReceiveNewEdit = () => {
  const { user } = useAuth();

  const { data } = useSWR(
    `billstoreceives?month=${format(new Date(), 'yyyy-MM-dd')}`
  );
  const { dataList: accountPlans } = useFetch('/accountplansearch?search=%');
  const { dataList: accounts } = useFetch('/accountsearch?search=%');
  const { dataList: providers } = useFetch('/peoplesearch?search=%');
  const { dataList: coastcenters } = useFetch('/coastcentersearch?search=%');

  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [received, setReceived] = useState(false);
  const [receivedPartial, setReceivedPartial] = useState(false);
  const [toggleReceived, setToggleReceived] = useState(false);
  const [saleId, setSaleId] = useState('');

  const [confereceAmountReceived, setConferenceAmountReceived] = useState(0);
  const [restAmountReceived, setRestAmountReceived] = useState(0);

  const location = useLocation();

  const history = useHistory();

  const formRef = useRef(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (!location.state) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      const response = await api.get(
        `/billstoreceives/${location.state.item.id}`
      );

      const dataBills = response.data;
      setSaleId(dataBills.sale_id);

      const dataBillsToReceives = {
        description: dataBills.description,
        account_plan_id: String(dataBills.account_plan_id),
        account_id: String(dataBills.account_id),
        competency_date: formatedInputDatePicker(dataBills.competency_date),

        maturity_date: formatedInputDatePicker(dataBills.maturity_date),
        amount: dataBills.amount && formatedInputBRL(dataBills.amount),
        received: dataBills.received,
        received_partial: dataBills.received_partial,

        received_date:
          dataBills.received_date &&
          formatedInputDatePicker(dataBills.received_date),
        discount: dataBills.discount
          ? formatedInputBRL(dataBills.discount)
          : '',
        interest: dataBills.interest
          ? formatedInputBRL(dataBills.interest)
          : '',
        received_amount: dataBills.received_amount
          ? formatedInputBRL(dataBills.received_amount)
          : '',
        people_id: dataBills.people_id ? String(dataBills.people_id) : null,
        coast_center_id:
          dataBills.coast_center_id && String(dataBills.coast_center_id),
        comments: dataBills.comments,
      };

      setLoading(false);

      if (dataBillsToReceives.received) {
        const amountReceived =
          parseStrToFloatInputBRL(dataBillsToReceives.received_amount).toFixed(
            2
          ) * 1;

        setConferenceAmountReceived(+amountReceived);
      }

      setReceivedPartial(dataBillsToReceives.received_partial);
      setReceived(dataBillsToReceives.received);
      setToggleReceived(dataBills.received);

      formRef.current.setData(dataBillsToReceives);
    };

    fetchData();

    setId(location.state.item.id);
  }, [location.state]);

  useEffect(() => {
    const descriptionInput = formRef.current.getFieldRef('description');

    descriptionInput.focus();
  }, []);

  const handleSubmit = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          description: Yup.string().required('Descrição é obrigatório'),
          account_plan_id: Yup.string().required(
            'Plano de contas é obrigatório'
          ),
          account_id: Yup.string().required('Conta é obrigatório'),
          competency_date: Yup.date().required(
            'Data competência é obrigatório'
          ),
          maturity_date: Yup.string().required('Data vencimento obrigatório'),

          amount: Yup.string()
            .test(
              'testAmountBiggerZero',
              'Valor deve ser maior que zero',
              (value) => validatorBiggerZero(value)
            )
            .required('Valor é obrigatório'),
          coast_center_id: Yup.string().required(
            'Centro de custo é obrigatório'
          ),
          received_amount: Yup.string().when('received', {
            is: (val) => val === true,
            then: Yup.string()
              .test(
                'testAmountBiggerZero',
                'Valor deve ser maior que zero',
                (value) => validatorBiggerZero(value)
              )
              .required('Valor de recebimento é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const competency = format(dataForm.competency_date, 'yyyy-MM-dd');
        const maturity = format(dataForm.maturity_date, 'yyyy-MM-dd');

        if (competency > maturity) {
          addToast({
            type: 'error',
            title: 'Erro: Data compentência maior que data Vencimento!',
          });
          return;
        }

        if (
          parseStrToFloatInputBRL(dataForm.received_amount).toFixed(2) * 1 >
          confereceAmountReceived
        ) {
          addToast({
            type: 'error',
            title: 'Erro: Valor do recebimento maior que o Lançamento',
          });
          return;
        }

        if (received && receivedPartial) {
          const dataBillsToReceived = {
            description: dataForm.description,
            account_plan_id: parseInt(dataForm.account_plan_id, 10),
            account_id:
              dataForm.account_id && parseInt(dataForm.account_id, 10),
            competency_date: new Date(dataForm.competency_date),
            maturity_date: new Date(dataForm.maturity_date),
            amount: dataForm.amount && parseStrToFloatInputBRL(dataForm.amount),
            received_partial: true,
            discount: dataForm.discount
              ? parseStrToFloatInputBRL(dataForm.discount)
              : null,
            interest: dataForm.interest
              ? parseStrToFloatInputBRL(dataForm.interest)
              : null,
            received_amount: dataForm.received_amount
              ? parseStrToFloatInputBRL(dataForm.received_amount)
              : null,
            people_id: dataForm.people_id
              ? parseInt(dataForm.people_id, 10)
              : null,
            coast_center_id:
              dataForm.coast_center_id &&
              parseInt(dataForm.coast_center_id, 10),
            comments: dataForm.comments,
          };

          const billsReceived = await api.post(
            'billstoreceives',
            dataBillsToReceived
          );

          const billToReceiveLaunched = {
            bills_to_receive_id: billsReceived.data.id,
            user_id: user.id,
          };

          await api.post(`/launchcreditaccount`, billToReceiveLaunched);

          const dataBillsToReceiveREST = {
            description: `${dataForm.description} *`,
            account_plan_id: parseInt(dataForm.account_plan_id, 10),
            account_id:
              dataForm.account_id && parseInt(dataForm.account_id, 10),
            competency_date: new Date(dataForm.competency_date),
            maturity_date: new Date(dataForm.maturity_date),
            amount: restAmountReceived,
            received: false,
            received_partial: false,
            received_date: null,
            discount: null,
            interest: null,
            received_amount: null,
            people_id: dataForm.people_id
              ? parseInt(dataForm.people_id, 10)
              : null,
            coast_center_id:
              dataForm.coast_center_id &&
              parseInt(dataForm.coast_center_id, 10),
            comments: dataForm.comments,
          };

          await api.post('billstoreceives', dataBillsToReceiveREST);

          mutateGlobal(
            `billstoreceives?month=${format(new Date(), 'yyyy-MM-dd')}`
          );

          addToast({
            type: 'success',
            title: 'Cadastro e Restante efetuado com Sucesso',
          });

          history.goBack();
          return;
        }

        if (received && !receivedPartial) {
          const dataBillsToReceive = {
            description: dataForm.description,
            account_plan_id: parseInt(dataForm.account_plan_id, 10),
            account_id:
              dataForm.account_id && parseInt(dataForm.account_id, 10),
            competency_date: new Date(dataForm.competency_date),
            maturity_date: new Date(dataForm.maturity_date),
            amount: dataForm.amount && parseStrToFloatInputBRL(dataForm.amount),

            received_partial: null,

            discount: dataForm.discount
              ? parseStrToFloatInputBRL(dataForm.discount)
              : null,
            interest: dataForm.interest
              ? parseStrToFloatInputBRL(dataForm.interest)
              : null,
            received_amount: dataForm.received_amount
              ? parseStrToFloatInputBRL(dataForm.received_amount)
              : null,
            people_id: dataForm.people_id
              ? parseInt(dataForm.people_id, 10)
              : null,
            coast_center_id:
              dataForm.coast_center_id &&
              parseInt(dataForm.coast_center_id, 10),
            comments: dataForm.comments,
          };

          const billsReceived = await api.post(
            'billstoreceives',
            dataBillsToReceive
          );

          const billToReceiveLaunched = {
            bills_to_receive_id: billsReceived.data.id,
            user_id: user.id,
          };

          await api.post(`/launchcreditaccount`, billToReceiveLaunched);

          mutateGlobal(
            `/billstoreceives?month=${format(new Date(), 'yyyy-MM-dd')}`
          );

          addToast({
            type: 'success',
            title: 'Cadastro efetuado com Sucesso',
          });

          history.goBack();
          return;
        }

        const dataBillsToReceive = {
          description: dataForm.description,
          account_plan_id: parseInt(dataForm.account_plan_id, 10),
          account_id: dataForm.account_id && parseInt(dataForm.account_id, 10),
          competency_date: new Date(dataForm.competency_date),
          maturity_date: new Date(dataForm.maturity_date),
          amount: dataForm.amount && parseStrToFloatInputBRL(dataForm.amount),
          received: dataForm.received,
          received_partial: null,
          received_date: received ? new Date(dataForm.received_date) : null,
          discount: dataForm.discount
            ? parseStrToFloatInputBRL(dataForm.discount)
            : null,
          interest: dataForm.interest
            ? parseStrToFloatInputBRL(dataForm.interest)
            : null,
          received_amount: dataForm.received_amount
            ? parseStrToFloatInputBRL(dataForm.received_amount)
            : null,
          people_id: dataForm.people_id
            ? parseInt(dataForm.people_id, 10)
            : null,
          coast_center_id:
            dataForm.coast_center_id && parseInt(dataForm.coast_center_id, 10),
          comments: dataForm.comments,
        };

        mutateGlobal(
          `/billstoreceives?month=${format(new Date(), 'yyyy-MM-dd')}`,
          [...data, dataBillsToReceive],
          false
        );
        await api.post('billstoreceives', dataBillsToReceive);

        addToast({
          type: 'success',
          title: 'Cadastro efetuado com Sucesso',
        });

        history.goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.response.status === 403) {
          addToast({
            type: 'error',
            description: 'Descrição já cadastrada',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [
      addToast,
      history,
      data,
      received,
      receivedPartial,
      restAmountReceived,
      confereceAmountReceived,
      user.id,
    ]
  );

  const handleUpdate = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          description: Yup.string().required('Descrição é obrigatório'),
          account_plan_id: Yup.string().required(
            'Plano de contas é obrigatório'
          ),
          account_id: Yup.string().required('Conta é obrigatório'),
          competency_date: Yup.date().required(
            'Data competência é obrigatório'
          ),
          maturity_date: Yup.string().required('Data vencimento obrigatório'),

          amount: Yup.string()
            .test(
              'testAmountBiggerZero',
              'Valor deve ser maior que zero',
              (value) => validatorBiggerZero(value)
            )
            .required('Valor é obrigatório'),
          coast_center_id: Yup.string().required(
            'Centro de custo é obrigatório'
          ),
          received_amount: Yup.string().when('received', {
            is: (val) => val === true,
            then: Yup.string()
              .test(
                'testAmountBiggerZero',
                'Valor deve ser maior que zero',
                (value) => validatorBiggerZero(value)
              )
              .required('Valor de recebimento é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const competency = format(dataForm.competency_date, 'yyyy-MM-dd');
        const maturity = format(dataForm.maturity_date, 'yyyy-MM-dd');

        if (competency > maturity) {
          addToast({
            type: 'error',
            title: 'Erro: Data compentência maior que data Vencimento!',
          });
          return;
        }

        if (
          parseStrToFloatInputBRL(dataForm.received_amount).toFixed(2) * 1 >
          confereceAmountReceived
        ) {
          addToast({
            type: 'error',
            title: 'Erro: Valor do recebimento maior que o Lançamento',
          });
          return;
        }

        if (received && receivedPartial) {
          const dataBillsToReceived = {
            description: dataForm.description,
            account_plan_id: parseInt(dataForm.account_plan_id, 10),
            account_id:
              dataForm.account_id && parseInt(dataForm.account_id, 10),

            competency_date: new Date(dataForm.competency_date),
            maturity_date: new Date(dataForm.maturity_date),
            amount: dataForm.amount && parseStrToFloatInputBRL(dataForm.amount),
            received_partial: true,
            discount: dataForm.discount
              ? parseStrToFloatInputBRL(dataForm.discount)
              : null,
            interest: dataForm.interest
              ? parseStrToFloatInputBRL(dataForm.interest)
              : null,
            received_amount: dataForm.received_amount
              ? parseStrToFloatInputBRL(dataForm.received_amount)
              : null,
            people_id: dataForm.people_id
              ? parseInt(dataForm.people_id, 10)
              : null,
            coast_center_id:
              dataForm.coast_center_id &&
              parseInt(dataForm.coast_center_id, 10),
            comments: dataForm.comments,
          };

          await api.patch(`billstoreceives/${id}`, dataBillsToReceived);

          const billsReceivedUpdated = await api.get(`billstoreceives/${id}`);

          const billToReceiveLaunched = {
            bills_to_receive_id: billsReceivedUpdated.data.id,
            user_id: user.id,
          };

          await api.post(`/launchcreditaccount`, billToReceiveLaunched);

          const dataBillsToReceiveREST = {
            description: `${dataForm.description} *`,
            account_plan_id: parseInt(dataForm.account_plan_id, 10),
            account_id:
              dataForm.account_id && parseInt(dataForm.account_id, 10),
            competency_date: new Date(dataForm.competency_date),
            maturity_date: new Date(dataForm.maturity_date),
            amount: restAmountReceived,
            received: false,
            received_partial: false,
            received_date: null,
            discount: null,
            interest: null,
            received_amount: null,
            people_id: dataForm.people_id
              ? parseInt(dataForm.people_id, 10)
              : null,
            coast_center_id:
              dataForm.coast_center_id &&
              parseInt(dataForm.coast_center_id, 10),
            comments: dataForm.comments,
            sale_id: saleId ? parseInt(saleId, 10) : null,
            sale: !!saleId,
          };

          await api.post('billstoreceives', dataBillsToReceiveREST);

          mutateGlobal(
            `billstoreceives?month=${format(new Date(), 'yyyy-MM-dd')}`
          );

          addToast({
            type: 'success',
            title: 'Edição e Restante efetuado com Sucesso',
          });

          history.goBack();
          return;
        }

        if (received && !receivedPartial) {
          const dataBillsToReceive = {
            description: dataForm.description,
            account_plan_id: parseInt(dataForm.account_plan_id, 10),
            account_id:
              dataForm.account_id && parseInt(dataForm.account_id, 10),
            competency_date: new Date(dataForm.competency_date),
            maturity_date: new Date(dataForm.maturity_date),
            amount: dataForm.amount && parseStrToFloatInputBRL(dataForm.amount),
            received_partial: null,
            discount: dataForm.discount
              ? parseStrToFloatInputBRL(dataForm.discount)
              : null,
            interest: dataForm.interest
              ? parseStrToFloatInputBRL(dataForm.interest)
              : null,
            received_amount: dataForm.received_amount
              ? parseStrToFloatInputBRL(dataForm.received_amount)
              : null,
            people_id: dataForm.people_id
              ? parseInt(dataForm.people_id, 10)
              : null,
            coast_center_id:
              dataForm.coast_center_id &&
              parseInt(dataForm.coast_center_id, 10),
            comments: dataForm.comments,
          };

          await api.patch(`billstoreceives/${id}`, dataBillsToReceive);

          const billsReceivedUpdated = await api.get(`billstoreceives/${id}`);

          const billToReceiveLaunched = {
            bills_to_receive_id: billsReceivedUpdated.data.id,
            user_id: user.id,
          };

          await api.post(`/launchcreditaccount`, billToReceiveLaunched);

          mutateGlobal(
            `billstoreceives?month=${format(new Date(), 'yyyy-MM-dd')}`
          );

          addToast({
            type: 'success',
            title: 'Edição efetuado com Sucesso',
          });

          history.push('/billstoreceives');
          return;
        }

        const dataBillsToReceive = {
          description: dataForm.description,
          account_plan_id: parseInt(dataForm.account_plan_id, 10),
          account_id: dataForm.account_id && parseInt(dataForm.account_id, 10),
          competency_date: new Date(dataForm.competency_date),
          maturity_date: new Date(dataForm.maturity_date),
          amount: dataForm.amount && parseStrToFloatInputBRL(dataForm.amount),
          received: dataForm.received,
          received_partial: null,
          received_date: received ? new Date(dataForm.received_date) : null,
          discount: dataForm.discount
            ? parseStrToFloatInputBRL(dataForm.discount)
            : null,
          interest: dataForm.interest
            ? parseStrToFloatInputBRL(dataForm.interest)
            : null,
          received_amount: dataForm.received_amount
            ? parseStrToFloatInputBRL(dataForm.received_amount)
            : null,
          people_id: dataForm.people_id
            ? parseInt(dataForm.people_id, 10)
            : null,
          coast_center_id:
            dataForm.coast_center_id && parseInt(dataForm.coast_center_id, 10),
          comments: dataForm.comments,
        };

        await api.patch(`billstoreceives/${id}`, dataBillsToReceive);

        mutateGlobal(
          `billstoreceives?month=${format(new Date(), 'yyyy-MM-dd')}`
        );

        addToast({
          type: 'success',
          title: 'Cadastro editado com Sucesso',
        });

        history.goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.response.status === 403) {
          addToast({
            type: 'error',
            description: 'Descrição já cadastrada',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [
      addToast,
      history,
      received,
      receivedPartial,
      restAmountReceived,
      confereceAmountReceived,
      user.id,
      id,
      saleId,
    ]
  );

  const handleCheckBoxReceived = useCallback((e) => {
    if (e.target.checked) {
      setReceived(true);
      const amount = formRef.current.getFieldValue('amount');
      formRef.current.setFieldValue('received_amount', amount);

      const amountReceiveFloat = parseStrToFloatInputBRL(amount);
      setConferenceAmountReceived(+amountReceiveFloat);
    } else {
      setReceived(false);
      setReceivedPartial(false);
      formRef.current.setFieldValue('discount', '');
      formRef.current.setFieldValue('interest', '');
      formRef.current.setFieldValue('received_amount', '');
      setConferenceAmountReceived(0);
      setRestAmountReceived(0);
    }
  }, []);

  const handleCalculatorFinalReceived = useCallback(() => {
    if (!received) return;

    const amount = formRef.current.getFieldValue('amount');
    const discount = formRef.current.getFieldValue('discount');
    const interest = formRef.current.getFieldValue('interest');

    const amountFloat = parseStrToFloatInputBRL(amount) * 1;
    const discountFloat = parseStrToFloatInputBRL(discount) * 1;
    const interestFloat = parseStrToFloatInputBRL(interest) * 1;

    const amountReceive = (amountFloat + interestFloat - discountFloat).toFixed(
      2
    );
    setConferenceAmountReceived(amountReceive);

    const amountReceiveFormated = formatedInputBRL(amountReceive);

    formRef.current.setFieldValue('received_amount', amountReceiveFormated);
  }, [received]);

  const handleConfereceFinalReceived = () => {
    if (!received) return;

    const amountReceive = formRef.current.getFieldValue('received_amount');
    const amountReceiveFloat = parseStrToFloatInputBRL(amountReceive);

    if (+amountReceiveFloat > +confereceAmountReceived) {
      addToast({
        type: 'error',
        title: 'Valor recebido incorreto',
        description: 'Erro: Valor do recebimento incorreto. Verifique!',
      });
      return;
    }
    if (+amountReceiveFloat <= 0) {
      addToast({
        type: 'error',
        title: 'Valor Pago incorreto',
        description: 'Valor recebido tem que ser maior que zero',
      });
      return;
    }
    if (+amountReceiveFloat < +confereceAmountReceived) {
      setReceivedPartial(true);

      const restAmoutreceived = (
        +confereceAmountReceived - +amountReceiveFloat
      ).toFixed(2);

      setRestAmountReceived(restAmoutreceived);

      addToast({
        type: 'info',
        title: 'Atenção: Valor recebido menor que valor',
        description: 'Será criado um novo lançamento com o restante.',
      });
    }
  };

  const handleEnter = useCallback((e) => {
    if (e.keyCode === 13) e.preventDefault();
  }, []);

  return (
    <>
      <NavBarMenu />
      {loading && <Loading />}
      {!loading && (
        <ContainerBox
          title={location.state ? 'Editar Recebimento' : 'Novo Recebimento'}
        >
          {toggleReceived && (
            <Info>
              <FiInfo color="#2196f3" size={22} />
              Para editar recebimentos já recebidos é necessário estorná-los
            </Info>
          )}

          <Form
            ref={formRef}
            onSubmit={location.state ? handleUpdate : handleSubmit}
            onKeyDown={(e) => handleEnter(e)}
          >
            <ContentForm noactive={toggleReceived}>
              <Col1>
                <GroupFields>
                  <Input
                    id="description"
                    name="description"
                    label="Descrição*"
                    autoComplete="off"
                  />
                  <Select
                    label="Plano de Contas*"
                    name="account_plan_id"
                    id="account_plan_id"
                  >
                    <option value="">Selecione o Plano de Contas</option>
                    {accountPlans &&
                      accountPlans
                        .filter((item) => item.type === 'r')
                        .map((acc) => (
                          <option key={acc.id} value={acc.id}>
                            {acc.name}
                          </option>
                        ))}
                  </Select>
                </GroupFields>

                <GroupFieldsCol4>
                  <Select label="Conta*" name="account_id" id="account_id">
                    <option value="">Selecione a Conta</option>
                    {accounts &&
                      accounts.map((acc) => (
                        <option key={acc.id} value={acc.id}>
                          {acc.description}
                        </option>
                      ))}
                  </Select>

                  <InputDatePicker
                    name="competency_date"
                    id="competency_date"
                    label="Data Competência*"
                  />
                  <InputDatePicker
                    name="maturity_date"
                    id="maturity_date"
                    label="Data Vencimento*"
                  />
                  <InputBRL
                    name="amount"
                    id="amount"
                    label="Valor"
                    placeholder="R$"
                    className={!received ? '' : 'noactive'}
                    onBlur={handleCalculatorFinalReceived}
                  />
                </GroupFieldsCol4>

                <Hr />

                <GroupFields style={{ marginTop: 16, marginBottom: 16 }}>
                  <CheckBox
                    name="received"
                    id="received"
                    label={
                      receivedPartial ? 'Recebido - PARCIALMENTE' : 'Recebido'
                    }
                    onChange={(e) => handleCheckBoxReceived(e)}
                  />
                </GroupFields>

                <GroupFieldsCol4>
                  <InputDatePicker
                    name="received_date"
                    id="received_date"
                    label="Data Recebimento"
                    className={received ? '' : 'noactive'}
                  />
                  <InputBRL
                    name="discount"
                    id="discount"
                    label="Desconto"
                    placeholder="R$"
                    className={received ? '' : 'noactive'}
                    onBlur={handleCalculatorFinalReceived}
                  />
                  <InputBRL
                    name="interest"
                    id="interest"
                    label="Juros / Multas"
                    placeholder="R$"
                    className={received ? '' : 'noactive'}
                    onBlur={handleCalculatorFinalReceived}
                  />
                  <InputBRL
                    name="received_amount"
                    id="received_amount"
                    label="Valor Recebido"
                    placeholder="R$"
                    className={received ? '' : 'noactive'}
                    onBlur={handleConfereceFinalReceived}
                  />
                </GroupFieldsCol4>
              </Col1>
              <Col2>
                <GroupFieldsCol1>
                  <Select label="Cliente:" name="people_id" id="people_id">
                    <option value="">Selecione o Fornecedor</option>
                    {providers &&
                      providers
                        .filter((item) => item.client)
                        .map((p) => (
                          <option key={p.id} value={p.id}>
                            {p.pf?.name || p.pj?.fantasy_name}
                          </option>
                        ))}
                  </Select>
                  <div style={{ marginTop: 24 }}>
                    <Select
                      label="Centro de Custos:*"
                      name="coast_center_id"
                      id="coast_center_id"
                    >
                      <option value="">Selecione o Centro de Custo</option>
                      {coastcenters &&
                        coastcenters.map((c) => (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                    </Select>
                  </div>
                  <div style={{ marginTop: 24 }}>
                    <TextArea
                      style={{ marginTop: 0 }}
                      rows="3"
                      id="comments"
                      name="comments"
                      label="Observações:"
                      autoComplete="off"
                    />
                  </div>
                </GroupFieldsCol1>
              </Col2>
            </ContentForm>
            <Hr />

            <ButtonGroup>
              {!toggleReceived && (
                <ButtonSave icon={FiCheck}>Salvar</ButtonSave>
              )}

              <ButtonCancel onClick={() => history.goBack()} icon={FiX}>
                Cancelar
              </ButtonCancel>
            </ButtonGroup>
          </Form>
        </ContainerBox>
      )}
    </>
  );
};

export default BillsToReceiveNewEdit;
