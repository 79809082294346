import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FiCheck, FiX } from 'react-icons/fi';

import * as Yup from 'yup';
import { format } from 'date-fns';

import { Form } from '@unform/web';

import getValidationErrors from '../../../utils/getValidationErrors';
import { parseStrToFloatInputBRL } from '../../../utils/parseStrToFloatInputBRL';
import {
  formatedInputBRL,
  formatedInputDatePicker,
} from '../../../utils/formatedInputs';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import NavBarMenu from '../../../components/NavbarMenu';
import ContainerBox from '../../../components/ContainerBox';

import { InputBRL, InputDatePicker } from '../../../components/Form';
import ButtonSave from '../../../components/ButtonPrimaryBlue';
import ButtonCancel from '../../../components/ButtonCancel';

import { ButtonGroup, GroupFields } from './styles';

const AccountEditInitialBalance = () => {
  const { user } = useAuth();

  const location = useLocation();

  const [id, setId] = useState(null);

  const history = useHistory();

  const formRef = useRef(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (!location.state) {
      return;
    }

    setId(location.state.accountData.id);

    const balanceData = {
      initial_balance:
        location.state.accountData.initial_balance &&
        formatedInputBRL(location.state.accountData.initial_balance),
      initial_balance_date:
        location.state.accountData.initial_balance_date &&
        formatedInputDatePicker(
          location.state.accountData.initial_balance_date
        ),
    };

    formRef.current.setData(balanceData);
  }, [location.state]);

  const handleUpdatedInitialBalance = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          initial_balance: Yup.string().required('Saldo é obrigatório'),
          initial_balance_date: Yup.string().required(
            'Data do Saldo é obrigatório'
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const dataAccountEditInitialBalance = {
          account_id: id,
          new_initial_balance: parseStrToFloatInputBRL(
            dataForm.initial_balance
          ),
          date:
            dataForm.initial_balance_date &&
            format(dataForm.initial_balance_date, 'yyyy-MM-dd'),
          user_id: user.id,
        };

        await api.post(
          'launcheditinitialbalance',
          dataAccountEditInitialBalance
        );

        addToast({
          type: 'success',
          title: 'Saldo inicial editado com Sucesso',
        });

        history.goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, history, user.id, id]
  );

  return (
    <>
      <NavBarMenu />

      <ContainerBox title="Editar Saldo Inicial" small>
        <Form ref={formRef} onSubmit={handleUpdatedInitialBalance}>
          <GroupFields>
            <InputBRL
              id="initial_balance"
              name="initial_balance"
              label="Saldo Inicial R$*"
              autoComplete="off"
            />
          </GroupFields>
          <GroupFields>
            <InputDatePicker
              id="initial_balance_date"
              name="initial_balance_date"
              label="Data do Saldo Inicial*"
            />
          </GroupFields>

          <ButtonGroup>
            <ButtonSave icon={FiCheck}>Salvar</ButtonSave>

            <ButtonCancel onClick={() => history.goBack()} icon={FiX}>
              Cancelar
            </ButtonCancel>
          </ButtonGroup>
        </Form>
      </ContainerBox>
    </>
  );
};

export default AccountEditInitialBalance;
