import styled from 'styled-components';

export const TableHeader = styled.thead`
  background: #f2f2f2;

  th {
    font-weight: bold;
    text-align: center;
    padding: 18px 10px;
    border-bottom: 2px solid #d4d4d4;

    :nth-child(1) {
      max-width: 30px;
    }
    :nth-child(2) {
      max-width: 100px;
      text-align: left;
    }
    :nth-child(3) {
      max-width: 100px;
      text-align: left;
    }
    :nth-child(4) {
      max-width: 250px;
      text-align: left;
      padding-left: 16px;
    }
    :nth-child(5) {
      width: 200px;
      text-align: left;
    }
    :nth-child(6) {
      width: 150px;
    }

    :nth-child(7) {
      width: 100px;
    }

    :nth-child(8) {
      display: none;
    }

    :nth-child(9) {
      width: 100px;
    }

    :last-child {
      max-width: 15%;
      border-left: solid 1px #d1d5da;
    }
  }
`;
