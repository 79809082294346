import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #ededf0;
    color: #4f4f4f;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, textarea, select {
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-size: 14px;
    color: #4f4f4f;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 2px solid #d1d5da;
    -webkit-text-fill-color: #5d5d5d;
    -webkit-box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075);
    transition: background-color 5000s ease-in-out 0s;

    &:focus {
      border-color: #2188ff;
      box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
          0 0 0 0.2em rgba(3, 102, 214, 0.3);
    }
  }

  a {
    color: inherit;
    background: transparent;
    text-decoration: none;
    margin: 0;
    padding: 0;
  }

  button {
    cursor: pointer;
  }
`;
