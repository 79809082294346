import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { Form } from '@unform/web';
import { addMonths, subMonths, format } from 'date-fns';

import { mutate as mutateGlobal } from 'swr';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { useFetchSalesReceivable } from '../hooks/useFetchSalesReceivable';

import { InputSearch, InputDatePickerMonth } from '../../../components/Form';
import NavbarMenu from '../../../components/NavbarMenu';
import NavTabs from '../../../components/NavTabs';
import ContainerBoxWhite from '../../../components/ContainerBoxWhite';
import ButtonGroupbox from '../../../components/ContainerBox/ButtonGroupbox';
import Table from './TableReceives';

import Loading from '../../../components/Loading';

import { Container, Previews, PreviewsItem } from './styles';

const SalesReceivable = () => {
  const formRef = useRef(null);

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const urlMonth = useMemo(() => format(currentMonth, 'yyyy-MM-dd'), [
    currentMonth,
  ]);

  const [showSales, setShowSales] = useState(true);

  const [url, setUrl] = useState(`/salesreceivable?month=${urlMonth}`);

  const { dataList: salesReceivable, loading } = useFetchSalesReceivable(
    `/salesreceivable?month=${urlMonth}`
  );

  const {
    dataList: saleSearch,

    loading: loadingSearch,
  } = useFetchSalesReceivable(url);

  // const { dataList: searchBrand, loading: loadingSearch } = useFetch(url);

  const { user } = useAuth();
  const { addToast } = useToast();

  const head = {
    maturity_date: 'Vencimento',
    sale_id: 'Venda',
    client: 'Cliente',
    parcel: 'Parcela',
    amount: 'Valor',
    received_amount: 'Recebido',
  };

  const dataTabs = [
    { label: 'Vendas e Pedidos', link: '/sales' },
    { label: 'Parcelas a Receber', link: '/sales/receivable' },
  ];

  useEffect(() => {
    setShowSales(true);
  }, [currentMonth]);

  const handlePreviousMonth = useCallback(() => {
    setCurrentMonth(subMonths(currentMonth, 1));
  }, [currentMonth]);

  const handleNextMonth = useCallback(() => {
    setCurrentMonth(addMonths(currentMonth, 1));
  }, [currentMonth]);

  const handleSubmit = useCallback(() => {
    const input = formRef.current.getFieldValue('inputsearch');

    if (!input) {
      setUrl(`/salesreceivable?month=${urlMonth}`);
      setShowSales(true);
      return;
    }

    setUrl(`/salesreceivablesearch?search=${input}`);
    setShowSales(false);

    formRef.current.reset();
  }, [urlMonth]);

  const handleReceived = useCallback(
    async (item) => {
      try {
        const billToReceiveLaunched = {
          bills_to_receive_id: item.id,
          user_id: user.id,
          sale_id: item.sale_id,
        };
        await api.post(`/launchcreditaccount`, billToReceiveLaunched);

        mutateGlobal(`/salesreceivable?month=${urlMonth}`);

        addToast({
          type: 'success',
          title: 'Recebimento confirmado com Sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, user.id, urlMonth]
  );

  const handleUndoReceived = useCallback(
    async (item) => {
      try {
        if (!item.parcel) {
          if (
            window.confirm(
              `ATENÇÃO! Estorno de Recebimento parcial. Se confirmar não vai dar baixa na parcela gerada com o restante. VERIFIQUE!`
            )
          ) {
            const billToReceiveReverseLaunched = {
              bills_to_receive_id: item.id,
              user_id: user.id,
              sale_id: item.sale_id,
            };
            await api.post(
              `/reverselaunchcreditaccount`,
              billToReceiveReverseLaunched
            );

            mutateGlobal(`/salesreceivable?month=${urlMonth}`);
            addToast({
              type: 'success',
              title: 'Estorno de recebimento confirmado com Sucesso',
            });
          }
          return;
        }

        const billToReceiveReverseLaunched = {
          bills_to_receive_id: item.id,
          user_id: user.id,
        };
        await api.post(
          `/reverselaunchcreditaccount`,
          billToReceiveReverseLaunched
        );

        mutateGlobal(`/salesreceivable?month=${urlMonth}`);
        addToast({
          type: 'success',
          title: 'Estorno de recebimento confirmado com Sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, user.id, urlMonth]
  );

  const handleEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Container>
      <NavbarMenu />
      <ContainerBoxWhite>
        <NavTabs dataTabs={dataTabs} />
        <ButtonGroupbox>
          <InputDatePickerMonth
            selected={currentMonth}
            onChange={(date) => setCurrentMonth(date)}
            handlePreviousMonth={handlePreviousMonth}
            handleNextMonth={handleNextMonth}
          />

          <Form ref={formRef} onSubmit={() => {}}>
            <InputSearch
              id="inputsearch"
              name="inputsearch"
              autoComplete="off"
              placeholder="Pesquisar por Cliente"
              onKeyDown={(e) => handleEnter(e)}
              handleClick={handleSubmit}
            />
          </Form>
        </ButtonGroupbox>

        {showSales && (
          <Previews>
            <PreviewsItem color="#e02041">
              <p>Em atraso</p>
              {!salesReceivable && <span>R$ ...</span>}
              <span>
                {salesReceivable && salesReceivable.billsReceivesPastTotal}
              </span>
            </PreviewsItem>
            <PreviewsItem color="#2687E9">
              <p>A receber</p>
              {!salesReceivable && <span>R$ ...</span>}
              <span>
                {salesReceivable &&
                  salesReceivable.billsReceivesToReceivesTotal}
              </span>
            </PreviewsItem>
            <PreviewsItem color="#52c41a">
              <p>Recebido</p>
              {!salesReceivable && <span>R$ ...</span>}
              <span>
                {salesReceivable && salesReceivable.billsReceivedTotal}
              </span>
            </PreviewsItem>
            <PreviewsItem style={{ borderTopColor: '#4f4f4f' }}>
              <p>Total</p>
              {!salesReceivable && <span>R$ ...</span>}
              <span>{salesReceivable && salesReceivable.billsTOTAL}</span>
            </PreviewsItem>
          </Previews>
        )}

        {!showSales && (
          <Previews>
            <PreviewsItem color="#e02041">
              <p>Em atraso</p>
              {!saleSearch && <span>R$ ...</span>}
              <span>{saleSearch && saleSearch.billsReceivesPastTotal}</span>
            </PreviewsItem>
            <PreviewsItem color="#2687E9">
              <p>A receber</p>
              {!saleSearch && <span>R$ ...</span>}
              <span>
                {saleSearch && saleSearch.billsReceivesToReceivesTotal}
              </span>
            </PreviewsItem>
            <PreviewsItem color="#52c41a">
              <p>Recebido</p>
              {!saleSearch && <span>R$ ...</span>}
              <span>{saleSearch && saleSearch.billsReceivedTotal}</span>
            </PreviewsItem>
            <PreviewsItem style={{ borderTopColor: '#4f4f4f' }}>
              <p>Total</p>
              {!saleSearch && <span>R$ ...</span>}
              <span>{saleSearch && saleSearch.billsTOTAL}</span>
            </PreviewsItem>
          </Previews>
        )}

        {showSales && loading && <Loading />}
        {showSales && !loading && salesReceivable && (
          <Table
            head={head}
            data={salesReceivable.saleReceivable}
            handleReceived={handleReceived}
            handleUndoReceived={handleUndoReceived}
            pathname="/billstoreceives/newedit"
          />
        )}

        {!showSales && loadingSearch && <Loading />}
        {!showSales && !loadingSearch && saleSearch && (
          <Table
            head={head}
            data={saleSearch.saleReceivable}
            handleReceived={handleReceived}
            handleUndoReceived={handleUndoReceived}
            pathname="/billstoreceives/newedit"
          />
        )}
      </ContainerBoxWhite>
    </Container>
  );
};

export default SalesReceivable;
