import styled from 'styled-components';

export const Container = styled.span`
  display: flex;
  place-content: center;
  width: 100%;
  background: #f2f2f2;
  margin: 32px 0;
  padding: 16px 0;
  border-radius: 4px;
  border: 1px dotted #d4d4d4;

  font-size: 21px;
`;
