import React from 'react';

import Head from './Head';
import Row from './Row';

import NoRegisterFound from '../../../components/NoRegisterFound';

import { TableList, TableBody } from './styles';

const Table = ({ data, head, handleDelete, pathname }) => {
  if (!data[0]) {
    return <NoRegisterFound />;
  }

  const keys = Object.keys(data[0]);

  return (
    <TableList>
      <Head head={head} keys={keys} />
      <TableBody>
        {data.map((item, index) => (
          <Row
            item={item}
            key={String(index)}
            handleDelete={handleDelete}
            pathname={pathname}
          />
        ))}
      </TableBody>
    </TableList>
  );
};

export default Table;
