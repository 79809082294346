import styled, { css } from 'styled-components';

export const Container = styled.div`
  .noactiveselect {
    pointer-events: none;
    opacity: 0.6;
  }

  select {
    height: 40px;
    padding: 6px 8px;
    background: ${(props) => (props.bg ? '#ededf0' : '#fff')};
    color: #616161;
    border-radius: 4px;
    border: 2px solid #d1d5da;
    font-weight: bold;

    transition: all 0.2s ease-in-out;

    ${(props) =>
      props.isErrored &&
      css`
        border-color: #e02041 !important;
        box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
          0 0 0 0.2em rgba(224, 32, 65, 0.3);
      `}
    ${(props) =>
      props.isErrored &&
      props.isFilled &&
      css`
        border-color: #d1d5da !important;
        box-shadow: none;
      `}


      :focus {
      border-color: #2188ff;
      box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
        0 0 0 0.2em rgba(3, 102, 214, 0.3);
      transition: all 0.2s ease-in-out;
    }

    option {
      font-size: 16px;
    }

    select option:nth-of-type(1) {
      color: '#d1d5da';
    }
  }

  small {
    font-size: 12px;
    font-style: italic;
    color: #e02041;
    margin-top: 4px;
    transition: all 0.2s ease-in-out;
  }
`;
