import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { Form } from '@unform/web';
import { addMonths, subMonths, format } from 'date-fns';

import { mutate as mutateGlobal } from 'swr';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import { useFetchSales } from './hooks/useFetchSales';

import ButtonPrimaryGree from '../../components/ButtonPrimaryGreen';
import ButtonPrimaryBlue from '../../components/ButtonPrimaryBlue';
import { InputSearch, InputDatePickerMonth } from '../../components/Form';
import NavbarMenu from '../../components/NavbarMenu';
import NavTabs from '../../components/NavTabs';
import ContainerBoxWhite from '../../components/ContainerBoxWhite';
import ButtonGroupbox from '../../components/ContainerBox/ButtonGroupbox';
import Table from './Table';
import Loading from '../../components/Loading';

import { Container, ButtonWrapper, Previews, PreviewsItem } from './styles';

const Sales = () => {
  const formRef = useRef(null);

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const urlMonth = useMemo(() => format(currentMonth, 'yyyy-MM-dd'), [
    currentMonth,
  ]);

  const [showSales, setShowSales] = useState(true);

  const [url, setUrl] = useState(`/sales?month=${urlMonth}`);

  const { dataList: sales, totals, loading } = useFetchSales(
    `/sales?month=${urlMonth}`
  );

  const {
    dataList: saleSearch,
    totals: totalsSearch,
    loading: loadingSearch,
  } = useFetchSales(url);

  const { addToast } = useToast();

  const head = {
    date_sale: 'Data da Venda',
    sale_id: 'Venda',
    client: 'Cliente',
    situation: 'Status da Negociação',
    amount: 'Valor',
  };

  const dataTabs = [
    { label: 'Vendas e Pedidos', link: '/sales' },
    { label: 'Parcelas a Receber', link: '/sales/receivable' },
  ];

  useEffect(() => {
    setShowSales(true);
  }, [currentMonth]);

  const handlePreviousMonth = useCallback(() => {
    setCurrentMonth(subMonths(currentMonth, 1));
  }, [currentMonth]);

  const handleNextMonth = useCallback(() => {
    setCurrentMonth(addMonths(currentMonth, 1));
  }, [currentMonth]);

  const handleSaleDelete = useCallback(
    async (id) => {
      try {
        if (
          window.confirm(`Tem Certeza que realmente deseja excluir o registro?`)
        ) {
          await api.delete(`/sales/${id}`);

          mutateGlobal(`/sales?month=${urlMonth}`);
          mutateGlobal(url);

          addToast({
            type: 'success',
            title: 'Cadastro Excluido com Sucesso',
          });
        } else {
          return;
        }
      } catch (err) {
        if (err.response.status === 400) {
          addToast({
            type: 'error',
            title:
              'Não permitido excluir Venda com lançamentos financeiros. Verifique!',
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, url, urlMonth]
  );

  const handleSubmit = useCallback(() => {
    const input = formRef.current.getFieldValue('inputsearch');

    if (!input) {
      setUrl(`/sales?month=${urlMonth}`);
      setShowSales(true);

      return;
    }

    setUrl(`/salesearch?search=${input}`);
    setShowSales(false);

    formRef.current.reset();
  }, [urlMonth]);

  const handleEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Container>
      <NavbarMenu />
      <ContainerBoxWhite>
        <NavTabs dataTabs={dataTabs} />
        <ButtonGroupbox>
          <ButtonWrapper>
            <Link to={{ pathname: '/sales/new', state: { situation: 'sale' } }}>
              <ButtonPrimaryGree icon={FiPlus}>Nova Venda</ButtonPrimaryGree>
            </Link>
            <Link
              to={{
                pathname: '/sales/new',
                state: { situation: 'order_draft' },
              }}
            >
              <ButtonPrimaryBlue icon={FiPlus}>Novo Pedido</ButtonPrimaryBlue>
            </Link>
          </ButtonWrapper>

          <InputDatePickerMonth
            selected={currentMonth}
            onChange={(date) => setCurrentMonth(date)}
            handlePreviousMonth={handlePreviousMonth}
            handleNextMonth={handleNextMonth}
          />

          <Form ref={formRef} onSubmit={() => {}}>
            <InputSearch
              id="inputsearch"
              name="inputsearch"
              autoComplete="off"
              placeholder="Pesquisar por Cliente"
              onKeyDown={(e) => handleEnter(e)}
              handleClick={handleSubmit}
            />
          </Form>
        </ButtonGroupbox>
        {showSales && (
          <Previews>
            <PreviewsItem color="#e02041">
              <p>Pedidos Cancelados</p>
              {!totals && <span>R$ ...</span>}
              <span>{totals && totals[0].total_order_canceled}</span>
            </PreviewsItem>
            <PreviewsItem color="#FFC200">
              <p>Pedidos Rascunhos</p>
              {!totals && <span>R$ ...</span>}
              <span>{totals && totals[0].total_order_draft}</span>
            </PreviewsItem>
            <PreviewsItem color="#52c41a">
              <p>Pedidos Confirmados</p>
              {!totals && <span>R$ ...</span>}
              <span>{totals && totals[0].total_order_confirmed}</span>
            </PreviewsItem>
            <PreviewsItem color="#2687E9">
              <p>Vendas</p>
              {!totals && <span>R$ ...</span>}
              <span>{totals && totals[0].total_sale}</span>
            </PreviewsItem>
            <PreviewsItem style={{ borderTopColor: '#4f4f4f' }}>
              <p>Total Previsto</p>
              {!totals && <span>R$ ...</span>}
              <span>{totals && totals[0].total_preview}</span>
            </PreviewsItem>
          </Previews>
        )}
        {!showSales && (
          <Previews>
            <PreviewsItem color="#e02041">
              <p>Pedidos Cancelados</p>
              {!totalsSearch && <span>R$ ...</span>}
              <span>
                {totalsSearch && totalsSearch[0].total_order_canceled}
              </span>
            </PreviewsItem>
            <PreviewsItem color="#FFC200">
              <p>Pedidos Rascunhos</p>
              {!totalsSearch && <span>R$ ...</span>}
              <span>{totalsSearch && totalsSearch[0].total_order_draft}</span>
            </PreviewsItem>
            <PreviewsItem color="#52c41a">
              <p>Pedidos Confirmados</p>
              {!totalsSearch && <span>R$ ...</span>}
              <span>
                {totalsSearch && totalsSearch[0].total_order_confirmed}
              </span>
            </PreviewsItem>
            <PreviewsItem color="#2687E9">
              <p>Vendas</p>
              {!totalsSearch && <span>R$ ...</span>}
              <span>{totalsSearch && totalsSearch[0].total_sale}</span>
            </PreviewsItem>
            <PreviewsItem style={{ borderTopColor: '#4f4f4f' }}>
              <p>Total Previsto</p>
              {!totalsSearch && <span>R$ ...</span>}
              <span>{totalsSearch && totalsSearch[0].total_preview}</span>
            </PreviewsItem>
          </Previews>
        )}

        {showSales && loading && <Loading />}
        {showSales && !loading && sales && (
          <>
            <Table
              head={head}
              data={sales}
              handleDelete={handleSaleDelete}
              pathname="/sales/view"
            />
          </>
        )}
        {!showSales && loadingSearch && <Loading />}
        {!showSales && !loadingSearch && saleSearch && (
          <Table
            head={head}
            data={saleSearch}
            handleDelete={handleSaleDelete}
            pathname="/sales/view"
          />
        )}
      </ContainerBoxWhite>
    </Container>
  );
};

export default Sales;
