import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FiCheck, FiX } from 'react-icons/fi';
import useSWR, { mutate as mutateGlobal } from 'swr';
import * as Yup from 'yup';
import { Form } from '@unform/web';

import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import NavBarMenu from '../../../components/NavbarMenu';
import ContainerBox from '../../../components/ContainerBox';
import { Input } from '../../../components/Form';
import ButtonSave from '../../../components/ButtonPrimaryBlue';
import ButtonCancel from '../../../components/ButtonCancel';

import { ButtonGroup, Wrapper } from './styles';

const UnitsNewEdit = () => {
  const { data } = useSWR('units');

  const [id, setId] = useState(null);

  const location = useLocation();

  const history = useHistory();

  const formRef = useRef(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (!location.state) {
      return;
    }

    setId(location.state.item.id);
  }, [location.state]);

  useEffect(() => {
    const descriptionInput = formRef.current.getFieldRef('description');

    descriptionInput.focus();
  }, []);

  const handleSubmit = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          description: Yup.string().required('Descrição é obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        mutateGlobal(`/units?page=1`, [...data, dataForm], false);

        await api.post('units', dataForm);

        addToast({
          type: 'success',
          title: 'Cadastro efetuado com Sucesso',
        });

        history.push('/units');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.response.status === 403) {
          addToast({
            type: 'error',
            description: 'Descrição já cadastrada',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, history, data]
  );

  const handleUpdate = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          description: Yup.string().required('Descrição é obrigatório'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });
        await api.patch(`units/${id}`, dataForm);

        mutateGlobal(`units/${id}`, dataForm);

        addToast({
          title: 'Cadastro alterado com Sucesso',
        });

        history.push('/units');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.response.status === 403) {
          addToast({
            type: 'error',
            description: 'Descrição já cadastrada',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, history, id]
  );
  return (
    <>
      <NavBarMenu />

      <ContainerBox
        title={location.state ? 'Alterar Unidade' : 'Cadastro de Unidade'}
        small
      >
        <Form
          initialData={location.state ? location.state.item : {}}
          ref={formRef}
          onSubmit={location.state ? handleUpdate : handleSubmit}
        >
          <Wrapper>
            <Input
              id="description"
              name="description"
              label="Descrição da Unidade*"
              autoComplete="off"
            />
          </Wrapper>
          <ButtonGroup>
            <ButtonSave icon={FiCheck}>Salvar</ButtonSave>
            <Link to="/units">
              <ButtonCancel icon={FiX}>Cancelar</ButtonCancel>
            </Link>
          </ButtonGroup>
        </Form>
      </ContainerBox>
    </>
  );
};

export default UnitsNewEdit;
