import React, { useCallback, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { Form } from '@unform/web';

import { mutate as mutateGlobal } from 'swr';
import api from '../../services/api';
import { useFetch } from '../../hooks/useFetch';
import { useToast } from '../../hooks/toast';

import ButtonPrimaryGree from '../../components/ButtonPrimaryGreen';
import { InputSearch } from '../../components/Form';
import NavbarMenu from '../../components/NavbarMenu';
import ContainerBox from '../../components/ContainerBox';
import ButtonGroupbox from '../../components/ContainerBox/ButtonGroupbox';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination';

import { Container, TotalItems } from './styles';

const CoastCenters = () => {
  const formRef = useRef(null);

  const [page, setPage] = useState(1);
  const [url, setUrl] = useState('/coastcenters?page=1');
  const [showCoastCenters, setShowCoastCenters] = useState(true);

  const { dataList: coastCenters, loading, totalItems } = useFetch(
    `/coastcenters?page=${page}`
  );

  const { dataList: searchCoastCenter, loading: loadingSearch } = useFetch(url);

  const { addToast } = useToast();

  const head = {
    id: 'ID',
    name: 'Centro de Custo',
    coast_center_default: ' ',
  };

  const dataTable =
    coastCenters &&
    coastCenters.map((c) => {
      return {
        id: c.id,
        name: c.name,
        coast_center_default: c.coast_center_default ? 'Padrão' : '',
      };
    });

  const handleCoastCenterDelete = useCallback(
    async (id) => {
      try {
        if (
          window.confirm(`Tem Certeza que realmente deseja excluir o registro?`)
        ) {
          await api.delete(`/coastcenters/${id}`);

          mutateGlobal(`/coastcenters?page=${page}`);
          mutateGlobal(url);

          addToast({
            type: 'success',
            title: 'Cadastro Excluido com Sucesso',
          });
        } else {
          return;
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, page, url]
  );

  const handleSubmit = useCallback(() => {
    const input = formRef.current.getFieldValue('inputcoast');

    if (!input) {
      setUrl('/coastcenters?page=1');
      setPage(1);
      setShowCoastCenters(true);

      return;
    }

    setUrl(`/coastcentersearch?search=${input}`);
    setShowCoastCenters(false);
    formRef.current.reset();
  }, []);

  const handleEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Container>
      <NavbarMenu />
      <ContainerBox title="Centro de Custo">
        <ButtonGroupbox>
          <Link to="/coastcenters/newedit">
            <ButtonPrimaryGree icon={FiPlus}>Adicionar</ButtonPrimaryGree>
          </Link>

          <Form ref={formRef} onSubmit={() => {}}>
            <InputSearch
              id="inputcoast"
              name="inputcoast"
              autoComplete="off"
              onKeyDown={(e) => handleEnter(e)}
              handleClick={handleSubmit}
            />
          </Form>
        </ButtonGroupbox>

        {showCoastCenters && loading && <Loading />}

        {showCoastCenters && !loading && coastCenters && (
          <>
            <Table
              head={head}
              data={dataTable}
              handleDelete={handleCoastCenterDelete}
              pathname="/coastcenters/newedit"
            />
            {totalItems && (
              <TotalItems>
                <span>
                  Total de registro(s): <b>{totalItems}</b>
                </span>
              </TotalItems>
            )}
            {totalItems > 20 && (
              <Pagination
                setPage={setPage}
                currentPage={page}
                totalItems={totalItems}
              />
            )}
          </>
        )}

        {!showCoastCenters && loadingSearch && <Loading />}

        {!showCoastCenters && !loadingSearch && searchCoastCenter && (
          <Table
            head={head}
            data={searchCoastCenter}
            handleDelete={handleCoastCenterDelete}
            pathname="/coastcenters/newedit"
          />
        )}
      </ContainerBox>
    </Container>
  );
};

export default CoastCenters;
