import React, { useCallback, useRef, useEffect } from 'react';

import { FiCheck, FiX } from 'react-icons/fi';
import { mutate as mutateGlobal } from 'swr';
import * as Yup from 'yup';
import { Form } from '@unform/web';

import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';
import { useFetch } from '../../hooks/useFetch';

import { Input } from '../Form';
import ButtonSave from '../ButtonPrimaryBlue';
import ButtonCancel from '../ButtonCancel';

import { ButtonGroup, Wrapper, Container, Header, Content } from './styles';

const ModalRegister = ({
  title,
  label,
  name,
  urlPost,
  urlGet,
  setShowModalRegister,
}) => {
  const { dataList } = useFetch(urlGet);
  const formRef = useRef(null);
  const { addToast } = useToast();

  useEffect(() => {
    const input = formRef.current.getFieldRef(name);

    input.focus();
  }, [name]);

  const handleSubmit = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        if (name === 'name') {
          const schema = Yup.object().shape({
            name: Yup.string().required(`${label} é obrigatório`),
          });

          await schema.validate(dataForm, {
            abortEarly: false,
          });
        }

        if (name === 'description') {
          const schema = Yup.object().shape({
            description: Yup.string().required(`${label} é obrigatório`),
          });

          await schema.validate(dataForm, {
            abortEarly: false,
          });
        }

        mutateGlobal(urlGet, { ...dataList, dataForm }, false);

        await api.post(urlPost, dataForm);

        mutateGlobal(urlGet);

        addToast({
          type: 'success',
          title: 'Cadastro efetuado com Sucesso',
        });

        setShowModalRegister(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }

        if (err.response.status === 403) {
          addToast({
            type: 'error',
            description: `${label} já cadastrado`,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, dataList, name, label, urlGet, urlPost, setShowModalRegister]
  );
  return (
    <Container>
      <Content>
        <Header>
          <h1>{title}</h1>
        </Header>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <Input
              id={name}
              name={name}
              label={`${label}*`}
              autoComplete="off"
            />
          </Wrapper>
          <ButtonGroup>
            <ButtonSave icon={FiCheck}>Salvar</ButtonSave>

            <ButtonCancel
              onClick={() => setShowModalRegister(false)}
              icon={FiX}
            >
              Cancelar
            </ButtonCancel>
          </ButtonGroup>
        </Form>
      </Content>
    </Container>
  );
};

export default ModalRegister;
