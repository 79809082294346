import React, { useCallback, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { Form } from '@unform/web';

import { mutate as mutateGlobal } from 'swr';
import api from '../../services/api';
import { useFetchProducts } from './hooks/useFetchProducts';
import { useFetchSearchProducts } from './hooks/useFetchSeachProducts';
import { useToast } from '../../hooks/toast';

import ButtonPrimaryGree from '../../components/ButtonPrimaryGreen';
import { InputSearch } from '../../components/Form';
import NavbarMenu from '../../components/NavbarMenu';
import ContainerBox from '../../components/ContainerBox';
import ButtonGroupbox from '../../components/ContainerBox/ButtonGroupbox';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination';

import { Container, TotalItems } from './styles';

const Products = () => {
  const formRef = useRef(null);

  const [page, setPage] = useState(1);
  const [url, setUrl] = useState('/products?page=1');
  const [showProducts, setShowProducts] = useState(true);

  const { dataList: products, loading, totalItems } = useFetchProducts(
    `/products?page=${page}`
  );

  const {
    dataList: searchProduct,
    loading: loadingSearch,
  } = useFetchSearchProducts(url);

  const { addToast } = useToast();

  const head = {
    id: 'ID',
    description: 'Descrição',
    sale_price: 'Preço de Venda',
    stock: 'Estoque',
  };

  const handleProductDelete = useCallback(
    async (id) => {
      try {
        if (
          window.confirm(`Tem Certeza que realmente deseja excluir o registro?`)
        ) {
          await api.delete(`/products/${id}`);

          mutateGlobal(`/products?page=${page}`);
          mutateGlobal(url);

          addToast({
            type: 'success',
            title: 'Cadastro Excluido com Sucesso',
          });
        } else {
          return;
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, page, url]
  );

  const handleSubmit = useCallback(() => {
    const input = formRef.current.getFieldValue('inputproduct');

    if (!input) {
      setUrl('/products?page=1');
      setPage(1);
      setShowProducts(true);

      return;
    }

    setUrl(`/productsearch?search=${input}`);
    setShowProducts(false);
    formRef.current.reset();
  }, []);

  const handleEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Container>
      <NavbarMenu />
      <ContainerBox title="Produtos">
        <ButtonGroupbox>
          <Link to="/products/newedit">
            <ButtonPrimaryGree icon={FiPlus}>Adicionar</ButtonPrimaryGree>
          </Link>

          <Form ref={formRef} onSubmit={() => {}}>
            <InputSearch
              id="inputproduct"
              name="inputproduct"
              autoComplete="off"
              onKeyDown={(e) => handleEnter(e)}
              handleClick={handleSubmit}
            />
          </Form>
        </ButtonGroupbox>

        {showProducts && loading && <Loading />}

        {showProducts && !loading && products && (
          <>
            <Table
              head={head}
              data={products}
              handleDelete={handleProductDelete}
              pathname="/products/newedit"
            />
            {totalItems && (
              <TotalItems>
                <span>
                  Total de registro(s): <b>{totalItems}</b>
                </span>
              </TotalItems>
            )}
            {totalItems > 20 && (
              <Pagination
                setPage={setPage}
                currentPage={page}
                totalItems={totalItems}
              />
            )}
          </>
        )}

        {!showProducts && loadingSearch && <Loading />}

        {!showProducts && !loadingSearch && searchProduct && (
          <Table
            head={head}
            data={searchProduct}
            handleDelete={handleProductDelete}
            pathname="/products/newedit"
          />
        )}
      </ContainerBox>
    </Container>
  );
};

export default Products;
