import styled, { keyframes } from 'styled-components';

const bounce_inTurnFadingTextG = keyframes`

    0% {
      color: rgb(0, 0, 0);
    }

    100% {
      color: rgb(255, 255, 255);
    }
    `;

export const Container = styled.div`
  margin: 40px 0;

  #inTurnFadingTextG {
    width: 168px;
    margin: auto;
  }

  .inTurnFadingTextG {
    color: #616161;
    font-family: Arial;
    font-size: 17px;
    text-decoration: none;
    font-weight: normal;
    font-style: normal;
    float: left;
    animation-name: ${bounce_inTurnFadingTextG};
    -o-animation-name: ${bounce_inTurnFadingTextG};
    -ms-animation-name: ${bounce_inTurnFadingTextG};
    -webkit-animation-name: ${bounce_inTurnFadingTextG};
    -moz-animation-name: ${bounce_inTurnFadingTextG};
    animation-duration: 1.551s;
    -o-animation-duration: 1.551s;
    -ms-animation-duration: 1.551s;
    -webkit-animation-duration: 1.551s;
    -moz-animation-duration: 1.551s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
  }

  #inTurnFadingTextG_1 {
    animation-delay: 0.5525s;
    -o-animation-delay: 0.5525s;
    -ms-animation-delay: 0.5525s;
    -webkit-animation-delay: 0.5525s;
    -moz-animation-delay: 0.5525s;
  }
  #inTurnFadingTextG_2 {
    animation-delay: 0.659s;
    -o-animation-delay: 0.659s;
    -ms-animation-delay: 0.659s;
    -webkit-animation-delay: 0.659s;
    -moz-animation-delay: 0.659s;
  }
  #inTurnFadingTextG_3 {
    animation-delay: 0.7755s;
    -o-animation-delay: 0.7755s;
    -ms-animation-delay: 0.7755s;
    -webkit-animation-delay: 0.7755s;
    -moz-animation-delay: 0.7755s;
  }
  #inTurnFadingTextG_4 {
    animation-delay: 0.882s;
    -o-animation-delay: 0.882s;
    -ms-animation-delay: 0.882s;
    -webkit-animation-delay: 0.882s;
    -moz-animation-delay: 0.882s;
  }
  #inTurnFadingTextG_5 {
    animation-delay: 0.9985s;
    -o-animation-delay: 0.9985s;
    -ms-animation-delay: 0.9985s;
    -webkit-animation-delay: 0.9985s;
    -moz-animation-delay: 0.9985s;
  }
  #inTurnFadingTextG_6 {
    animation-delay: 1.105s;
    -o-animation-delay: 1.105s;
    -ms-animation-delay: 1.105s;
    -webkit-animation-delay: 1.105s;
    -moz-animation-delay: 1.105s;
  }
  #inTurnFadingTextG_7 {
    animation-delay: 1.2115s;
    -o-animation-delay: 1.2115s;
    -ms-animation-delay: 1.2115s;
    -webkit-animation-delay: 1.2115s;
    -moz-animation-delay: 1.2115s;
  }
  #inTurnFadingTextG_8 {
    animation-delay: 1.328s;
    -o-animation-delay: 1.328s;
    -ms-animation-delay: 1.328s;
    -webkit-animation-delay: 1.328s;
    -moz-animation-delay: 1.328s;
  }
  #inTurnFadingTextG_9 {
    animation-delay: 1.4345s;
    -o-animation-delay: 1.4345s;
    -ms-animation-delay: 1.4345s;
    -webkit-animation-delay: 1.4345s;
    -moz-animation-delay: 1.4345s;
  }
  #inTurnFadingTextG_10 {
    animation-delay: 1.551s;
    -o-animation-delay: 1.551s;
    -ms-animation-delay: 1.551s;
    -webkit-animation-delay: 1.551s;
    -moz-animation-delay: 1.551s;
  }
  #inTurnFadingTextG_11 {
    animation-delay: 1.6575s;
    -o-animation-delay: 1.6575s;
    -ms-animation-delay: 1.6575s;
    -webkit-animation-delay: 1.6575s;
    -moz-animation-delay: 1.6575s;
  }
  #inTurnFadingTextG_12 {
    animation-delay: 1.764s;
    -o-animation-delay: 1.764s;
    -ms-animation-delay: 1.764s;
    -webkit-animation-delay: 1.764s;
    -moz-animation-delay: 1.764s;
  }
  #inTurnFadingTextG_13 {
    animation-delay: 1.8805s;
    -o-animation-delay: 1.8805s;
    -ms-animation-delay: 1.8805s;
    -webkit-animation-delay: 1.8805s;
    -moz-animation-delay: 1.8805s;
  }
`;
