import styled from 'styled-components';
import { shade } from 'polished';

export const Section = styled.div`
  margin-bottom: 104px;
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 888;
  width: 100%;
  height: 64px;
  margin-bottom: 40px;

  background: #3f3e39;
  color: #fff;
  font-weight: bold;

  &.scrolling {
    background: rgba(63, 62, 57, 0.8);
    backdrop-filter: saturate(180%) blur(5px);
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%);
    transition: 0.2s;
  }
`;

export const Content = styled.nav`
  width: 100%;
  height: 64px;
  max-width: 1120px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  position: relative;

  img {
    height: 36px;
  }

  a {
    text-decoration: none;
    color: #fff;
  }
`;

export const Navigation = styled.ul`
  display: flex;
  margin-left: 64px;
  position: relative;
`;

export const NavItem = styled.li`
  list-style: none;
  position: relative;
  padding: 24px 0;
  transition: all 0.15s ease;

  > span,
  a {
    padding: 24px 16px;
    cursor: pointer;
  }

  :hover {
    background: ${shade(0.4, '#3f3e39')};
  }

  ul {
    position: absolute;
    display: block;
    min-width: 210px;
    min-width: 280px;
    border-radius: 0 0 4px 4px;
    list-style: none;

    visibility: hidden;
    opacity: 0;
    pointer-events: none;

    top: 0;
    left: 0;
    transform: translateY(48px);
    z-index: 999;
  }

  :hover ul {
    background: ${shade(0.4, '#3f3e39')};

    transform: translateY(64px);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    z-index: 999;
    transition: all 0.2s ease;

    li {
      a {
        display: block;
        padding: 16px 0 16px 16px;
      }
      :hover {
        background: #43c6ac;
        max-height: 64px;
        a {
          font-weight: bold;
        }
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 0;
    background: #43c6ac;
    z-index: 1000;
    opacity: 0;
    transition: all 0.15s ease;
  }
  &:hover::after {
    opacity: 1;
    height: 4px;
  }
`;
