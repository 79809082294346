import React, { useCallback, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { Form } from '@unform/web';

import { mutate as mutateGlobal } from 'swr';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import { useFetch } from '../../hooks/useFetch';

import ButtonPrimaryGree from '../../components/ButtonPrimaryGreen';
import { InputSearch } from '../../components/Form';
import NavbarMenu from '../../components/NavbarMenu';
import ContainerBox from '../../components/ContainerBox';
import ButtonGroupbox from '../../components/ContainerBox/ButtonGroupbox';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination';

import { Container, TotalItems } from './styles';

const Professions = () => {
  const formRef = useRef(null);

  const [page, setPage] = useState(1);
  const [url, setUrl] = useState('/professions?page=1');
  const [showProfessions, setShowProfessions] = useState(true);

  const { dataList: professions, loading, totalItems } = useFetch(
    `/professions?page=${page}`
  );

  const { dataList: searchProfessions, loading: loadingSearch } = useFetch(url);

  const { addToast } = useToast();

  const head = {
    id: 'ID',
    name: 'Nome',
  };

  const handleDelete = useCallback(
    async (id) => {
      try {
        if (
          window.confirm(`Tem Certeza que realmente deseja excluir o registro?`)
        ) {
          await api.delete(`/professions/${id}`);

          mutateGlobal(`/professions?page=${page}`);
          mutateGlobal(url);

          addToast({
            type: 'success',
            title: 'Cadastro Excluido com Sucesso',
          });
        } else {
          return;
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, page, url]
  );

  const handleSubmit = useCallback(() => {
    const input = formRef.current.getFieldValue('inputpro');

    if (!input) {
      setUrl('/professions?page=1');
      setPage(1);
      setShowProfessions(true);

      return;
    }

    setUrl(`/professionsearch?search=${input}`);
    setShowProfessions(false);
    formRef.current.reset();
  }, []);

  const handleEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Container>
      <NavbarMenu />
      <ContainerBox title="Profissão">
        <ButtonGroupbox>
          <Link to="/professions/newedit">
            <ButtonPrimaryGree icon={FiPlus}>Adicionar</ButtonPrimaryGree>
          </Link>

          <Form ref={formRef} onSubmit={() => {}}>
            <InputSearch
              id="inputpro"
              name="inputpro"
              autoComplete="off"
              onKeyDown={(e) => handleEnter(e)}
              handleClick={handleSubmit}
            />
          </Form>
        </ButtonGroupbox>

        {showProfessions && loading && <Loading />}

        {showProfessions && !loading && professions && (
          <>
            <Table
              head={head}
              data={professions}
              handleDelete={handleDelete}
              pathname="/professions/newedit"
            />
            {totalItems && (
              <TotalItems>
                <span>
                  Total de registro(s): <b>{totalItems}</b>
                </span>
              </TotalItems>
            )}
            {totalItems > 20 && (
              <Pagination
                setPage={setPage}
                currentPage={page}
                totalItems={totalItems}
              />
            )}
          </>
        )}

        {!showProfessions && loadingSearch && <Loading />}

        {!showProfessions && !loadingSearch && searchProfessions && (
          <Table
            head={head}
            data={searchProfessions}
            handleDelete={handleDelete}
            pathname="/professions/newedit"
          />
        )}
      </ContainerBox>
    </Container>
  );
};

export default Professions;
