import styled from 'styled-components';

export const TableHeader = styled.thead`
  background: #f2f2f2;

  th {
    font-weight: bold;
    text-align: center;
    padding: 14px 16px;
    border-bottom: 2px solid #d4d4d4;

    :nth-child(1) {
      width: 10%;
    }
    :nth-child(2) {
      width: 45%;
      text-align: left;
    }

    :last-child {
      max-width: 15%;
    }
  }
`;
