import React from 'react';
import { Link } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';

import { TableBodyItem, Actions } from './styles';

const Row = ({ item, handleDelete, pathname }) => {
  const keys = Object.keys(item);

  return (
    <TableBodyItem key={item.id}>
      {keys.map((key) => (
        <td key={key}>
          <Link to={{ pathname, state: { item } }}>{item[key]} </Link>
        </td>
      ))}
      <td>
        <Actions
          title="Excluir item"
          type="button"
          onClick={() => handleDelete(item.id)}
        >
          <FiTrash2 size={16} color="#e02041" />
        </Actions>
      </td>
    </TableBodyItem>
  );
};

export default Row;
