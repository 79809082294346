import React from 'react';
import { Link } from 'react-router-dom';
import { FiTrash2, FiDollarSign, FiRotateCcw } from 'react-icons/fi';

import { TableBodyItem, WrapperActions, Actions } from './styles';

const Row = ({
  item,
  handleDelete,
  handleLaunchDebit,
  handleReverseLaunchDebit,
  pathname,
}) => {
  const keys = Object.keys(item);

  return (
    <TableBodyItem
      key={item.id}
      past={item.past}
      paid={item.paid === 'Pago'}
      paidPartial={item.paid === 'Pago*'}
      opened={item.paid === 'Em Aberto'}
    >
      {keys.map((key) => (
        <td key={key} title={item[key].toString()}>
          <Link to={{ pathname, state: { item } }}>{item[key]}</Link>
        </td>
      ))}
      <td>
        <WrapperActions>
          {item.paid === 'Pago' || item.paid === 'Pago*' ? (
            <Actions
              title="Definir como não pago"
              type="button"
              onClick={() => handleReverseLaunchDebit(item)}
            >
              <FiRotateCcw size={18} color="#616161" />
            </Actions>
          ) : (
            <>
              <Actions
                title="Definir como pago"
                type="button"
                onClick={() => handleLaunchDebit(item.id)}
              >
                <FiDollarSign size={18} color="#f43e61" />
              </Actions>
              <Actions
                title="Excluir item"
                type="button"
                onClick={() => handleDelete(item.id)}
              >
                <FiTrash2 size={17} color="#616161" />
              </Actions>
            </>
          )}
        </WrapperActions>
      </td>
    </TableBodyItem>
  );
};

export default Row;
