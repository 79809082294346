import React, { useRef, useState, useCallback, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';

import { useField } from '@unform/core';

import { Container } from './styles';

const InputSearch = ({ name, placeholder, handleClick, ...rest }) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const { fieldName, defaultValue, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isFocused={isFocused}>
      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        name={name}
        placeholder={placeholder || 'Pesquisar'}
        {...rest}
      />
      <button type="button" onClick={handleClick}>
        <FiSearch size={18} />
      </button>
    </Container>
  );
};

export default InputSearch;
