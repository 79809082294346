/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { parse, parseISO } from 'date-fns';
import { mutate as mutateGlobal } from 'swr';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { FiCheck, FiX, FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { Form } from '@unform/web';

import Switch from 'react-switch';

import getValidationErrors from '../../../utils/getValidationErrors';
import validatorCpfCnpj from '../../../utils/validatorCPFCNPJ';
import {
  checkExistsCNPJ,
  checkExistsCPF,
} from '../../../utils/checkExistsCPFCNPJ';
import getCEP from '../../../utils/getCEP';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import { useFetch } from '../../../hooks/useFetch';

import {
  InputCNPJ,
  InputCPF,
  InputDate,
  InputPhone,
  InputCEP,
  Input,
  InputBRL,
  InputPercentage,
  CheckBox,
  Select,
  SelectAddFast,
  TextArea,
} from '../../../components/Form';

import NavBarMenu from '../../../components/NavbarMenu';
import ContainerBox from '../../../components/ContainerBox';
import ModalRegister from '../../../components/ModalRegister';
import Loading from '../../../components/Loading';

import ButtonSave from '../../../components/ButtonPrimaryBlue';
import ButtonCancel from '../../../components/ButtonCancel';

import {
  ButtonGroup,
  Wrapper,
  Container,
  GroupFields,
  GroupFieldsHead,
  SwitchGroup,
  Pj,
  Pf,
  PfEmployee,
  People,
  BankDetails,
  Hr,
  Accordion,
  ButtonAcordion,
} from './styles';

const PeopleNewEdit = () => {
  const { addToast } = useToast();
  const location = useLocation();
  const history = useHistory();
  const formRef = useRef(null);

  const { dataList: professions } = useFetch('/professionsearch?search=%');

  const [loadingSearchCNPJ, setLoadingSearchCNPJ] = useState(false);
  const [showPJ, setShowPJ] = useState(true);
  const [showPF, setShowPF] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const [showEmployee, setShowEmployee] = useState(false);
  const [checkClient, setCheckClient] = useState(false);
  const [checkProvider, setCheckProvider] = useState(false);
  const [checkEmployee, setCheckEmployee] = useState(false);
  const [checkShipping, setCheckShipping] = useState(false);
  const [checkStatus, setCheckStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(!!location.state);
  const [id, setId] = useState(null);

  useEffect(() => {
    if (!location.state) {
      return;
    }

    const fetchData = async () => {
      const { data } = await api.get(`/peoples/${location.state.item.id}`);
      setIsLoading(false);

      if (data.pj) {
        setShowPJ(true);
        setShowPF(false);
        setCheckStatus(data.active);

        const dataPJ = {
          legal_people: '1',
          client: data.client,
          provider: data.provider,
          employee: data.employee,
          shipping_company: data.shipping_company,
          zip_code: data.zip_code,
          address: data.address,
          number: data.number,
          complement: data.complement,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
          email: data.email,
          phone: data.phone,
          cell: data.cell,
          comments: data.comments,
          bank: data.bank,
          agency: data.agency,
          current_account: data.current_account,
          active: data.active,
          cnpj: data.pj.cnpj,
          company_name: data.pj.company_name,
          fantasy_name: data.pj.fantasy_name,
          contact: data.pj.contact,
          contact_cell: data.pj.contact_cell,
          situation: data.pj.situation,
          opening_date:
            data.pj.opening_date &&
            Intl.DateTimeFormat('pt-br').format(parseISO(data.pj.opening_date)),
          state_registration: data.pj.state_registration,
          municipal_registration: data.pj.municipal_registration,
        };

        formRef.current.setData(dataPJ);
      }

      if (data.pf) {
        setShowPJ(false);
        setShowPF(true);
        setCheckClient(data.pf.client);
        setCheckProvider(data.pf.provider);
        setCheckEmployee(data.pf.employee);
        setCheckShipping(data.pf.shipping_company);
        setCheckStatus(data.active);

        const dataPF = {
          legal_people: '0',
          client: data.client,
          provider: data.provider,
          employee: data.employee,
          shipping_company: data.shipping_company,
          zip_code: data.zip_code,
          address: data.address,
          number: data.number,
          complement: data.complement,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
          email: data.email,
          phone: data.phone,
          cell: data.cell,
          comments: data.comments,
          bank: data.bank,
          agency: data.agency,
          current_account: data.current_account,
          active: data.active,
          profession_id: String(data.pf.profession_id),
          name: data.pf.name,
          cpf: data.pf.cpf,
          rg: data.pf.rg,
          rg_complement: data.pf.rg_complement,
          cnh_record: data.pf.cnh_record,
          cnh_category: data.pf.cnh_category,
          cnh_validity:
            data.pf.cnh_validity &&
            Intl.DateTimeFormat('pt-br').format(parseISO(data.pf.cnh_validity)),
          work_card: data.pf.work_card,
          series: data.pf.series,
          pis: data.pf.pis,
          volter_title: data.pf.volter_title,
          zone: data.pf.zone,
          section: data.pf.section,
          dad: data.pf.dad,
          mother: data.pf.mother,
          spouse: data.pf.spouse,
          sex: data.pf.sex,
          marital_status: data.pf.marital_status,
          birth_date:
            data.pf.birth_date &&
            Intl.DateTimeFormat('pt-br').format(parseISO(data.pf.birth_date)),
          admission_date:
            data.pf.admission_date &&
            Intl.DateTimeFormat('pt-br').format(
              parseISO(data.pf.admission_date)
            ),
          resignation_date:
            data.pf.resignation_date &&
            Intl.DateTimeFormat('pt-br').format(
              parseISO(data.pf.resignation_date)
            ),
          salary: data.pf.salary && String(data.pf.salary).replace('.', ','),
          commissioned: data.pf.commissioned ? '1' : '0',
          commission:
            data.pf.commission && String(data.pf.commission).replace('.', ','),
          payment_type: data.pf.payment_type,
        };

        formRef.current.setData(dataPF);
      }
    };

    fetchData();

    setId(location.state.item.id);
  }, [location.state]);

  const handleSubmit = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          cnpj: Yup.string().when('legal_people', {
            is: (val) => val === '1',
            then: Yup.string()
              .test('testCnpj', 'Número de CNPJ inválido', (value) =>
                validatorCpfCnpj(value)
              )
              .test('testExistsCnpj', 'Esse CNPJ já foi Cadastrado', (value) =>
                checkExistsCNPJ(value)
              ),
            otherwise: Yup.string(),
          }),
          fantasy_name: Yup.string().when('legal_people', {
            is: (val) => val === '1',
            then: Yup.string().required('Nome fantasia é obrigatório'),
            otherwise: Yup.string(),
          }),
          company_name: Yup.string().when('legal_people', {
            is: (val) => val === '1',
            then: Yup.string(),
            otherwise: Yup.string(),
          }),
          name: Yup.string().when('legal_people', {
            is: (val) => val === '0',
            then: Yup.string().required('Nome é obrigatório'),
            otherwise: Yup.string(),
          }),
          cpf: Yup.string().when('legal_people', {
            is: (val) => val === '0',
            then: Yup.string()
              .test('testCpf', 'Número de CPF inválido', (value) =>
                validatorCpfCnpj(value)
              )
              .test('testExistsCpf', 'Esse CPF já foi Cadastrado', (value) =>
                checkExistsCPF(value)
              ),
            otherwise: Yup.string(),
          }),
          sex: Yup.string().when('legal_people', {
            is: (val) => val === '0',
            then: Yup.string(),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        if (
          !dataForm.client &&
          !dataForm.provider &&
          !dataForm.employee &&
          !dataForm.shipping_company
        ) {
          addToast({
            type: 'error',
            title: 'Marque pelo menos um o Papel da pessoa',
          });
          return;
        }

        const dataPeople = {
          legal_people: !!parseInt(dataForm.legal_people, 10),
          client: dataForm.client,
          provider: dataForm.provider,
          employee: dataForm.employee,
          shipping_company: dataForm.shipping_company,
          zip_code: dataForm.zip_code,
          address: dataForm.address,
          number: dataForm.number,
          complement: dataForm.complement,
          neighborhood: dataForm.neighborhood,
          city: dataForm.city,
          state: dataForm.state,
          email: dataForm.email,
          phone: dataForm.phone,
          cell: dataForm.cell,
          comments: dataForm.comments,
          bank: dataForm.bank,
          agency: dataForm.agency,
          current_account: dataForm.current_account,
          active: checkStatus,
        };

        if (dataPeople.legal_people) {
          const dataPJ = {
            cnpj: dataForm.cnpj,
            company_name: dataForm.company_name,
            fantasy_name: dataForm.fantasy_name,
            contact: dataForm.contact,
            contact_cell: dataForm.contact_cell,
            situation: dataForm.situation,
            opening_date: parse(
              dataForm.opening_date,
              'dd/MM/yyyy',
              new Date()
            ),
            state_registration: dataForm.state_registration,
            municipal_registration: dataForm.municipal_registration,
          };

          const response = await api.post('peoples', dataPeople);

          const idPeople = response.data.id;

          await api.post(`/peoples/${idPeople}/legalpeoples`, dataPJ);
        }

        if (!dataPeople.legal_people) {
          let salaryString = formRef.current.getFieldValue('salary');
          let comissionString = formRef.current.getFieldValue('commission');

          salaryString = salaryString.replace('R$ ', '');
          salaryString = salaryString.replace(/\./g, '');
          salaryString = salaryString.replace(',', '.');
          const salaryDecimal = parseFloat(salaryString).toFixed(2);

          comissionString = comissionString.replace(' %', '');
          comissionString = comissionString.replace(/\./g, '');
          comissionString = comissionString.replace(',', '.');
          const comissionDecimal = parseFloat(comissionString).toFixed(2);

          const dataPF = {
            profession_id: parseInt(dataForm.profession_id, 10),
            name: dataForm.name,
            cpf: dataForm.cpf,
            rg: dataForm.rg,
            rg_complement: dataForm.rg_complement,
            cnh_record: dataForm.cnh_record,
            cnh_category: dataForm.cnh_category,
            cnh_validity: parse(
              dataForm.cnh_validity,
              'dd/MM/yyyy',
              new Date()
            ),
            work_card: dataForm.work_card,
            series: dataForm.series,
            pis: dataForm.pis,
            volter_title: dataForm.volter_title,
            zone: dataForm.zone,
            section: dataForm.section,
            dad: dataForm.dad,
            mother: dataForm.mother,
            spouse: dataForm.spouse,
            sex: dataForm.sex,
            marital_status: dataForm.marital_status,
            birth_date: parse(dataForm.birth_date, 'dd/MM/yyyy', new Date()),
            admission_date: parse(
              dataForm.admission_date,
              'dd/MM/yyyy',
              new Date()
            ),
            resignation_date: parse(
              dataForm.resignation_date,
              'dd/MM/yyyy',
              new Date()
            ),
            salary: dataForm.salary ? salaryDecimal : null,
            commissioned: dataForm.commissioned === '1',
            commission: dataForm.commission ? comissionDecimal : null,
            payment_type: dataForm.payment_type,
          };

          const response = await api.post('peoples', dataPeople);
          const idPeople = response.data.id;

          await api.post(`/peoples/${idPeople}/fisicalpeoples`, dataPF);
        }

        formRef.current.reset();
        formRef.current.setFieldValue('legal_people', '1');
        formRef.current.setFieldValue('client', true);

        mutateGlobal('/peoples?page=1');

        addToast({
          type: 'success',
          title: 'Cadastro efetuado com Sucesso',
        });

        history.push('/peoples');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erros nos Campos',
            description: 'Verifique os Campos em vermelho!',
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique os dados.',
        });
      }
    },
    [addToast, checkStatus, history]
  );

  const handleUpdate = useCallback(
    async (dataForm) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          cnpj: Yup.string().when('legal_people', {
            is: (val) => val === '1',
            then: Yup.string().test(
              'testCnpj',
              'Número de CNPJ inválido',
              (value) => validatorCpfCnpj(value)
            ),
            otherwise: Yup.string(),
          }),
          fantasy_name: Yup.string().when('legal_people', {
            is: (val) => val === '1',
            then: Yup.string().required('Nome fantasia é obrigatório'),
            otherwise: Yup.string(),
          }),
          company_name: Yup.string().when('legal_people', {
            is: (val) => val === '1',
            then: Yup.string(),
            otherwise: Yup.string(),
          }),
          name: Yup.string().when('legal_people', {
            is: (val) => val === '0',
            then: Yup.string().required('Nome é obrigatório'),
            otherwise: Yup.string(),
          }),
          cpf: Yup.string().when('legal_people', {
            is: (val) => val === '0',
            then: Yup.string().test(
              'testCpf',
              'Número de CPF inválido',
              (value) => validatorCpfCnpj(value)
            ),
            otherwise: Yup.string(),
          }),
          sex: Yup.string().when('legal_people', {
            is: (val) => val === '0',
            then: Yup.string(),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        if (
          !dataForm.client &&
          !dataForm.provider &&
          !dataForm.employee &&
          !dataForm.shipping_company
        ) {
          addToast({
            type: 'error',
            title: 'Marque pelo menos um o Papel da pessoa',
          });
          return;
        }

        const dataPeople = {
          legal_people: !!parseInt(dataForm.legal_people, 10),
          client: dataForm.client,
          provider: dataForm.provider,
          employee: dataForm.employee,
          shipping_company: dataForm.shipping_company,
          zip_code: dataForm.zip_code,
          address: dataForm.address,
          number: dataForm.number,
          complement: dataForm.complement,
          neighborhood: dataForm.neighborhood,
          city: dataForm.city,
          state: dataForm.state,
          email: dataForm.email,
          phone: dataForm.phone,
          cell: dataForm.cell,
          comments: dataForm.comments,
          bank: dataForm.bank,
          agency: dataForm.agency,
          current_account: dataForm.current_account,
          active: checkStatus,
        };

        if (dataPeople.legal_people) {
          const dataPJ = {
            cnpj: dataForm.cnpj,
            company_name: dataForm.company_name,
            fantasy_name: dataForm.fantasy_name,
            contact: dataForm.contact,
            contact_cell: dataForm.contact_cell,
            situation: dataForm.situation,
            opening_date: parse(
              dataForm.opening_date,
              'dd/MM/yyyy',
              new Date()
            ),
            state_registration: dataForm.state_registration,
            municipal_registration: dataForm.municipal_registration,
          };

          await api.patch(`/peoples/${id}`, dataPeople);
          await api.patch(`/peoples/${id}/legalpeoples`, dataPJ);
        }

        if (!dataPeople.legal_people) {
          let salaryString = formRef.current.getFieldValue('salary');
          let comissionString = formRef.current.getFieldValue('commission');

          salaryString = salaryString.replace('R$ ', '');
          salaryString = salaryString.replace(/\./g, '');
          salaryString = salaryString.replace(',', '.');
          const salaryDecimal = parseFloat(salaryString).toFixed(2);

          comissionString = comissionString.replace(' %', '');
          comissionString = comissionString.replace(/\./g, '');
          comissionString = comissionString.replace(',', '.');
          const comissionDecimal = parseFloat(comissionString).toFixed(2);

          const dataPF = {
            profession_id: parseInt(dataForm.profession_id, 10),
            name: dataForm.name,
            cpf: dataForm.cpf,
            rg: dataForm.rg,
            rg_complement: dataForm.rg_complement,
            cnh_record: dataForm.cnh_record,
            cnh_category: dataForm.cnh_category,
            cnh_validity: parse(
              dataForm.cnh_validity,
              'dd/MM/yyyy',
              new Date()
            ),
            work_card: dataForm.work_card,
            series: dataForm.series,
            pis: dataForm.pis,
            volter_title: dataForm.volter_title,
            zone: dataForm.zone,
            section: dataForm.section,
            dad: dataForm.dad,
            mother: dataForm.mother,
            spouse: dataForm.spouse,
            sex: dataForm.sex,
            marital_status: dataForm.marital_status,
            birth_date: parse(dataForm.birth_date, 'dd/MM/yyyy', new Date()),
            admission_date: parse(
              dataForm.admission_date,
              'dd/MM/yyyy',
              new Date()
            ),
            resignation_date: parse(
              dataForm.resignation_date,
              'dd/MM/yyyy',
              new Date()
            ),
            salary: dataForm.salary ? salaryDecimal : null,
            commissioned: dataForm.commissioned === '1',
            commission: dataForm.commission ? comissionDecimal : null,
            payment_type: dataForm.payment_type,
          };

          const response = await api.patch(`/peoples/${id}`, dataPeople);
          const idPeople = response.data.id;

          await api.patch(`/peoples/${idPeople}/fisicalpeoples`, dataPF);
        }

        formRef.current.reset();
        formRef.current.setFieldValue('legal_people', '1');
        formRef.current.setFieldValue('client', true);

        mutateGlobal('/peoples');

        addToast({
          type: 'success',
          title: 'Cadastro alterado com Sucesso',
        });

        history.push('/peoples');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erros nos Campos',
            description: 'Verifique os Campos em vermelho!',
          });
          return;
        }

        if (err.response.status === 403) {
          if (formRef.current.getFieldValue('legal_people') === '1') {
            addToast({
              type: 'error',
              title: 'Erro CNPJ',
              description: 'CNPJ já cadastrado',
            });
          } else {
            addToast({
              type: 'error',
              title: 'Erro CPF',
              description: 'CPF já cadastrado',
            });
          }
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Erro ao tentar alterar',
        });
      }
    },
    [addToast, checkStatus, history, id]
  );

  const handleSearchCNPJ = useCallback(async () => {
    let cnpj = formRef.current.getFieldValue('cnpj');

    if (!cnpj) {
      return;
    }

    cnpj = cnpj.replace(/\./g, '');
    cnpj = cnpj.replace('-', '');
    cnpj = cnpj.replace('/', '');

    setLoadingSearchCNPJ(true);

    const response = await api.get(`searchcnpj/${cnpj}`);

    const {
      status,
      message,
      fantasia,
      nome,
      situacao,
      abertura,
      cep,
      logradouro,
      numero,
      bairro,
      complemento,
      municipio,
      uf,
    } = response.data;

    if (status === 'ERROR') {
      addToast({
        type: 'error',
        title: 'Erro',
        description: message,
      });
    }

    if (status === 'OK') {
      formRef.current.setFieldValue('fantasy_name', fantasia || '');
      formRef.current.setFieldValue('company_name', nome || '');
      formRef.current.setFieldValue('situation', situacao || '');
      formRef.current.setFieldValue('opening_date', abertura || '');
      formRef.current.setFieldValue('zip_code', cep || '');
      formRef.current.setFieldValue('address', logradouro || '');
      formRef.current.setFieldValue('number', numero || '');
      formRef.current.setFieldValue('neighborhood', bairro || '');
      formRef.current.setFieldValue('complement', complemento || '');
      formRef.current.setFieldValue('city', municipio || '');
      formRef.current.setFieldValue('state', uf || '');
    }

    setLoadingSearchCNPJ(false);
  }, [addToast]);

  const handleGetCEP = useCallback(async () => {
    formRef.current.setFieldValue('city', '');
    formRef.current.setFieldValue('state', '');
    formRef.current.setFieldValue('neighborhood', '');
    formRef.current.setFieldValue('address', '');
    formRef.current.setFieldValue('number', '');
    formRef.current.setFieldValue('complement', '');

    let cep = formRef.current.getFieldValue('zip_code');

    if (!cep) {
      return;
    }

    cep = cep.replace(/\./g, '');
    cep = cep.replace('-', '');

    const cepNumber = parseInt(cep, 10);

    const resultCEP = await getCEP(cepNumber);

    if (resultCEP === 404) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'CEP não encontrado!',
      });
      return;
    }

    const { state, city, neighborhood, street } = resultCEP;

    formRef.current.setFieldValue('city', city || '');
    formRef.current.setFieldValue('state', state || '');
    formRef.current.setFieldValue('neighborhood', neighborhood || '');
    formRef.current.setFieldValue('address', street || '');
  }, [addToast]);

  const handleTypePeople = useCallback((legalPeople) => {
    if (legalPeople === '1') {
      setShowPJ(true);
      setShowPF(false);
      formRef.current.setFieldValue('employee', false);
      setCheckEmployee(false);
    }

    if (legalPeople === '0') {
      setShowPJ(false);
      setShowPF(true);
    }
  }, []);

  const handleCheckBoxClient = useCallback((e) => {
    if (e.target.checked) {
      setCheckClient(true);
    } else {
      setCheckClient(false);
    }
  }, []);
  const handleCheckBoxProvider = useCallback((e) => {
    if (e.target.checked) {
      setCheckProvider(true);
    } else {
      setCheckProvider(false);
    }
  }, []);
  const handleCheckBoxEmployee = useCallback((e) => {
    if (e.target.checked) {
      setCheckEmployee(true);
      setShowEmployee(true);
    } else {
      setCheckEmployee(false);
      setShowEmployee(false);
    }
  }, []);
  const handleCheckBoxShipping = useCallback((e) => {
    if (e.target.checked) {
      setCheckShipping(true);
    } else {
      setCheckShipping(false);
    }
  }, []);
  const handleEnter = useCallback((e) => {
    if (e.keyCode === 13) e.preventDefault();
  }, []);

  return (
    <Container>
      {showModalRegister && (
        <ModalRegister
          title="Cadastro de Profissão"
          setShowModalRegister={setShowModalRegister}
          label="Profissão"
          name="name"
          urlPost="/professions"
          urlGet="/professionsearch?search=%"
        />
      )}
      <NavBarMenu />

      {isLoading ? (
        <Loading />
      ) : (
        <ContainerBox
          title={location.state ? 'Alterar Pessoa' : 'Cadastro de Pessoa'}
        >
          <Wrapper>
            <Form
              ref={formRef}
              onSubmit={location.state ? handleUpdate : handleSubmit}
              onKeyDown={(e) => handleEnter(e)}
            >
              <GroupFieldsHead>
                <Select
                  label="Tipo Pessoa"
                  name="legal_people"
                  id="legal_people"
                  onChange={(e) => handleTypePeople(e.target.value)}
                  defaultValue="1"
                  className={location.state && 'noactiveselect'}
                  disabled={location.state}
                >
                  <option value={1}>Jurídica</option>
                  <option value={0}>Física</option>
                </Select>

                <article>
                  <div>
                    <span>Papel da pessoa*</span>
                    <CheckBox
                      label="Cliente"
                      name="client"
                      id="client"
                      onChange={(e) => handleCheckBoxClient(e)}
                    />
                    <CheckBox
                      label="Fornecedor"
                      name="provider"
                      id="provider"
                      onChange={(e) => handleCheckBoxProvider(e)}
                    />
                    <CheckBox
                      label="Colaborador"
                      name="employee"
                      id="employee"
                      className={showPJ ? 'noactive' : ''}
                      onChange={(e) => handleCheckBoxEmployee(e)}
                    />
                    <CheckBox
                      label="Transportadora"
                      name="shipping_company"
                      id="shipping_company"
                      onChange={(e) => handleCheckBoxShipping(e)}
                    />
                  </div>
                </article>

                <SwitchGroup checkStatus={checkStatus}>
                  <label htmlFor="active">Status Pessoa</label>
                  <Switch
                    name="active"
                    id="active"
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={28}
                    uncheckedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={96}
                    onChange={() => setCheckStatus(!checkStatus)}
                    checked={checkStatus}
                  />
                  <span>{checkStatus ? 'Ativo' : 'Inativo'}</span>
                </SwitchGroup>
              </GroupFieldsHead>

              <Pj showPJ={showPJ}>
                <h4>
                  Dados Pessoa Jurídica:
                  {checkClient && (
                    <>
                      <span>Cliente</span>
                    </>
                  )}
                  {checkProvider && (
                    <>
                      <span>Fornecedor</span>
                    </>
                  )}
                  {checkEmployee && (
                    <>
                      <span>Colaborador</span>
                    </>
                  )}
                  {checkShipping && (
                    <>
                      <span>Transportadora</span>
                    </>
                  )}
                </h4>

                <GroupFields>
                  <InputCNPJ
                    id="cnpj"
                    name="cnpj"
                    label="CNPJ"
                    handleOnClick={handleSearchCNPJ}
                    loading={loadingSearchCNPJ}
                    autoComplete="off"
                  />

                  <Input
                    id="fantasy_name"
                    name="fantasy_name"
                    label="Nome Fantasia*"
                    autoComplete="off"
                  />
                  <Input
                    id="company_name"
                    name="company_name"
                    label="Razão Social"
                    autoComplete="off"
                  />
                </GroupFields>
                <GroupFields>
                  <Input
                    id="state_registration"
                    name="state_registration"
                    label="Inscrição Estadual"
                    autoComplete="off"
                  />

                  <Input
                    id="municipal_registration"
                    name="municipal_registration"
                    label="Inscrição Municipal"
                    autoComplete="off"
                  />

                  <Input
                    id="situation"
                    name="situation"
                    label="Situação da empresa"
                    autoComplete="off"
                  />
                </GroupFields>
                <GroupFields>
                  <Input
                    id="contact"
                    name="contact"
                    label="Nome do Contato"
                    autoComplete="off"
                  />

                  <InputPhone
                    id="contact_cell"
                    name="contact_cell"
                    label="Telefone do contato"
                    autoComplete="off"
                  />
                  <InputDate
                    id="opening_date"
                    name="opening_date"
                    label="Data de abertura da empresa"
                    autoComplete="off"
                  />
                </GroupFields>

                <Hr />
              </Pj>

              <Pf showPF={showPF}>
                <h4>
                  Dados Pessoa Física:
                  {checkClient && (
                    <>
                      <span>Cliente</span>
                    </>
                  )}
                  {checkProvider && (
                    <>
                      <span>Fornecedor</span>
                    </>
                  )}
                  {checkEmployee && (
                    <>
                      <span>Colaborador</span>
                    </>
                  )}
                  {checkShipping && (
                    <>
                      <span>Transportadora</span>
                    </>
                  )}
                </h4>

                <GroupFields>
                  <Input
                    id="name"
                    name="name"
                    label="Nome*"
                    autoComplete="off"
                  />

                  <InputCPF
                    id="cpf"
                    name="cpf"
                    label="CPF"
                    autoComplete="off"
                  />

                  <Select name="sex" id="sex" label="Sexo">
                    <option value="">Selecione o sexo</option>
                    <option value="m">Masculino</option>
                    <option value="f">Feminino</option>
                  </Select>
                </GroupFields>
                <GroupFields>
                  <InputDate
                    id="birth_date"
                    name="birth_date"
                    label="Data Nascimento"
                    autoComplete="off"
                  />
                  <Input id="rg" name="rg" label="RG" autoComplete="off" />
                  <Input
                    id="rg_complement"
                    name="rg_complement"
                    label="Complemento do RG"
                    autoComplete="off"
                  />
                </GroupFields>

                <Hr />
              </Pf>

              <People>
                <GroupFields>
                  <Input
                    id="email"
                    name="email"
                    label="E-mail"
                    type="email"
                    autoComplete="off"
                  />
                  <InputPhone
                    id="phone"
                    name="phone"
                    label="Telefone"
                    autoComplete="off"
                  />
                  <InputPhone
                    id="cell"
                    name="cell"
                    label="Celular"
                    autoComplete="off"
                  />
                </GroupFields>
                <GroupFields>
                  <InputCEP
                    id="zip_code"
                    name="zip_code"
                    label="CEP"
                    handleOnClick={handleGetCEP}
                    autoComplete="off"
                  />
                  <Input
                    id="address"
                    name="address"
                    label="Endereço"
                    placeholder="Ex: Avenida Brasil"
                    autoComplete="off"
                  />
                  <Input
                    id="number"
                    name="number"
                    label="Número"
                    autoComplete="off"
                  />
                </GroupFields>
                <GroupFields>
                  <Input
                    id="neighborhood"
                    name="neighborhood"
                    label="Bairro"
                    autoComplete="off"
                  />
                  <Input
                    id="complement"
                    name="complement"
                    label="Complemento"
                    autoComplete="off"
                  />
                  <Input
                    id="city"
                    name="city"
                    label="Cidade"
                    autoComplete="off"
                  />
                </GroupFields>
                <GroupFields>
                  <Select id="state" name="state" label="Estado">
                    <option value="">Selecione o Estado</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </Select>
                </GroupFields>

                <TextArea name="comments" id="comments" label="Observações" />
              </People>

              {showPF && (
                <Accordion showBank={showBank} showEmployee={showEmployee}>
                  <ButtonAcordion
                    onClick={() => setShowEmployee((state) => !state)}
                    type="button"
                  >
                    {!showEmployee ? <FiChevronDown /> : <FiChevronUp />}
                    Dados Complementares Colaborador
                  </ButtonAcordion>
                  <PfEmployee showPF={showPF} showEmployee={showEmployee}>
                    <GroupFields>
                      <Input
                        id="cnh_record"
                        name="cnh_record"
                        label="CNH"
                        autoComplete="off"
                      />

                      <Select
                        name="cnh_category"
                        id="cnh_category"
                        label="Categoria CNH"
                      >
                        <option value="">Selecione a Categoria</option>
                        <option value="a">A</option>
                        <option value="b">B</option>
                        <option value="c">C</option>
                        <option value="d">D</option>
                        <option value="e">E</option>
                        <option value="ab">AB</option>
                        <option value="ac">AC</option>
                        <option value="ad">AD</option>
                        <option value="ae">AE</option>
                        <option value="acc">ACC</option>
                      </Select>

                      <InputDate
                        id="cnh_validity"
                        name="cnh_validity"
                        label="Validade CNH"
                        autoComplete="off"
                      />
                    </GroupFields>
                    <GroupFields>
                      <Input
                        id="work_card"
                        name="work_card"
                        label="Carteira de Trabalho"
                        autoComplete="off"
                      />
                      <Input
                        id="series"
                        name="series"
                        label="Série da  carteira de Trabalho"
                        autoComplete="off"
                      />
                      <Input
                        id="pis"
                        name="pis"
                        label="PIS"
                        autoComplete="off"
                      />
                    </GroupFields>
                    <GroupFields>
                      <Input
                        id="volter_title"
                        name="volter_title"
                        label="Título de Eleitor"
                        autoComplete="off"
                      />
                      <Input
                        id="zone"
                        name="zone"
                        label="Zona Eleitoral"
                        autoComplete="off"
                      />
                      <Input
                        id="section"
                        name="section"
                        label="Seção Eleitoral"
                        autoComplete="off"
                      />
                    </GroupFields>
                    <GroupFields>
                      <Input
                        id="dad"
                        name="dad"
                        label="Nome do Pai"
                        autoComplete="off"
                      />
                      <Input
                        id="mother"
                        name="mother"
                        label="Nome da Mãe"
                        autoComplete="off"
                      />
                      <Input
                        id="spouse"
                        name="spouse"
                        label="Cônjuge"
                        autoComplete="off"
                      />
                    </GroupFields>
                    <GroupFields>
                      <Select
                        name="marital_status"
                        id="marital_status"
                        label="Estado Civil"
                      >
                        <option value="">Selecione o Estado Civil</option>
                        <option value="solteiro">Solteiro(a)</option>
                        <option value="casado">Casado(a)</option>
                        <option value="divorciado">Divorciado(a)</option>
                        <option value="viuvo">Viúvo(a)</option>
                        <option value="separado">Separado(a)</option>
                        <option value="uniaoestavel">União estável</option>
                      </Select>

                      <SelectAddFast
                        name="profession_id"
                        id="profession_id"
                        label="Profissão"
                        handleAddFast={() => setShowModalRegister(true)}
                      >
                        <option value="">Selecione a Profissão</option>
                        {professions &&
                          professions.map((p) => (
                            <option key={p.id} value={p.id}>
                              {p.name}
                            </option>
                          ))}
                      </SelectAddFast>

                      <InputBRL
                        id="salary"
                        name="salary"
                        label="Proventos/Salário"
                        autoComplete="off"
                      />
                    </GroupFields>
                    <GroupFields>
                      <Select
                        name="payment_type"
                        id="payment_type"
                        label="Tipo Pagamento"
                      >
                        <option value="">Selecione Forma Pagamento</option>
                        <option value="mensal">Mensal</option>
                        <option value="diaria">Diária</option>
                        <option value="quinzenal">Quinzenal</option>
                        <option value="empreita">Empreitada</option>
                      </Select>
                      <Select
                        name="commissioned"
                        id="commissioned"
                        label="Comissionado?"
                      >
                        <option value="">Selecione se é comissionado</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </Select>

                      <InputPercentage
                        id="commission"
                        name="commission"
                        label="Comissão em %"
                        autoComplete="off"
                      />
                    </GroupFields>
                    <GroupFields>
                      <InputDate
                        id="admission_date"
                        name="admission_date"
                        label="Data de Admissão"
                        autoComplete="off"
                      />
                      <InputDate
                        id="resignation_date"
                        name="resignation_date"
                        label="Data de Demissão"
                        autoComplete="off"
                      />
                    </GroupFields>
                  </PfEmployee>
                </Accordion>
              )}

              <Accordion showBank={showBank}>
                <ButtonAcordion
                  onClick={() => setShowBank((state) => !state)}
                  type="button"
                >
                  {!showBank ? <FiChevronDown /> : <FiChevronUp />} Dados
                  Bancários
                </ButtonAcordion>
                <BankDetails showBank={showBank}>
                  <GroupFields>
                    <Input
                      id="bank"
                      name="bank"
                      label="Banco"
                      autoComplete="off"
                    />
                    <Input
                      id="agency"
                      name="agency"
                      label="Agencia"
                      autoComplete="off"
                    />
                    <Input
                      id="current_account"
                      name="current_account"
                      label="Conta"
                      autoComplete="off"
                    />
                  </GroupFields>
                </BankDetails>
              </Accordion>
              <Hr style={{ marginTop: 64 }} />

              <ButtonGroup>
                <ButtonSave icon={FiCheck}>Salvar</ButtonSave>
                <Link to="/peoples">
                  <ButtonCancel icon={FiX}>Cancelar</ButtonCancel>
                </Link>
              </ButtonGroup>
            </Form>
          </Wrapper>
        </ContainerBox>
      )}
    </Container>
  );
};

export default PeopleNewEdit;
