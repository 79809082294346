import styled from 'styled-components';

export const TableHeader = styled.thead`
  border-radius: 4px;

  th {
    font-weight: bold;
    text-align: left;
    padding: 16px 10px 6px 0;
    border-bottom: 1px dotted #c9d3dd;

    :nth-child(1) {
      text-align: left;
    }
    :nth-child(2) {
      text-align: left;
    }

    :nth-child(3) {
      text-align: right;
    }
    :nth-child(4) {
      text-align: right;
    }
    :nth-child(5) {
      text-align: right;
    }
    :nth-child(6) {
      display: none;
    }
  }
`;
