import styled from 'styled-components';

export const TableList = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 24px;

  border: 1px solid #d4d4d4;
  border-radius: 4px 4px 0 4px;
`;

export const TableBody = styled.tbody``;
