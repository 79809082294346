import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Section, Container, Content, Navigation, NavItem } from './styles';

import logo from '../../assets/logoBranco.png';

const NavbarMenu = () => {
  const [isScroll, setIsScroll] = useState(false);

  const scrollNavbar = useCallback(() => {
    if (window.scrollY >= 1) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollNavbar);

    return function cleanup() {
      window.removeEventListener('scroll', scrollNavbar);
    };
  }, [scrollNavbar]);

  const vendas = [
    {
      link: '/sales',
      label: 'Vendas e Pedidos',
    },
  ];
  const cadastros = [
    { link: '/peoples', label: 'Pessoas: Clientes, Fornecedores...' },
    { link: '/brands', label: 'Marcas' },
    { link: '/professions', label: 'Profissões' },
    {
      link: '/dregroups',
      label: 'Grupos DRE',
    },
    {
      link: '/accountplans',
      label: 'Plano Categorias de Contas',
    },
    {
      link: '/coastcenters',
      label: 'Centro de Custos',
    },
    {
      link: '/classifications',
      label: 'Tipo - Classificação de ovos',
    },
    {
      link: '/categories',
      label: 'Categorias de Produto, Insumos...',
    },
    {
      link: '/units',
      label: 'Unidades',
    },
    {
      link: '/products',
      label: 'Produtos',
    },
  ];
  const financeiro = [
    {
      link: '/accounts',
      label: 'Contas',
    },
    {
      link: '/billstopays',
      label: 'Contas a Pagar',
    },
    {
      link: '/billstoreceives',
      label: 'Contas a Receber',
    },
  ];

  return (
    <Section>
      <Container className={isScroll ? 'scrolling' : ''}>
        <Content>
          <Link to="/">
            <img src={logo} alt="Zamp tecnologia" />
          </Link>

          <Navigation>
            <span>
              <NavItem>
                <Link to="/">Visão geral</Link>
              </NavItem>
            </span>

            <NavItem>
              <span> Vendas</span>
              <ul>
                {vendas.map((item) => (
                  <li key={item.label}>
                    <Link to={item.link}>{item.label}</Link>
                  </li>
                ))}
              </ul>
            </NavItem>
            <NavItem>
              <span> Cadastros</span>

              <ul>
                {cadastros.map((item) => (
                  <li key={item.label}>
                    <Link to={item.link}>{item.label}</Link>
                  </li>
                ))}
              </ul>
            </NavItem>
            <NavItem>
              <span>Financeiro</span>
              <ul>
                {financeiro.map((item) => (
                  <li key={item.label}>
                    <Link to={item.link}>{item.label}</Link>
                  </li>
                ))}
              </ul>
            </NavItem>
          </Navigation>
        </Content>
      </Container>
    </Section>
  );
};

export default NavbarMenu;
