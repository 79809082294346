import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-text-mask';

import { Container } from './styles';

const InputDate = ({ id, name, placeholder, label, ...rest }) => {
  const mask = [
    /[0-3]/,
    /[0-9]/,
    '/',
    /[0-1]/,
    /[0-9]/,
    '/',
    /[1-2]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current.inputElement.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current.inputElement,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      <label {...rest} htmlFor={id}>
        {label}
      </label>

      <InputMask
        guide
        mask={mask}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        name={name}
        id={id}
        placeholder={placeholder}
        {...rest}
      />

      <small>{error}</small>
    </Container>
  );
};

export default InputDate;
