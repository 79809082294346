import styled from 'styled-components';

export const Container = styled.div``;

export const TotalItems = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    border-radius: 0 0 4px 4px;

    padding: 8px 16px;
    background: #f2f2f2;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div:first-child {
    margin-right: 16px;
  }
`;

export const Previews = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
`;
export const PreviewsItem = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  border: solid 1px #e5eaed;

  span {
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    color: ${(props) => props.color};
  }
`;
