import React, { useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FiPrinter, FiEdit2 } from 'react-icons/fi';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { mutate as mutateGlobal } from 'swr';
import logoGranja from '../../../assets/logogranja.png';
import canceled from '../../../assets/canceled.png';
import confirmed from '../../../assets/confirmed.png';
import draft from '../../../assets/draft.png';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { useFetchSaleView } from '../hooks/useFetchSaleView';

import ButtonGohst from '../../../components/ButtonGhostDefault';

import NavbarMenu from '../../../components/NavbarMenu';
import ContainerBox from './ContainerBox';

import Table from './Table';
import TableReceives from './TableReceives';
import Loading from '../../../components/Loading';

import {
  Container,
  WrapperOffSet,
  WrapperButton,
  HeaderSale,
  Col1,
  FlexRow,
  Total,
  SubTotal,
  SubTotal2,
  TotalLiq,
  Obs,
  ContainerBills,
  HeaderBills,
  LabelBills,
  ItemBills,
} from './styles';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const SaleView = () => {
  const location = useLocation();

  const sale_id = location.state.item
    ? location.state.item.sale_id
    : location.state.sale_id;

  const { dataList: dataSale, loading } = useFetchSaleView(`/sales/${sale_id}`);

  const { addToast } = useToast();
  const { user } = useAuth();

  const headReceives = {
    parcel: 'Parcela',
    maturity_date: 'Data',
    amount: 'Valor',
    received_amount: 'Recebido',
    received_date: 'Data de recebimento',
    id: 'ID',
    past: ' ',
    received: ' ',
    comments: ' ',
  };
  const head = {
    product_id: 'ID',
    product: 'Produto',
    quantity: 'Quantidade',
    price: 'Preço',
    sub_total: 'Subtotal',
  };

  const handleReceived = useCallback(
    async (item) => {
      try {
        const billToReceiveLaunched = {
          bills_to_receive_id: item.id,
          user_id: user.id,
        };
        await api.post(`/launchcreditaccount`, billToReceiveLaunched);

        mutateGlobal(`/sales/${sale_id}`);

        addToast({
          type: 'success',
          title: 'Recebimento confirmado com Sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, user.id, sale_id]
  );

  const handleUndoReceived = useCallback(
    async (item) => {
      try {
        if (!item.parcel) {
          if (
            window.confirm(
              `ATENÇÃO! Estorno de Recebimento parcial. Se confirmar não vai dar baixa na parcela gerada com o restante. VERIFIQUE!`
            )
          ) {
            const billToReceiveReverseLaunched = {
              bills_to_receive_id: item.id,
              user_id: user.id,
            };
            await api.post(
              `/reverselaunchcreditaccount`,
              billToReceiveReverseLaunched
            );

            mutateGlobal(`/sales/${sale_id}`);
            addToast({
              type: 'success',
              title: 'Estorno de recebimento confirmado com Sucesso',
            });
          }
          return;
        }

        const billToReceiveReverseLaunched = {
          bills_to_receive_id: item.id,
          user_id: user.id,
        };
        await api.post(
          `/reverselaunchcreditaccount`,
          billToReceiveReverseLaunched
        );

        mutateGlobal(`/sales/${sale_id}`);
        addToast({
          type: 'success',
          title: 'Estorno de recebimento confirmado com Sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir verifique.',
        });
      }
    },
    [addToast, user.id, sale_id]
  );

  const handleReportViewSale = useCallback(async () => {
    try {
      const linesItems = [];

      linesItems.push([
        { text: 'Produto', style: 'tableHeader', bold: true },
        { text: 'Qtde', style: 'tableHeader', alignment: 'center', bold: true },
        { text: 'Preço', style: 'tableHeader', alignment: 'right', bold: true },
        {
          text: 'Subtotal',
          style: 'tableHeader',
          alignment: 'right',
          bold: true,
        },
      ]);

      // eslint-disable-next-line no-unused-vars
      const itemsProduct =
        dataSale &&
        dataSale.itemsSale.map((i) => {
          linesItems.push([
            { text: `${i.product}`, fontSize: 10 },
            { text: `${i.quantity}`, fontSize: 10, alignment: 'center' },
            { text: `${i.price}`, fontSize: 10, alignment: 'right' },
            { text: `${i.sub_total}`, fontSize: 10, alignment: 'right' },
          ]);
          return i;
        });

      const linesReceived = [];

      linesReceived.push([
        {
          text: `${
            dataSale.sale.payment_term !== 'avista' ? 'Parcela' : 'Pagto.'
          }`,
          style: 'tableHeader',
          alignment: 'center',
          bold: true,
        },
        { text: 'Data Venc.', style: 'tableHeader', bold: true },
        { text: 'Valor', style: 'tableHeader', alignment: 'right', bold: true },
        {
          text: 'Vlr Recebido',
          style: 'tableHeader',
          alignment: 'right',
          bold: true,
        },
        {
          text: 'Data Recebido',
          style: 'tableHeader',
          alignment: 'center',
          bold: true,
        },
        {
          text: 'Situação',
          style: 'tableHeader',
          alignment: 'center',
          bold: true,
        },
      ]);

      // eslint-disable-next-line no-unused-vars
      const received =
        dataSale &&
        dataSale.billsReceives.map((b) => {
          linesReceived.push([
            {
              text: `${b.parcel ? b.parcel : ''}`,
              style: 'lineItem',
              alignment: 'center',
            },
            {
              text: `${b.maturity_date}`,
              style: 'lineItem',
            },
            { text: `${b.amount}`, style: 'lineItem', alignment: 'right' },
            {
              text: `${b.received_amount}`,
              style: 'lineItem',
              alignment: 'right',
            },
            {
              text: `${b.received_date}`,
              style: 'lineItem',
              alignment: 'center',
            },
            {
              text: `${b.received ? 'RECEBIDO' : '-'}`,
              style: 'lineItem',
              alignment: 'center',
              bold: true,
            },
          ]);
          return b;
        });

      const docDefinition = {
        info: {
          title: `${dataSale && dataSale.sale.client} - ${
            dataSale && dataSale.sale.date_sale
          } - ${
            dataSale && dataSale.sale.situation === 'sale' ? 'Venda' : 'Pedido'
          } ${dataSale && dataSale.sale.id}`,
        },

        content: [
          {
            margin: [0, 10, 0, 0],
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: `Data: ${dataSale && dataSale.sale.date_sale}`,
                    fontSize: 12,
                    border: [false, false, false, true],
                  },
                  {
                    text: `${
                      dataSale && dataSale.sale.situation === 'sale'
                        ? 'VENDA '
                        : 'PEDIDO '
                    } ${dataSale && dataSale.sale.id}`,
                    fontSize: 14,
                    alignment: 'right',
                    bold: true,
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
          },
          {
            image: logoGranja,
            width: 180,
            margin: [0, 10, 0, 0],
          },
          {
            text: 'Waldir Gaspar da Silva',
            bold: true,
            margin: [0, 10, 0, 5],
          },
          {
            text: 'Fones: (67) 3461-1085 / Cel: (67) 99977-2238',
            fontSize: 8,
          },
          {
            text:
              'RODOVIA NAVIRAÍ - IVINHEMA KM 06 LADO ESQ SD 500M CEP 79.950-000 - NAVIRAÍ-MS',
            fontSize: 8,
          },
          {
            margin: [0, 10, 0, 20],
            table: {
              widths: ['*'],
              body: [
                [
                  {
                    text: [
                      {
                        text: `Cliente: `,
                        bold: true,
                      },
                      {
                        text: `${dataSale && dataSale.sale.client}`,
                      },
                    ],
                    fontSize: 12,
                    border: [true, true, true, true],
                    margin: [0, 10, 0, 10],
                  },
                ],
              ],
            },
          },
          {
            fontSize: 12,
            table: {
              headerRows: 1,
              widths: ['*', 30, 80, 80],
              body: linesItems,
            },
            layout: 'lightHorizontalLines',
          },
          {
            margin: [0, 1, 0, 0],
            alignment: 'right',
            table: {
              widths: ['*', 100],
              body: [
                [
                  {
                    text: 'Total',
                    alignment: 'right',
                    fontSize: 12,
                    bold: true,
                    border: [false, true, false, false],
                    margin: [0, 4, 0, 0],
                  },
                  {
                    text: dataSale && dataSale.sale.total,
                    alignment: 'right',
                    fontSize: 12,
                    bold: true,
                    border: [false, true, false, false],
                    margin: [0, 4, 0, 0],
                  },
                ],

                [
                  {
                    text: 'desconto',
                    margin: [0, 8, 0, 16],
                    alignment: 'right',
                    fontSize: 12,
                    border: [false, false, false, false],
                  },
                  {
                    text: (dataSale && dataSale.sale.discount) || 'R$ 0,00',
                    alignment: 'right',
                    margin: [0, 8, 0, 16],
                    fontSize: 12,
                    border: [false, false, false, false],
                  },
                ],
                [
                  {
                    text: 'TOTAL LÍQUIDO',
                    alignment: 'right',
                    fontSize: 14,
                    bold: true,
                    border: [false, true, false, true],
                    fillColor: '#eeeeee',
                  },

                  {
                    border: [false, true, false, true],
                    fillColor: '#eeeeee',
                    text: dataSale && dataSale.sale.total_liquid,
                    bold: true,
                    alignment: 'right',
                    fontSize: 14,
                  },
                ],
              ],
            },
          },
          dataSale.sale.situation === 'sale'
            ? {
                text: [
                  {
                    text: 'Condição de Pagamento: ',
                    bold: true,
                  },
                  {
                    text:
                      dataSale.sale.payment_term !== 'avista'
                        ? ` Parcelado em ${dataSale.sale.payment_term}x`
                        : ` À Vista`,
                  },
                ],
                margin: [0, 40, 0, 20],
              }
            : {},
          dataSale.sale.situation === 'sale'
            ? {
                fontSize: 10,
                table: {
                  headerRows: 1,
                  widths: [60, 70, 70, '*', '*', '*'],
                  body: linesReceived,
                },
                layout: 'noBorders',
              }
            : {},
        ],
        footer: (p, pages) => {
          return {
            columns: [
              `User: ${user.name}`,
              {
                alignment: 'right',
                text: [
                  { text: 'Página: ', italics: true },
                  { text: p.toString(), italics: true },
                  ' de ',
                  { text: pages.toString(), italics: true },
                ],
              },
            ],
            margin: [40, 0],
            border: [false, true, false, true],
            fontSize: 10,
          };
        },
      };

      const pdf = pdfMake.createPdf(docDefinition);
      pdf.open();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao excluir verifique.',
      });
    }
  }, [addToast, dataSale, user.name]);

  return (
    <>
      <NavbarMenu />

      {loading && <Loading />}
      {!loading && (
        <Container>
          <WrapperButton noactive={dataSale.received}>
            <Link
              to={{
                pathname: '/sales/edit',
                state: { saleEdit: dataSale },
              }}
            >
              <ButtonGohst>
                <FiEdit2 /> Editar
              </ButtonGohst>
            </Link>
            <ButtonGohst onClick={() => handleReportViewSale()}>
              <FiPrinter /> Imprimir
            </ButtonGohst>
          </WrapperButton>

          {dataSale.sale.situation === 'sale' && (
            <ContainerBills>
              <HeaderBills>
                <LabelBills>RECEBIMENTOS</LabelBills>
                <ItemBills>
                  <p>recebido</p>
                  <span>{dataSale.billsReceivedTotal}</span>
                </ItemBills>
                <ItemBills>
                  <p>vencido</p>
                  <span style={{ color: '#f43e61' }}>
                    {dataSale.billsReceivesPastTotal}
                  </span>
                </ItemBills>
                <ItemBills>
                  <p>a receber</p>
                  <span>{dataSale.billsReceivesToReceivesTotal}</span>
                </ItemBills>
              </HeaderBills>
              <TableReceives
                head={headReceives}
                data={dataSale.billsReceives}
                pathname="/billstoreceives/newedit"
                handleReceived={handleReceived}
                handleUndoReceived={handleUndoReceived}
              />
            </ContainerBills>
          )}

          <ContainerBox>
            <HeaderSale>
              <div>
                <h1>{dataSale.sale.situation === 'sale' && 'VENDA'}</h1>
                <h1>{dataSale.sale.situation !== 'sale' && 'PEDIDO'}</h1>

                <p>{dataSale.sale.id}</p>
              </div>
              {dataSale.sale.situation === 'order_confirmed' && (
                <img src={confirmed} alt="confirmado" />
              )}
              {dataSale.sale.situation === 'order_canceled' && (
                <img src={canceled} alt="cancelado" />
              )}
              {dataSale.sale.situation === 'order_draft' && (
                <img src={draft} alt="rascunho" />
              )}

              <span>{dataSale.sale.total_liquid}</span>
            </HeaderSale>
            <WrapperOffSet>
              <Col1>
                <span>Cliente</span>
                <p>{dataSale.sale.client}</p>
              </Col1>
              <Col1>
                <span>
                  Data{' '}
                  {dataSale.sale.situation === 'sale'
                    ? 'da Venda'
                    : 'do Pedido'}
                </span>
                <p>{dataSale.sale.date_sale}</p>
              </Col1>

              {dataSale.sale.situation === 'sale' && (
                <FlexRow>
                  <Col1>
                    <>
                      <span>
                        Data{' '}
                        {dataSale.sale.payment_term === 'avista' &&
                          'de Vencimento'}
                        {dataSale.sale.payment_term !== 'avista' &&
                          'da Primeira Parcela'}
                      </span>
                      <p>
                        {dataSale.sale.payment_term === 'avista' &&
                          dataSale.sale.date_sale}
                      </p>
                      <p>
                        {dataSale.sale.payment_term !== 'avista' &&
                          dataSale.billsReceives[0]?.maturity_date}
                      </p>
                    </>
                  </Col1>

                  <Col1 style={{ marginLeft: 250 }}>
                    <span>Condição de pagamento</span>
                    <p>
                      {dataSale.sale.payment_term === 'avista' && `À Vista`}
                    </p>
                    <p>
                      {dataSale.sale.payment_term !== 'avista' &&
                        `Parcelado em ${dataSale.sale.payment_term}x`}
                    </p>
                  </Col1>
                </FlexRow>
              )}

              <div style={{ marginTop: 56 }}>
                <Table head={head} data={dataSale.itemsSale} />
              </div>

              <Total>
                <SubTotal>
                  <p>Valor Total</p>
                  <span>{dataSale.sale.total}</span>
                </SubTotal>
                <SubTotal2>
                  <p>Desconto R$ </p>
                  <p>
                    {dataSale.sale.discount
                      ? dataSale.sale.discount
                      : 'R$ 0,00'}
                  </p>
                </SubTotal2>
                <TotalLiq>
                  <p>Total líquido</p>
                  <span>{dataSale.sale.total_liquid}</span>
                </TotalLiq>
              </Total>
              {dataSale.sale.comments && (
                <Obs>
                  <span>Observações</span>
                  <p>{dataSale.sale.comments}</p>
                </Obs>
              )}
            </WrapperOffSet>
          </ContainerBox>

          <WrapperButton noactive={dataSale.received}>
            <Link
              to={{
                pathname: '/sales/edit',
                state: { saleEdit: dataSale },
              }}
            >
              <ButtonGohst>
                <FiEdit2 /> Editar
              </ButtonGohst>
            </Link>
            <ButtonGohst onClick={() => handleReportViewSale()}>
              <FiPrinter /> Imprimir
            </ButtonGohst>
          </WrapperButton>
        </Container>
      )}
    </>
  );
};

export default SaleView;
