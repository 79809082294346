import React from 'react';

import { Container } from './styles';

const ButtonDefault = ({ children, ...rest }) => {
  return (
    <Container>
      <button type="button" {...rest}>
        {children}
      </button>
    </Container>
  );
};

export default ButtonDefault;
