import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { Container } from './styles';

const InputBRL = ({ id, name, placeholder, label, ...rest }) => {
  const mask = createNumberMask({
    prefix: 'R$ ',
    suffix: '',
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    requireDecimal: true,
    decimalLimit: 2,
  });

  const inputRef = useRef(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current.inputElement,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error}>
      <label {...rest} htmlFor={id}>
        {label}
      </label>

      <InputMask
        guide
        mask={mask}
        defaultValue={defaultValue}
        ref={inputRef}
        name={name}
        id={id}
        placeholder={placeholder || 'R$'}
        {...rest}
      />

      <small>{error}</small>
    </Container>
  );
};

export default InputBRL;
