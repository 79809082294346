import styled from 'styled-components';

export const Container = styled.div``;

export const TotalItems = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    border-radius: 0 0 4px 4px;

    padding: 8px 16px;
    background: #f2f2f2;
  }
`;
