import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 85px;
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    align-items: center;

    button {
      min-width: 125px;
      margin-left: -125px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      color: #fff;
      background: linear-gradient(0deg, #358ccf, #3ca2d3);
      border-color: #cdd4dc;
      border: 0;
      border-radius: 0 4px 4px 0;
      height: 38px;
      font-size: 14px;
      font-weight: bold;

      transition: background 0.2s;

      :hover {
        filter: brightness(90%);
      }

      :active {
        filter: brightness(80%);
      }
    }
  }

  label {
    color: #616161;
    margin-bottom: 4px;
  }

  input {
    min-width: 250px;
    width: 100%;
    height: 40px;
    padding: 6px 8px;
    background: #fff;
    color: #616161;
    border-radius: 4px;
    border: 2px solid #d1d5da;

    transition: all 0.2s ease-in-out;

    ::placeholder {
      color: #d1d5da;
    }

    ${(props) =>
      props.isFocused &&
      css`
        border-color: #2188ff;
        box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
          0 0 0 0.2em rgba(3, 102, 214, 0.3);
        transition: all 0.2s ease-in-out;
      `}
    ${(props) =>
      props.isErrored &&
      css`
        border-color: #e02041;
        box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
          0 0 0 0.2em rgba(224, 32, 65, 0.3);
      `}
      ${(props) =>
        props.isErrored &&
        props.isFilled &&
        css`
          border: 2px solid #d1d5da;
          box-shadow: none;
        `}
  }

  small {
    font-size: 12px;
    font-style: italic;
    color: #e02041;
    margin-top: 4px;
    transition: all 0.2s ease-in-out;
  }
`;
