import axios from 'axios';

const url = 'https://brasilapi.com.br/api/cep/v1/';

const getCEP = async (cep) => {
  try {
    const response = await axios.get(`${url}${cep}`);
    return response.data;
  } catch (error) {
    return error.response.status;
  }
};

export default getCEP;
