import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  .noactive {
    pointer-events: none;
    opacity: 0.6;
    cursor: not-allowed;
  }
  button {
    min-width: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;

    background: transparent;
    border: 0;

    border: 1px solid #c1c3c7;
    border-radius: 4px;
    height: 38px;

    font-size: 14px;
    font-weight: bold;

    transition: background 0.2s;

    svg {
      margin-right: 8px;
    }

    &:hover {
      background: ${shade(0.04, '#f1f3f5')};
    }

    &:active {
      background: ${shade(0.06, '#f1f3f5')};
    }
  }
`;
