import useSWR from 'swr';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

export const useFetchCategories = (url) => {
  const { signOut } = useAuth();
  const { addToast } = useToast();

  const { data, error, mutate } = useSWR(
    url,
    async () => {
      const response = await api.get(url);

      const totalItens = response.headers['x-total-count'];

      const categories = response.data.map((cat) => {
        if (cat.type === 'p')
          return {
            id: cat.id,
            description: cat.description,
            type: 'Produtos',
          };

        if (cat.type === 'i')
          return {
            id: cat.id,
            description: cat.description,
            type: 'Insumos',
          };

        return {
          id: cat.id,
          description: cat.description,
          type: 'Matéria-Prima',
        };
      });

      return { data: categories, total: totalItens };
    },
    {
      revalidateOnReconnect: true,
      onError: (err) => {
        if (err.response.status === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada. Faça seu login.',
          });
          signOut();
        }
      },
    }
  );

  return {
    dataList: data && data.data,
    totalItems: data && data.total,
    loading: !data && !error,
    error,
    mutate,
  };
};
