import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 50vh;
  max-width: 1120px;

  ${(props) =>
    props.small &&
    css`
      max-width: 600px;
    `}

  margin: 0 auto;
  padding: 32px;
  margin-bottom: 64px;

  background: #fff;
  border-radius: 4px;

  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
`;
