import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  button {
    min-width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    background-color: #28a745;
    background-image: linear-gradient(-180deg, #34d058, #28a745 90%);

    border: 0;
    border-radius: 4px;
    height: 38px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.17);
    transition: background 0.2s;

    svg {
      margin-right: 8px;
    }

    &:hover {
      background-color: #269f42;
      background-image: linear-gradient(-180deg, #2fcb53, #269f42 90%);
    }

    &:active {
      background-color: ${shade(0.2, '#269f42')};
      box-shadow: inset 0 2px 29px 0 rgba(0, 0, 0, 0.17);
      transform: translateY(1px);
    }
  }
`;
