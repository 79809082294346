import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import ButtonAddFast from '../../ButtonAddFast';
import { Container } from './styles';

const SelectAddFast = ({
  id,
  name,
  label,
  children,
  handleAddFast,
  ...rest
}) => {
  const selectRef = useRef(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error}>
      <label htmlFor={id}>
        {label}
        <div>
          <select
            name={name}
            id={id}
            defaultValue={defaultValue}
            ref={selectRef}
            {...rest}
          >
            {children}
          </select>
          <ButtonAddFast onClick={handleAddFast} title={`Cadastrar ${label}`} />
        </div>
      </label>

      <small>{error}</small>
    </Container>
  );
};

export default SelectAddFast;
