import styled, { css } from 'styled-components';

export const Container = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

export const PageList = styled.ul`
  display: flex;
  list-style: none;
`;

export const PageItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  margin: 0 3px;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: bold;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      background: #f0f0f0;
    `}

  ${(props) =>
    props.disable &&
    css`
      display: none;
    `}
`;
