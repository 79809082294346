import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3333'
      : 'https://granjaz-api.herokuapp.com',
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('@GranjaERP:token');

  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
