import React from 'react';
import { Link } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';

import { TableBodyItem, Actions } from './styles';

const Row = ({ item, pathname, handleDelete }) => {
  const keys = Object.keys(item);

  return (
    <TableBodyItem
      key={item.sale_id}
      canceled={item.situation === 'Pedido Cancelado'}
      daft={item.situation === 'Pedido Rascunho'}
      confirmed={item.situation === 'Pedido Confirmado'}
      sale={item.situation === 'Venda'}
    >
      {keys.map((key) => (
        <td key={key}>
          <Link to={{ pathname, state: { item } }}>{item[key]} </Link>
        </td>
      ))}
      <td>
        <Actions
          title="Excluir item"
          type="button"
          onClick={() => handleDelete(item.sale_id)}
        >
          <FiTrash2 size={16} color="#e02041" />
        </Actions>
      </td>
    </TableBodyItem>
  );
};

export default Row;
