import React from 'react';

import { FiTrash2 } from 'react-icons/fi';

import { TableBodyItem, Actions } from './styles';

const Row = ({ item, handleDelete }) => {
  const keys = Object.keys(item);

  return (
    <TableBodyItem key={item.product_id}>
      {keys.map((key) => (
        <td key={key}>
          <div>{item[key]}</div>
        </td>
      ))}
      <td>
        <Actions
          title="Excluir item"
          type="button"
          onClick={() => handleDelete(item.product_id)}
        >
          <FiTrash2 size={16} color="#e02041" />
        </Actions>
      </td>
    </TableBodyItem>
  );
};

export default Row;
