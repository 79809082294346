import { addHours, format } from 'date-fns';

export const formatedInputDatePicker = (value) => {
  const formatedDatePicker = addHours(new Date(value), 6);
  return formatedDatePicker;
};

export const formatedStringInputDatePicker = (value) => {
  const day = value.split('/')[0];
  const month = value.split('/')[1];
  const year = value.split('/')[2];

  const date = `${year}-${month}-${day}`;

  const formatedDatePicker = addHours(new Date(date), 6);
  return formatedDatePicker;
};

export const formatedDateOnlyAdd6Hours = (value) => {
  const formatedDateOnly = format(addHours(new Date(value), 6), 'dd/MM/yyyy');

  return formatedDateOnly;
};

export const formatedInputBRL = (value) => {
  const formatedBRL = new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);

  return formatedBRL;
};
