import styled from 'styled-components';

export const TableBodyItem = styled.tr`
  transition: all 0.2s ease;

  td {
    text-align: center;
    border-bottom: 1px dotted #d4d4d4;

    :nth-child(1) {
      width: 48px;
      div {
        justify-content: center;
      }
    }
    :nth-child(2) {
      max-width: 400px;
      div {
        padding: 4px 8px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    :nth-child(3) {
      width: 100px;
      div {
        justify-content: flex-end;
      }
    }
    :nth-child(4) {
      div {
        justify-content: flex-end;
      }
    }
    :nth-child(5) {
      div {
        justify-content: flex-end;
      }
    }

    div {
      display: flex;
      padding: 18px 16px;
    }
  }

  :hover {
    background: #f5f5f9;
  }
`;

export const Actions = styled.button`
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;

  svg {
    margin: 0;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button + button {
    margin-left: 12px;
  }
`;
