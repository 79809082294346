import useSWR from 'swr';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';

import {
  formatedInputBRL,
  formatedDateOnlyAdd6Hours,
} from '../../../utils/formatedInputs';

export const useFetchSalesReceivable = (url) => {
  const { signOut } = useAuth();
  const { addToast } = useToast();

  const { data, error, mutate } = useSWR(
    url,
    async () => {
      const response = await api.get(url);

      const saleReceivable = response.data.map((i) => {
        return {
          maturity_date: formatedDateOnlyAdd6Hours(i.maturity_date),
          sale_id: i.sale_id,
          client: i.peoples.pj?.fantasy_name || i.peoples.pf?.name || '',
          parcel: i.parcel,
          amount: formatedInputBRL(i.amount),
          received_amount: i.received_amount
            ? formatedInputBRL(i.received_amount)
            : '',
          id: i.id,
          past: i.past,
          received: i.received,
        };
      });

      const billsReceivedTotal = response.data
        .filter((r) => r.received)
        .reduce((totalOk, item) => totalOk + +item.received_amount, 0);

      const billsReceivesPastTotal = response.data
        .filter((r) => r.past && !r.received)
        .reduce((totalPast, item) => totalPast + +item.amount, 0);

      const billsReceivesToReceivesTotal = response.data
        .filter((r) => !r.past && !r.received)
        .reduce((totalReceived, item) => totalReceived + +item.amount, 0);

      const billsTOTAL =
        billsReceivedTotal +
        billsReceivesPastTotal +
        billsReceivesToReceivesTotal;

      const dataSale = {
        saleReceivable,

        billsReceivedTotal: formatedInputBRL(billsReceivedTotal),
        billsReceivesPastTotal: formatedInputBRL(billsReceivesPastTotal),
        billsReceivesToReceivesTotal: formatedInputBRL(
          billsReceivesToReceivesTotal
        ),
        billsTOTAL: formatedInputBRL(billsTOTAL),
      };
      return { data: dataSale };
    },
    {
      revalidateOnReconnect: true,

      onError: (err) => {
        if (err.response.status === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada. Faça seu login.',
          });
          signOut();
        }
      },
    }
  );

  return {
    dataList: data && data.data,
    loading: !data && !error,
    error,
    mutate,
  };
};
