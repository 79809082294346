import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
`;

export const HeaderSale = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: dotted 1px #c9d3dd;
  padding-bottom: 16px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      font-family: 'Open Sans Condensed', sans-serif;
    }

    p {
      font-family: 'Open Sans Condensed', sans-serif;
      font-weight: bold;
      font-size: 18px;
      padding: 6px 5px;
      background: #ededf0;
      border-radius: 4px;
      margin-left: 12px;
    }
  }

  span {
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: bold;
    font-size: 36px;
    color: #395a7a;
  }

  img {
    margin-top: -32px;
    width: 100px;
  }
`;

export const Col1 = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 40px;

  p {
    margin-top: 4px;
    color: #395a7a;
  }
`;

export const Obs = styled.div`
  margin-top: 64px;
  border-top: dotted 1px #c9d3dd;

  span {
    font-weight: bold;
    margin-top: 8px;
  }

  p {
    margin-top: 4px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const WrapperOffSet = styled.div`
  max-width: 960px;
  margin: 0 auto;
  margin-bottom: 40px;
`;

export const Total = styled.section`
  display: flex;
  max-width: 40%;
  flex-direction: column;
  margin-left: auto;
  color: #395a7a;
`;

export const SubTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;

  p {
    font-weight: bold;
  }

  span {
    font-size: 16px;
    font-weight: bold;
  }
`;

export const SubTotal2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;

  border-top: 2px solid #c9d3dd;

  p {
    padding: 32px 0 32px 0;
  }
`;

export const TotalLiq = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #c9d3dd;
  border-bottom: 1px solid #c9d3dd;
  padding: 16px 0 16px 16px;

  p,
  span {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const ContainerBills = styled.div`
  margin-bottom: 32px;
`;

export const HeaderBills = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 72px;
  border: dotted 1px #c9d3dd;
`;

export const LabelBills = styled.div`
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  font-size: 22px;
  padding-right: 72px;
`;

export const ItemBills = styled.div`
  p {
    text-align: right;
  }
  span {
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #395a7a;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-top: dotted 1px #c9d3dd;

  padding: 20px 0;

  a + div {
    margin-left: 16px;
  }

  a {
    opacity: ${(props) => (props.noactive ? '0.6' : '1')};
    pointer-events: ${(props) => (props.noactive ? 'none' : 'auto')};
  }
`;
