export const parseStrToFloatInputBRL = (value) => {
  const value1 = value.replace('R$ ', '');
  const value2 = value1.replace(/\./g, '');
  const valueReady = value2.replace(',', '.');
  const ready = valueReady.replace('R$', ''); // confirmar se nao ficou restos R$

  const valueDecimal = parseFloat(ready).toFixed(2) * 1; //* 1 para converter em number

  return valueDecimal || 0;
};
