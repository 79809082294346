import useSWR from 'swr';

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import { formatedInputBRL } from '../../../utils/formatedInputs';

export const useFetchSearchProducts = (url) => {
  const { signOut } = useAuth();
  const { addToast } = useToast();

  const { data, error, mutate } = useSWR(
    url,
    async () => {
      const response = await api.get(url);

      const totalItens = response.headers['x-total-count'];

      const products = response.data.map((prod) => {
        return {
          id: prod.id,
          description: `${prod.description} | ${
            prod.classification?.description
          } ${prod.color === 'b' ? '- BRANCO' : ''}${
            prod.color === 'v' ? '- VERMELHO' : ''
          }`,
          sale_price: prod.sale_price && formatedInputBRL(prod.sale_price),
          stock: prod.stock || 0,
        };
      });

      return { data: products, total: totalItens };
    },
    {
      revalidateOnReconnect: true,
      onError: (err) => {
        if (err.response.status === 401) {
          addToast({
            type: 'error',
            title: 'Sessão expirada. Faça seu login.',
          });
          signOut();
        }
      },
    }
  );

  return {
    dataList: data && data.data,
    totalItems: data && data.total,
    loading: !data && !error,
    error,
    mutate,
  };
};
