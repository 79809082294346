import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3px;

  input {
    min-width: 150px;
    width: 100%;
    height: 40px;
    padding: 6px 8px;
    background: #fff;
    color: #616161;
    border-radius: 4px;
    border: 2px solid #d1d5da;

    transition: all 0.2s ease-in-out;

    ::placeholder {
      color: #d1d5da;
    }

    :focus {
      border-color: #2188ff;
      box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
        0 0 0 0.2em rgba(3, 102, 214, 0.3);
      transition: all 0.2s ease-in-out;
    }

    ${(props) =>
      props.isErrored &&
      css`
        border-color: #e02041;
        box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
          0 0 0 0.2em rgba(224, 32, 65, 0.3);
      `}
  }

  small {
    font-size: 12px;
    font-style: italic;
    color: #e02041;
    margin-top: 4px;
    transition: all 0.2s ease-in-out;
  }
`;
